const env = {
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  FITBIT_LOGIN_URL: process.env.REACT_APP_FITBIT_LOGIN_URL,
  MERCURE_URL: process.env.REACT_APP_MERCURE_URL,
  MERCURE_FITBIT_OAUTH_TOPIC: process.env.REACT_APP_MERCURE_FITBIT_OAUTH_TOPIC,
  MERCURE_OMRON_OAUTH_TOPIC: process.env.REACT_APP_MERCURE_OMRON_OAUTH_TOPIC,
  MERCURE_SUBSCRIBE_MESSAGE_EVENT: process.env.REACT_APP_MERCURE_SUBSCRIBE_MESSAGE_EVENT,
};

export default env;
