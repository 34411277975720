import {PaperAirplaneIcon} from '@heroicons/react/solid';
import {hasLength} from '../util/helper';
import {useLogin} from '../api/login';
import {useNestedTranslation, useQuery} from '../hooks';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {and, chain, compose, getPath, getProp, isString, map, Maybe, option, propEq, safe, tap,} from 'crocks';
import {API_ENDPOINT} from '../api';
import {fromPromise, Rejected} from 'crocks/Async';
import {useNotification} from '../components/Notification';
import {useHistory, useParams} from "react-router-dom";
import Messages from "../components/chat/Messages";
import VideoCallBtn from "../components/chat/VideoCallBtn";
import ChatLayout from "../layout/ChatLayout";
import {get} from "../util/apiFetch";

const useChatUtils = ({newMessageInputRef, receiver} = {}) => {
  const {data: user} = useLogin();
  const {notify} = useNotification();
  const [t] = useNestedTranslation(['chatLayout']);
  const [isNewMessageValid, setIsNewMessageValid] = useState(false);
  const onMessageChange = useCallback(event => {
    newMessageInputRef.current.style.height = '0px';
    newMessageInputRef.current.style.height = newMessageInputRef?.current?.scrollHeight + 'px';
    setIsNewMessageValid(
      getPath(['current', 'value'], newMessageInputRef)
        .chain(safe(isString))
        .map(a => a.trim())
        .chain(safe(hasLength))
        .map(() => true)
        .option(false)
    );
  }, [setIsNewMessageValid, newMessageInputRef]);

  const sendMessage = useCallback((e, meetLink) => {
    if (e)
      e.preventDefault()
    const getBody = compose(
      option(null),
      chain(safe(hasLength)),
      map(s => s.trim()),
      chain(safe(isString)),
      getPath(['current', 'value'])
    );

    const getSender = compose(
      option(null),
      chain(safe(isString)),
      getProp('fullName'),
    );

    safe(obj => Object.values(obj).every(a => a !== null), {
      receiver: receiver,
      sender: getSender(user),
      body: meetLink ? meetLink : getBody(newMessageInputRef),
    })
      .map(fromPromise(API_ENDPOINT.POST_MESSAGE.fetch))
      .option(Rejected(t('invalidMessageRequest')))
      .fork(
        error => notify({
          children: error,
          className: 'alert-error',
          timeout: 1000,
        }),
        () => compose(
          target => onMessageChange({target}),
          tap(a => {
            a.value = ''
            if (meetLink)
              window.location.href = meetLink;
          }),
        )(newMessageInputRef.current)
      );
  }, [newMessageInputRef, user, receiver, onMessageChange, notify, t]);

  return {
    isNewMessageValid,
    onMessageChange,
    sendMessage,
    t,
    sendOnEnterKey: useCallback(
      event => Maybe.of(event)
        .chain(safe(and(propEq('which', 13), propEq('shiftKey', false))))
        .map(tap(e => {
          e.preventDefault()
        }))
        .map(sendMessage),
      [sendMessage]
    ),
  };
};


const ChatDetailPage = () => {
  const [selected, setSelected] = useState(null);
  const {data: user} = useLogin();
  const messageRef = useRef(null);
  const {receiver} = useParams()
  const query = useQuery();
  const isSent = query.get('send');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef();
  const {
    onMessageChange,
    sendMessage,
    sendOnEnterKey,
    isNewMessageValid,
    t,
  } = useChatUtils({
    newMessageInputRef: messageRef,
    receiver,
  });


  const getLastMessage = async () => {
    try {
      const data = await get(`/message/latest?sender=${user.fullName}&receiver=${receiver}`);
      setSelected(data)
      if (!data.otherName) {
        setSelected({otherName: receiver})
      }
    } catch (error) {
      console.error('Error fetching conv:', error);
    }
  }

  useEffect(() => {
    getLastMessage()
  }, [receiver])

  useEffect(() => {
    const updateSelectedUser = async () => {
      if (isSent && selected) {
        await videoRef.current.sendMeetLink();
        // Clear query parameter
        const location = history.location;
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('isSent');
        console.log(queryParams)
        history.push({
          ...location,
          search: queryParams.toString(),
        });
      }
    };

    updateSelectedUser();
  }, [selected, isSent, history])

  return (
    <ChatLayout>
      <Messages selected={selected}/>
      <div className="flex items-end">
        <div className="form-control w-full">
          <form className={`relative`}
                style={{opacity: isLoading ? 0.3 : 1}}
                onSubmit={sendMessage}
          >
              <textarea
                className={`w-full textarea textarea-bordered max-h-[45vh] h-12 pr-20`}
                rows={1}
                placeholder={t('yourMessage')}
                ref={messageRef}
                onChange={onMessageChange}
                onKeyDown={sendOnEnterKey}
                disabled={!selected}
              />
            <div className={'absolute top-0 right-0 flex items-center'}>
              <VideoCallBtn ref={videoRef} selected={selected} sendMessage={sendMessage} setIsLoading={setIsLoading}/>
              <button
                type={'submit'}
                className="rounded-l-none btn btn-primary"
                style={{height: 'calc(100% - 8px)'}}
                disabled={!isNewMessageValid}
              >
                <PaperAirplaneIcon className="w-6 h-6 color-base-content"/>
              </button>
            </div>
          </form>
        </div>
      </div>
    </ChatLayout>
  );
};

export default ChatDetailPage;
