import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  and,
  branch,
  chain,
  compose,
  getPath,
  getProp,
  hasProp,
  hasProps,
  identity,
  isArray,
  isString,
  map,
  merge,
  option,
  safe,
  setProp,
  tap
} from "crocks";
import {caseMap, concatBySpace, hasLength, isNumeric, parseInt10} from "../../util/helper";
import SanitizeHTML from "react-sanitized-html";
import {useLogin} from "../../api/login";
import {useDebounced, useEventListener, useMutationObserver, useNestedTranslation} from "../../hooks";
import {API_ENDPOINT} from "../../api";
import {getMercureEvent} from "../../util";
import env from "../../env";

const linkifyUrls = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return str.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
};

const Messages = ({selected}) => {
  const bodyRef = useRef();
  const {data: user} = useLogin();
  const [t] = useNestedTranslation(['chatLayout']);
  const conversationSwr = API_ENDPOINT.GET_MESSAGE_HISTORY.swr(selected?.id);
  const [conversation, setConversation] = useState([]);

  useEffect(() => {
    setConversation(conversationSwr?.data)
  }, [conversationSwr.data]);

  useEffect(() => {
    const getMessageEvent = compose(
      map(str => getMercureEvent(`${env.MERCURE_SUBSCRIBE_MESSAGE_EVENT}${str}`, ({data}) => {
        setConversation(a => [...a, JSON.parse(data)]);
      })),
      chain(safe(and(isString, hasLength))),
      getProp('conversation'),
    );

    const messageEvent = getMessageEvent(selected);

    return () => messageEvent.map(a => a.close());

  }, [selected, setConversation]);


  const mapResolve = useCallback(message => compose(
    option(identity),
    map(
      m => setProp('component', compose(
        option(<p>{t('noMessages')}</p>),
        map(a => (
          <SanitizeHTML html={a} className={concatBySpace(
            'rounded-md p-3 bg-base-100 bg-opacity-10 inline-block text-left',
            m?.isMine ? 'bg-primary ml-auto' : 'bg-base-content'
          )}/>
        )),
        map(arr => arr.join('')),
        map(map(str => `<p>${linkifyUrls(str)}</p>`)), // Use linkifyUrls here
        map(s => s.split('\n')),
        safe(isString),
      )(m?.body), m)
    ),
    map(myName => caseMap(() => message, [
      [m => myName === m?.sender, m => ({
        ...m,
        myName: m?.sender,
        otherName: m?.receiver,
        isMine: true,
      })],
      [m => myName === m.receiver, m => ({
        ...m,
        myName: m?.receiver,
        otherName: m?.sender,
        isMine: false,
      })],
    ], message)),
    getProp('fullName'),
  )(user), [user, t]);

  useEventListener(
    bodyRef,
    'scroll',
    useDebounced(useCallback(() => {
      compose(
        option([]),
        map(map(compose(
          merge((element, id) => {
            API_ENDPOINT.PUT_MESSAGE_SEEN.fetch({id}).then(response => {
              element.dataset.seenAt = response?.seenAt;
            })

            return element;
          }),
          map(option(null)),
          map(map(parseInt10)),
          map(chain(safe(isNumeric))),
          map(getPath(['dataset', 'messageId'])),
          branch,
        ))),
        chain(safe(hasLength)),
        map(Array.from),
        map(element => element.querySelectorAll('[data-message-id]:not([data-mine]):not([data-seen-at])')),
        chain(safe(hasProp('querySelectorAll'))),
        getProp('current')
      )
      (bodyRef)
    }, [bodyRef]), 1000)
  );

  useMutationObserver(bodyRef, useCallback(() => {
    safe(hasProps(['scrollTo', 'scrollWidth']), bodyRef?.current)
      .map(tap(e => e.scrollTo(e.scrollLeft, e.scrollHeight)))
  }, [bodyRef]));

  return (
    <div className="pt-8 p-1 overflow-y-scroll overflow-x-hidden h-full max-w-none mb-4 space-y-4 chat-content"
         ref={bodyRef}>
      {useMemo(() => compose(
        option(null),
        map(map(compose(
          m => (
            <div key={m?.id} className={m?.isMine ? 'text-right' : 'text-left'}>
              <div
                key={m?.id}
                data-tip={m?.sentAt}
                data-message-id={m?.id}
                data-mine={m?.isMine ? 'yes' : undefined}
                data-seen-at={m?.seenAt}
                className={concatBySpace('min-w-40 inline-block tooltip', m?.isMine ? 'text-right' : 'text-left')}>
                {m.component}
              </div>
            </div>
          ),
          mapResolve,
        ))),
        safe(isArray),
      )(conversation), [mapResolve, conversation])}
    </div>
  )
}

export default Messages
