import TableLayout, {LINK_PROPS_FN} from './TableLayout';
import {API_ENDPOINT} from '../api';
import {isSessionActive} from '../business';
import {concatBySpace, createNewItemPath, mFirstById} from '../util/helper';
import {useNestedTranslation} from '../hooks';
import {LOCAL_STORAGE_KEYS, routes} from '../App';
import {getProp} from 'crocks';
import {useHistory} from 'react-router-dom';

const PatientsLayout = () => {
  const [t, tt] = useNestedTranslation(['patientsLayout', 'patientsLayout.table']);
  const {data: sessions = []} = API_ENDPOINT.GET_SESSIONS.swr();
  const {data: doctors = []} = API_ENDPOINT.GET_DOCTORS.swr();
  const history = useHistory();

  return (
    <TableLayout {...{
      title: t('title'),
      linkPropFn: LINK_PROPS_FN.idProp(routes.patient.path),
      onCreateCallback: createNewItemPath(history, routes.patient.path),
      localStorageSettingsKey: LOCAL_STORAGE_KEYS.PATIENT_TABLE_SETTINGS,
      swr: API_ENDPOINT.GET_PATIENTS.swr(),
      columnSettings: {
        id: {
          order: 0,
          on: true,
          renderer: ({id}) => <span>{id}</span>,
          translated: tt('id'),
        },
        sessions: {
          order: 0,
          on: true,
          renderer: ({sessions: x}) => {
            const exists = sessions?.find(s => x?.includes(s?.id) && isSessionActive(s))
            const until = getProp('dateTo', exists).option(null);
            return (
              <div className={`badge ${exists ? 'badge-success' : 'badge-error'}`}>
                {exists ? (until ? concatBySpace(tt('sessionActiveUntil'), until) : tt('sessionActive')) : tt('sessionInactive')}
              </div>
            );
          },
          translated: tt('sessions'),
        },
        fullName: {
          translated: tt('fullName'),
          order: 0,
          on: true,
          renderer: ({fullName}) => <span>{fullName}</span>,
        },
        firstName: {
          translated: tt('firstName'),
          order: 0,
          on: true,
          renderer: ({firstName}) => <span>{firstName}</span>,
        },
        lastName: {
          translated: tt('lastName'),
          order: 0,
          on: true,
          renderer: ({lastName}) => <span>{lastName}</span>,
        },
        dob: {
          translated: tt('dob'),
          order: 0,
          on: true,
          renderer: ({dob}) => <span>{dob}</span>,
        },
        personCode: {
          translated: tt('personCode'),
          order: 0,
          on: true,
          renderer: ({personCode}) => <span>{personCode}</span>,
        },
        phoneNo: {
          translated: tt('phoneNo'),
          order: 0,
          on: true,
          renderer: ({phoneNo}) => <span>{phoneNo}</span>,
        },
        doctor: {
          translated: tt('doctor'),
          order: 0,
          on: true,
          renderer: ({doctor}) => {
            const doc = mFirstById(doctor, doctors)
              .chain(getProp('fullName'))
              .option(tt('unknownDoctor'));

            return <span>{doc}</span>;
          },
        },
        riskName: {
          translated: tt('riskName'),
          order: 0,
          on: true,
          renderer: ({riskName: x}) => {
            const c =
              x === 'hypo' ? 'bg-blue-400 text-white'
              : x === 'low' ? 'bg-yellow-400 text-white'
              : x === 'med' ? 'bg-orange-400 text-white'
              : x === 'high' ? 'bg-red-400 text-white'
              : x === null ? 'bg-gray-500 text-white'
              : 'badge-success'
            ;

            return (
              <div className={`badge border-none ${c} py-1`}>{tt(`risk.${x}`)}</div>
            );
          }
        },
        sex: {
          translated: tt('sex'),
          order: 0,
          on: true,
          renderer: ({sex}) => <span>{tt(sex)}</span>,
        },
      }
    }}/>
  );
};

export default PatientsLayout;
