import {ConfigurableTable, Hr, Title} from '../components';
import {getProp, isFunction} from 'crocks';
import {useNestedTranslation} from '../hooks';
import {PlusIcon} from '@heroicons/react/solid';

export const TAG_CLASS_NAME = 'py-1';
export const LINK_PROPS_FN = {
  idProp: pathToItem => row => getProp('id', row)
  .map(id => ({to: pathToItem.replace(':id', id)}))
  .option(undefined)
}

const TableLayout = ({
  title,
  swr,
  columnSettings,
  localStorageSettingsKey,
  linkPropFn,
  onCreateCallback,
}) => {
  const [t] = useNestedTranslation(['tableLayout'])
  return (
    <>
      <div className="flex justify-between items-center">
        <Title>{title}</Title>
        <div>
          {isFunction(onCreateCallback) ? (
            <button className="btn btn-primary" onClick={onCreateCallback}>
              {t('new')}
              <PlusIcon className="w-5 h-5 ml-4"/>
            </button>
          ) : null}
        </div>
      </div>
      <Hr />
      <ConfigurableTable
        linkProps={isFunction(linkPropFn) ? linkPropFn : undefined}
        localStorageSettingsKey={localStorageSettingsKey}
        columnSettings={columnSettings}
        {...swr}
      />
    </>
  );
};

export default TableLayout;
