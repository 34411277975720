import {createContext, useContext, useEffect, useState} from 'react';
import {get} from "../util/apiFetch";

const ChatContext = createContext();

const ChatContextProvider = ({children}) => {
  const [conversationList, setConversationList] = useState([]);


  useEffect(() => {
    const getList = async () => {
      try {
        const data = await get(`/message/conversation/list`);
        setConversationList(data)
      } catch (error) {
        console.error('Error fetching conv:', error);
      }
    }

    getList()
  }, [])


  return (
    <ChatContext.Provider value={{conversationList}}>
      {children}
    </ChatContext.Provider>
  );
};

const useChat = () => {
  const context = useContext(ChatContext);

  if (context === undefined) throw new Error('useChat must be used within a ChatProvider');

  return {...context};
};

export {ChatContextProvider, useChat};
