import React, {useCallback} from "react";
import {concatBySpace} from "../../util/helper";
import {compose, isString, map, option, safe} from "crocks";

const Avatar = ({children, name, online = false}) => {
  const getInitials = useCallback(name => compose(
    option('?'),
    map(a => a.join('')),
    map(map(a => a?.[0])),
    map(a => a.split(' ')),
    safe(isString),
  )(name), [])

  return (
    <div className={concatBySpace('avatar placeholder', online ? 'online' : null)}>
      <div className="bg-neutral-focus text-neutral-content rounded-full w-12 h-12">
        {children ? children : <span>{getInitials(name)}</span>}
      </div>
    </div>
  );
};

const ChatListItem = ({name, sentAt, online = false, isSelected = false}) => {
  return (
    <div className="flex items-center">
      <Avatar name={name} online={online}/>
      <div className="ml-4 hidden md:block">
        <span className="block font-semibold">{name}</span>
        {/*<div className="tooltip text-left" data-tip={format(new Date(sentAt), 'yyyy-MM-dd HH:mm')}>*/}
        {/*  {concatBySpace(t('sentAtAgo'), formatDistanceToNow(parse(sentAt, 'yyyy-MM-dd HH:mm:ss', new Date()), {locale: ltLocale}))}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default ChatListItem
