import React from "react";
import List from "../components/chat/List";
import {useQuery} from "../hooks";

const ChatLayout = ({children}) => {
  const query = useQuery();
  const isSent = query.get('send');

  return (
    <div className="flex" style={{height: 'calc(100vh - 6rem)', opacity: isSent ? 0.3 : 1}}>
      <List/>
      <div className="md:w-8/12 xl:w-9/12 ml-2 flex flex-col flex-grow h-full">
        {children}
      </div>
    </div>
  );
};

export default ChatLayout;
