import {map, getPath, hasProps, getProp, safe, compose, option} from 'crocks';
import {useCallback, useLayoutEffect, useMemo, useState} from "react";
import {routes} from "../App";
import {DatePicker, Select} from "../components"
import {concatBySpace, formatIsoHoursInput, hasLength, isNumeric, mFirstById, sortArrayByPred} from "../util/helper";
import {useIdParam, useMergeReducer, useNestedTranslation} from "../hooks";
import {API_ENDPOINT} from '../api';
import {mappedObjectsToOptions, objectsToOptions} from '../components/Select';
import SwrWrapper from '../components/SwrWrapper';
import {AppendVerticalButton, DeleteButton} from '../components/Button';
import {ReactComponent as IconPill} from "../assets/svg/pill-icon.svg";
import {ReactComponent as IconMeasurement} from "../assets/svg/blood-pressure-icon.svg";
import IconToggle from '../components/IconToggle';
import {mapInputEvent} from '../util/mapper';
import {parseISO, format, isBefore, parse} from 'date-fns';
import {Transition} from '@headlessui/react';
import {TransitionVariant} from '../components/Transition';
import EditLayout from './EditLayout';
import {RouteToLink} from '../components/BreadCrumbs';
import {LabeledFormControl} from '../components/Form';

const useSessionForm = ({
  id = undefined,
  showPatientSelect = true,
} = {}) => {
  const [t] = useNestedTranslation(['sessionLayout']);
  const [state, setState] = useMergeReducer({sessionTimetables: []});
  const patientsSwr = API_ENDPOINT.GET_PATIENTS.swr();
  const devicePackSwr = API_ENDPOINT.GET_DEVICE_PACKS.swr();
  const institutionSwr = API_ENDPOINT.GET_INSTITUTIONS.swr();
  const swr = API_ENDPOINT.GET_SESSION.swr(id);

  useLayoutEffect(() => {
    setState({
      ...swr?.data,
      devicePacks: swr?.data?.devicePacks[0],
      sessionTimetables: getPath(['data', 'sessionTimetables'], swr).chain(safe(hasLength)).option(DEFAULT_SESSIONS_TIMETABLE)
    });
  }, [swr, setState]);

  const setDates = useCallback(dates => {
    const run = compose(
      setState,
      ([dateFrom, dateTo]) => ({dateFrom, dateTo}),
      map(d => format(d, 'yyyy-MM-dd')),
    );
    return run(dates);
  }, [setState]);

  const setDevicePack = (v) =>{
    setState({...state, devicePacks: v})
  }

  const selectedDates = useMemo(() => (
    safe(hasProps(['dateFrom', 'dateTo']), state)
    .map(({dateFrom, dateTo}) => [parseISO(dateFrom), parseISO(dateTo)])
    .option([])
  ), [state]);

  const sortedTimetables = useMemo(() => (
    sortArrayByPred(
      ({time: a}, {time: b}) => isBefore(
        parse(a, 'HH:mm', new Date()),
        parse(b, 'HH:mm', new Date())
      ),
      (state?.sessionTimetables || [])
    )
  ), [state?.sessionTimetables]);

  const patientsOptions = useMemo(() => objectsToOptions(['id', 'fullName'], patientsSwr?.data), [patientsSwr?.data]);
  const setPatientOption = useCallback(patient => setState({patient}), [setState]);
  const append = useCallback(() => {setState({sessionTimetables: [...state.sessionTimetables, {id: + new Date()}]})}, [state, setState]);

  const Form = useMemo(() => (
    <div className="gap-4 grid xl:grid-cols-3 grid-cols-1">
      {showPatientSelect ? (
        <SwrWrapper {...patientsSwr} className="form-control">
          <label className="label">
            <span className="label-text">{t('patient')}</span>
          </label>
          <Select options={patientsOptions} value={state?.patient} onSelect={setPatientOption}/>
        </SwrWrapper>
      ) : null}
      <LabeledFormControl label={t('dates')}>
        <DatePicker id="dates" label={t('dates')} ranged onSelect={setDates} selectedDates={selectedDates} />
      </LabeledFormControl>
      <SwrWrapper className={concatBySpace('form-control', showPatientSelect ? null : 'col-span-2')} label={t('devicePack')} {...devicePackSwr}>
        <label className="label"><span className="label-text">{t('devicePack')}</span></label>
        <Select id="device-pack" onSelect={setDevicePack} options={mapDevicePacks(devicePackSwr.data, institutionSwr.data, t)} value={state.devicePacks}/>
      </SwrWrapper>
      <div className="form-control xl:col-span-3">
        <label className="label"><span className="label-text">{t('medicationPrescription')}</span></label>
        <textarea
          className="textarea textarea-bordered h-24"
          defaultValue={swr?.data?.medicationPrescription}
          onChange={mapInputEvent(medicationPrescription => setState({...state, medicationPrescription}))}
        />
      </div>
      <div className="xl:col-span-3">
        <label className="label"><span className="label-text">{t('timetable.label')}</span></label>
        <div className="space-y-4">
          {
            sortedTimetables.map((item, index) => (
              <TimeTableEntry
                onDelete={
                  (entry) => {
                    setState({
                      sessionTimetables: state.sessionTimetables.filter(i => JSON.stringify(i) !== JSON.stringify(entry))
                    });
                  }
                }
                key={item?.id}
                entry={item}
                onChange={
                  entry => setState({sessionTimetables: state.sessionTimetables.map((e, i) => i === index ? entry : e)})
                }
              />
            ))
          }
        </div>
          <AppendVerticalButton onClick={append}/>
    </div>
    </div>
  ), [
    append,
    devicePackSwr,
    institutionSwr.data,
    patientsOptions,
    patientsSwr,
    selectedDates,
    setDates,
    setPatientOption,
    setState,
    showPatientSelect,
    sortedTimetables,
    state,
    swr?.data?.devicePacks,
    swr?.data?.medicationPrescription,
    t,
  ]);

  return {
    Form,
    state,
    setState,
  }
};

const SessionLayout = () => {
  const [t] = useNestedTranslation(['sessionLayout']);
  const id = useIdParam(routes.sessions.path);
  const swr = API_ENDPOINT.GET_SESSION.swr(id);
  const isNew = useMemo(() => id === 'new', [id]);
  const sessionForm = useSessionForm({id});

  return (
    <EditLayout
      t={t}
      swr={swr}
      fallbackRedirectPath={routes.sessions.path}
      saveFetcher={() => API_ENDPOINT[`${isNew ? 'POST' : 'PUT'}_SESSION`].fetch({id, ...swr.data, ...sessionForm.state})}
      removeFetcher={isNumeric(id) ? () => API_ENDPOINT.DELETE_SESSION.fetch(id) : undefined}
      breadcrumbs={<RouteToLink {...routes.sessions}/>}
    >
      {sessionForm.Form}
    </EditLayout>
  );
};

const TimeTableEntry = ({onDelete, onChange = () => {}, entry}) => {
  const [t] = useNestedTranslation(['sessionLayout']);
  const [type, setType] = useState(entry?.type || SESSION_TIMETABLE_TYPE_MEDICATION);
  const [time, setTime] = useState(entry?.time || '');
  const [name, setName] = useState(entry?.name || '');
  const [text, setText] = useState(entry?.text || '')

  useLayoutEffect(() => {
    if (
      entry?.type !== type
      || entry?.time !== time
      || entry?.name !== name
      || entry?.text !== text
    ) {
      onChange({
        id: entry?.id || + new Date(),
        type,
        time,
        name,
        text,
      });
    }
  }, [onChange, type, time, name, entry?.time, entry?.type, entry?.name, entry?.id, text, entry?.text]);

  return (
    <div className="card shadow bg-base-100">
      <div className="card-body p-4">
        <div className="flex items-center w-full lg:space-x-4 lg:flex-nowrap flex-wrap">
          <div>
            <label className="label">
              <span className="label-text">{t('timetable.type.label')}</span>
            </label>

            <IconToggle
              value={type === SESSION_TIMETABLE_TYPE_MEDICATION ? 0 : 1}
              onChange={type => {
              setType(type === 0 ? SESSION_TIMETABLE_TYPE_MEDICATION : SESSION_TIMETABLE_TYPE_MEASUREMENT)
            }}>
              <IconPill className="w-full"/>
              <IconMeasurement className="w-full"/>
            </IconToggle>
          </div>

          <div className="form-control flex-grow ml-4 lg:ml-0">
            <label className="label">
              <span className="label-text">{t('timetable.time')}</span>
            </label>
            <input text="" className="input input-bordered" onChange={mapInputEvent(compose(setTime, formatIsoHoursInput))} value={time} />
          </div>

           <div className="form-control flex-grow w-full">
            <label className="label">
              <span className="label-text">{t('timetable.title')}</span>
            </label>
            <input type="text" className="input input-bordered" onChange={mapInputEvent(setName)} value={name}/>
          </div>
        </div>
        <Transition show={type === SESSION_TIMETABLE_TYPE_MEDICATION} {...TransitionVariant.opacity}>
          <label className="label  lg:mt-4">
            <span className="label-text">{t('timetable.directions')}</span>
          </label>
          <textarea className="textarea w-full textarea-bordered" defaultValue={text} onChange={mapInputEvent(setText)} />
        </Transition>
        <div className="text-right mt-8">
          <DeleteButton onClick={() => onDelete(entry)}  disableWait={500} confirmWait={1000}/>
        </div>
      </div>
    </div>
  );
};

const mapDevicePacks = (devicePacksResponse, institutionsResponse, t) => mappedObjectsToOptions(
  [
    compose(option(null), getProp('id')),
    ({id, institution, serialNo}) => {
      const institutionTitle = mFirstById(institution, institutionsResponse || [])
        .chain(getProp('title'))
        .option(null);

      return `${id}: ${serialNo}` + (institutionTitle ? ` (${t('devicePackFrom')} "${institutionTitle}")` : '')
    },
  ],
  (devicePacksResponse || [])
);

export const SESSION_TIMETABLE_TYPE_MEASUREMENT = 'measurement';
export const SESSION_TIMETABLE_TYPE_MEDICATION = 'medication';

const DEFAULT_SESSIONS_TIMETABLE = [
  {
    id: 1 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name: "1-oje nakties pusėje (gilaus miego metu) (deep sleep, DS)",
    time: "05:00",
  },
  {
    id: 2 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name:
    "2-os nakties pusės, paryčio prieš atsikeliant (early morning, EM)",
    time: "07:00",
  },
  {
    id: 3 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name: "Ryte prieš vaistus (morning before drugs, MBD)",
    time: "08:00",
  },
  {
    id: 4 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEDICATION,
    name: "Rytiniai vaistai",
    time: "08:00",
    text: '1x po valgio',
  },
  {
    id: 5 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name: "Ryte po vaistų (morning after drugs, MAD)",
    time: "10:00",
  },
  {
    id: 6 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name: "Dienos metu prieš vaistus (daytime before drugs, DBD)",
    time: "13:00",
  },
  {
    id: 7 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEDICATION,
    name: "Vaistai per pietus",
    time: "15:00",
    text: '1x po valgio',
  },
  {
    id: 8 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name: "Dienos metu po vaistų (daytime after drugs, DAD)",
    time: "15:00",
  },
  {
    id: 9 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name: "Vakare prieš vaistus (evening before drugs, EBD)",
    time: "19:00",
  },
  {
    id: 10 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEDICATION,
    name: "Vakariniai vaistai",
    time: "19:00",
    text: '1x po valgio',
  },
  {
    id: 11 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name: "Vakare po vaistų (evening after drugs, EAD)",
    time: "21:00",
  },
  {
    id: 12 + new Date(),
    type: SESSION_TIMETABLE_TYPE_MEASUREMENT,
    name: "Prieš užmiegant (perisleep, PS)",
    time: "23:00",
  },
]

export default SessionLayout;

export {
  useSessionForm,
}
