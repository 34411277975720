export const TransitionVariant = {
  opacity: {
    enter: 'transition-opacity duration-100',
    enterFrom: 'opacity-0',
    enterTo: 'opacity-100',
    leave: 'transition-opacity duration-200',
    leaveFrom: 'opacity-100',
    leaveTo: 'opacity-0',
  },
  scale: {
    enter: 'transition-all duration-200 transform',
    enterFrom: 'opacity-0 scale-0',
    enterTo: 'opacity-100 scale-100',
    leave: 'transition-all duration-500 transform',
    leaveFrom: 'opacity-100 scale-100',
    leaveTo: 'opacity-0 scale-0',
  },
  scaleRightBottom: {
    enter: 'transition-all duration-300 ease-in-out transform',
    leave: 'transition-all duration-300 ease-in-out transform',
    enterFrom: 'opacity-0 scale-0 translate-x-1/2 translate-y-1/2',
    enterTo: 'opacity-100 scale-100 translate-x-0 translate-y-0',
    leaveTo: 'opacity-0 scale-0 translate-x-1/2 translate-y-1/2',
    leaveFrom: 'opacity-100 scale-100 translate-x-0 translate-y-0',
  },
  slideFromTop: {
    enter: 'transition-all duration-300 ease-in-out transform',
    leave: 'transition-all duration-300 ease-in-out transform',
    enterFrom: '-translate-y-full opacity-0 h-0',
    enterTo: 'translate-y-0 opacity-100 h-10',
    leaveFrom: 'translate-y-0 opacity-100 h-10',
    leaveTo: 'translate-y-full opacity-0 h-0',
  },
  slide: {
    enter: 'transition duration-200 ease-in-out transform absolute top-0 bottom-0',
    leave: 'transition duration-200 ease-in-out transform absolute top-0 bottom-0',
    entered: 'transition duration-200 ease-in-out transform absolute top-0 bottom-0',
    enterFrom: '-translate-x-8 opacity-0',
    enterTo: 'translate-x-0 opacity-100',
    leaveFrom: 'translate-x-0 opacity-100',
    leaveTo: 'translate-x-8 opacity-0',
  }
}
