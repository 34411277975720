import Calendar from './Calendar'
import {TransitionVariant} from "./Transition"
import {Transition} from "@headlessui/react"
import {format} from 'date-fns'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useNestedTranslation} from '../hooks'
import {concatBySpace, strToDates} from '../util/helper'
import CardWithImage from './CardWithImage'
import { ReactComponent as IconLogo } from "../assets/svg/app-icon.svg";
import Popover from './Popover';
import Title from './Title'
import Hr from './Hr';
import NullWrapper from './NullWrapper';
import ConfigurableTable from './ConfigurableTable';
import Select from './Select'

export const Bg = ({children, className, ...props}) => (
  <div className={concatBySpace('bg-base-300 w-full min-h-screen', className)} {...props}>
    {children}
  </div>
);

export const H1 = ({className = 'font-extrabold text-xl text-gray-900', children, ...props}) => (
  <h1 className={className} {...props}>{children}</h1>
);

export const DatePicker = ({
  availableDates = [],
  cellContainerProps,
  fromToday = false,
  wrapperProps = null,
  isOpen = false,
  lookBack = false,
  onSelect = () => {},
  ranged = false,
  placeholder,
  selectedDates = [],
  ...props
}) => {
  const [tip] = useNestedTranslation(['input.placeholder'])
  const dateJoin = useMemo(() => ' - ', [])
  const dateFormat = useMemo(() => 'yyyy-MM-dd', [])
  const _placeholder = useMemo(() => placeholder ? placeholder : tip(ranged ? 'dateRange' : 'date'), [tip, placeholder, ranged])
  const [_isOpen, setIsOpen] = useState(isOpen)
  const [_selectedDates, setSelectedDates] = useState(selectedDates)
  const [value, setValue] = useState('')
  const strToDatesP = useCallback(datesString => strToDates(dateJoin, dateFormat, datesString), [dateJoin, dateFormat])

  useEffect(() => {
    setSelectedDates(selectedDates);
    setValue(selectedDates.map(d => format(d, dateFormat)).join(dateJoin))
  }, [selectedDates, dateFormat, dateJoin]);

  const _onSelect = useCallback((dates) => {
   if ((ranged && dates?.length < 2) || (!ranged && !dates?.length)) return null
   setIsOpen(false)
   setValue(dates.map(d => format(d, dateFormat)).join(dateJoin))
   onSelect(dates)
  }, [onSelect, ranged, dateJoin, dateFormat])

  const onInputFocus = useCallback(({target: {value}}) => {
    setIsOpen(true)
    if (value) {
      setSelectedDates(strToDatesP(value))
    }
  }, [strToDatesP, setIsOpen])

  const onCalendarMouseLeave = useCallback(() => {
    setIsOpen(false)
    document?.activeElement?.blur()
  }, [setIsOpen])

  return (
    <div className="relative" {...wrapperProps} >
      <input className="input input-bordered w-full" {...props} readOnly  placeholder={_placeholder} onFocus={onInputFocus} value={value} onChange={useCallback(({target: {value}}) => setValue(value), [setValue])}/>
      <Transition
        {...TransitionVariant.opacity}
        enter={concatBySpace(TransitionVariant.opacity.enter, 'z-30')}
        leave={concatBySpace(TransitionVariant.opacity.leave, 'z-30')}
        show={_isOpen}>
        {<Calendar className="absolute z-30" onSelect={_onSelect} {...{cellContainerProps, ranged, lookBack, fromToday, selectedDates: _selectedDates, availableDates}} onMouseLeave={onCalendarMouseLeave}/>}
      </Transition>
    </div>
  )
}

export const FloatingLoadingWraper = ({className, children, loading = false, ...props}) => {
  const [t] = useNestedTranslation(['state'])

  return (
    <div className={concatBySpace('relative', className)} {...props}>
      {children}
      <Transition {...TransitionVariant.opacity} show={loading}>
        <div className={'badge absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'}>{t('loading')}</div>
      </Transition>
    </div>
  )
}

export const JsonErrorMessage = ({children}) => {
  const [t] = useNestedTranslation(['state'])

  return (
    <>
      <h2>{t('error')}</h2>
      <pre>{JSON.stringify(children, null, 2)}</pre>
    </>
  )
}

export const Brand = ({title = 'Telehipertenzija', className = 'flex space-x-4 items-center', textClassName, iconClassName = 'text-gray-600 w-8 h-8', ...props}) => (
  <div className={className}>
    <IconLogo className={iconClassName}/>
    <H1 className={textClassName}>{title}</H1>
  </div>
);

export {
  CardWithImage,
  ConfigurableTable,
  Hr,
  NullWrapper,
  Select,
  Popover,
  Title,
}
