import {useMemo} from "react";
import {Hr, Title} from "../components";
import BreadCrumbs from "../components/BreadCrumbs";
import {DeleteButton, SaveButton} from "../components/Button";
import {useNestedTranslation, useNotFoundErrorGuard, useRemoveEntity, useSaveEntity} from "../hooks";
import {flattenFragments} from "../util/helper";
import {isFunction} from 'crocks';
import {useLogin} from "../api/login";

const EditLayout = ({
                      breadcrumbs = [],
                      children,
                      fallbackRedirectPath,
                      saveFetcher,
                      swr,
                      removeFetcher = null,
                      t,
                    }) => {
  const [te] = useNestedTranslation(['editLayout']);
  const {save} = useSaveEntity(saveFetcher, fallbackRedirectPath);
  const {remove} = useRemoveEntity(removeFetcher, fallbackRedirectPath);
  const {data: loginData,} = useLogin();

  const Buttons = useMemo(() => (
    <div className="mt-4 flex justify-end space-x-4">
      <DeleteButton disabled={!isFunction(remove)} onClick={remove}/>
      <SaveButton disabled={!isFunction(save)} onClick={save}>{te('save')}</SaveButton>
    </div>
  ), [save, te, remove]);

  useNotFoundErrorGuard(swr, fallbackRedirectPath);

  if(loginData?.roles?.length === 1 && loginData?.roles[0] === 'ROLE_USER'){
    return children
  }

  return <>
    <div className="flex justify-between flex-wrap space-y-4 md:space-y-0">
      <BreadCrumbs>
        {flattenFragments(breadcrumbs)}
        <Title>{t('title')}</Title>
      </BreadCrumbs>
      {Buttons}
    </div>
    <Hr/>
    {children}
    {Buttons}
  </>;
}

export default EditLayout;
