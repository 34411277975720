import {concatBySpace} from '../util/helper';
import {useCallback, useMemo} from 'react';
import {useNestedTranslation} from '../hooks';
import {useHistory, useLocation} from 'react-router-dom';
import {
  ifElse,
  identity,
  isDefined,
  isArray,
} from 'crocks';

const Tabs = ({className, children, tabMap, tabClassName = 'tab tab-bordered'}) => {
  const [t] = useNestedTranslation(['tabs']);
  const location = useLocation();
  const history = useHistory()
  const tabs = useMemo(() => {
    const childrenArray = ifElse(isArray, identity, x => [x], children);
    return childrenArray.map((component, index) => {
      const text = tabMap?.[index]?.[1] || `${t('unnamedTab')}-${index+1}`;
      const hash = index > 0 ? tabMap?.[index]?.[0] || `#${index}` : '';

      return {
        hash,
        text,
        component,
        tabButton: (
          <button
            key={index}
            className={concatBySpace(tabClassName, (location.hash === '' && index === 0) || location.hash === hash ? 'tab-active' : null)}
            onClick={() => {history.replace(`${location.pathname}${hash}`)}}>
            {text}
          </button>
        )
      };
    });
  }, [children, tabMap, history, location.hash, location.pathname, t, tabClassName]);

  const isHash = useCallback(x => y => String(x).toLowerCase() === String(y).toLowerCase(), []);
  const isDefault = useCallback(currentIndex => hash => hash === '' && currentIndex === 0, []);
  const isTabActive = useCallback((itemHash, itemIndex) => isHash(itemHash)(location.hash) || isDefault(itemIndex)(location.hash), [location.hash, isDefault, isHash]);
  const tabFound = useMemo(() => isDefined(tabs.find(({hash}) => hash === location.hash)), [location.hash, tabs]);

  return (
    <div>
      <div className={concatBySpace('tabs', className)}>
        {tabs.map(({tabButton}) => tabButton)}
      </div>
      {tabFound ? tabs.map((tab, index) => (
        <div key={index} className={isTabActive(tab.hash, index) ? '' : 'hidden'}>
          {tab.component}
        </div>
      )) : (
        <div key={0}>{tabs[0].component}</div>
      )}
    </div>
  );
};

export default Tabs;
