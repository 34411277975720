import {useLayoutEffect, useMemo} from 'react';
import {API_ENDPOINT} from '../api';
import {routes} from '../App';
import {mappedObjectsToOptions, objectsToOptions, SelectMultiple} from '../components/Select';
import {useIdParam, useMergeReducer, useNestedTranslation} from '../hooks';
import {mapInputEvent} from '../util/mapper';
import EditLayout from './EditLayout';
import {getPropOr} from 'crocks';
import {RouteToLink} from '../components/BreadCrumbs';
import {Input, LabeledFormControl} from '../components/Form';
import {isNumeric} from '../util/helper';

const InstitutionLayout = () => {
  const id = useIdParam(routes.institutions.path);
  const [state, setState] = useMergeReducer();
  const [t] = useNestedTranslation(['institutionLayout']);
  const swr = API_ENDPOINT.GET_INSTITUTION.swr(id);
  const devicesSwr = API_ENDPOINT.GET_DEVICES.swr();
  const doctorsSwr = API_ENDPOINT.GET_DOCTORS.swr();
  const isNew = useMemo(() => id === 'new', [id]);

  useLayoutEffect(() => { setState({...swr?.data}) }, [setState, swr]);

  return (
    <EditLayout
      breadcrumbs={RouteToLink(routes.institutions)}
      fallbackRedirectPath={routes.institutions.path}
      saveFetcher={() => API_ENDPOINT[`${isNew ? 'POST' : 'PUT'}_INSTITUTION`].fetch({...state, id})}
      removeFetcher={isNumeric(id) ? () => API_ENDPOINT.DELETE_INSTITUTION.fetch(id) : undefined}
      swr={swr}
      t={t}
    >
      <div className="space-y-4">
        <LabeledFormControl className="lg:col-span-2" label={t('title')}>
          <Input value={state?.title || ''} onChange={mapInputEvent(title => setState({title}))} />
        </LabeledFormControl>
        <LabeledFormControl label={t('devices')}>
          <SelectMultiple options={mappedObjectsToOptions(
            [
              getPropOr('', 'id'),
              ({ serialNo, deviceApi }) => `${serialNo}: ${deviceApi}`
            ],
            devicesSwr?.data || []
          )}
          value={state?.devices}
          onSelect={devices => setState({devices})}
          />
        </LabeledFormControl>
        <LabeledFormControl label={t('doctors')}>
          <SelectMultiple options={objectsToOptions(['id', 'fullName'], doctorsSwr?.data || [])} onSelect={doctors => setState({doctors})} value={state?.doctors || []}/>
        </LabeledFormControl>
      </div>
    </EditLayout>
  );
};

export default InstitutionLayout;
