import SwrWrapper from '../components/SwrWrapper';
import {FilterIcon} from '@heroicons/react/solid';
import {Table} from '../components/Table';
import {asciifyLT, componentToString, parseInt10, parseJsonOr} from '../util/helper';
import {isNumber, isFunction, map, branch, curry, isArray, safe, compose, assign} from 'crocks';
import {mapCheckBoxEvent, mapInputEvent, mapInputEventOr} from '../util/mapper';
import {useCallback, useMemo, useRef} from 'react';
import {useMergeReducer, useOutsideClick} from '../hooks';
import {useTranslation} from 'react-i18next';

const ConfigurableTable = ({linkProps, columnSettings, localStorageSettingsKey, data, isValidating, error, queryPlaceholder}) => {
  const {t} = useTranslation();
  const [state, setState] = useMergeReducer({
    showColumnSettings: false,
    query: '',
  });

  const amendLocalStorageState = useCallback((savedColumnSettings) =>
    Object.entries(columnSettings)
    .reduce((carry, [key, value]) => assign(
      {[key]: {...value, ...savedColumnSettings?.[key]}},
      carry
    ), {}), [columnSettings]);

  const parseLocalStorage = compose(
    amendLocalStorageState,
    parseJsonOr(columnSettings)
  );

  const [tableState, setTableState] = useMergeReducer(
    parseLocalStorage(localStorage.getItem(localStorageSettingsKey))
  );

  const setColumnSetting = curry((columnKey, value) => {
    const newState = {
      ...tableState,
      [columnKey]: {
        ...tableState[columnKey],
        ...value
      }
    };

    setTableState(newState);
    localStorage.setItem(localStorageSettingsKey, JSON.stringify(newState));
  });

  const sortedColumns = useMemo(() => Object
    .keys(columnSettings)
    .filter(column => tableState[column].on)
    .sort((a, b) => (
      tableState[a].order < tableState[b].order) ? -1 : 1),
    [columnSettings, tableState]
  );

  const filterByQuery = useCallback(
    pairs => state?.query?.length
    ? pairs?.filter(
      pair => String(componentToString(pair.snd())).match(new RegExp(asciifyLT(state.query), 'gi'))
    )
    : pairs

    ,[state.query]);

  const rowToColums = useCallback(
    row => sortedColumns.map(key => columnSettings[key].renderer(row)),
    [sortedColumns, columnSettings]
  );

  const _items = useMemo(() =>
    safe(isArray, data) // Array<Object>
    .map(map(branch)) // Array<Pair<Object, Object>>
    .map(map(map(rowToColums))) // Array<Pair<Object, ReactComponent[]>>
    .map(filterByQuery) // Array<Pair<Object, ReactComponent[]>>
    .option([]),
    [data, filterByQuery, rowToColums]
  );

  const columnSettingsRef = useRef();

  useOutsideClick(columnSettingsRef, () => setState({showColumnSettings: false}), state?.showColumnSettings);

  return (
    <SwrWrapper isValidating={isValidating} error={error} className="space-y-8">
      <div className="flex space-x-4 items-center justify-between w-full">
        <input className="input input-bordered w-full" id="query" placeholder={queryPlaceholder || t('searchQuery')} onChange={mapInputEvent(query => setState({query}))} value={state.query}/>
        <div className="dropdown dropdown-left">
          <div className="tooltip" data-tip={t('columnSettings')}>
          <div className="btn-group">
            <button className="btn" onClick={() => setState({showColumnSettings: !state.showColumnSettings})}>
              <FilterIcon className="w-6 h-6"/>
            </button>
          </div>
          </div>
          <div className="dropdown-content bg-base-100 shadow-lg border border z-50 rounded-md">
          {Object.keys(tableState).map((column) => (
            <li key={column} className="flex items-center px-4 py-2">
              <label className="cursor-pointer label">
                <input type="checkbox" className="checkbox checkbox-sm" onChange={mapCheckBoxEvent(tableState[column].on, on => setColumnSetting(column, {on}))} checked={tableState?.[column]?.on} />
                <span className="ml-4 label-text whitespace-nowrap">{columnSettings[column].translated}</span> 
              </label>
              <input
                type="number"
                className="input input-sm rounded-none text-right input-ghost w-20 flex-grow"
                onChange={mapInputEventOr('0', order => setColumnSetting(column, {order: parseInt10(order)}))}
                value={safe((num) => !isNaN(num) && isNumber(num), tableState[column].order).option(0)}
              />
            </li>
          ))}
          </div>
        </div>
      </div>
      <Table
        headers={sortedColumns.map(key => columnSettings[key].translated)}
        items={_items.map(i => i.snd())}
        linkProps={index => isFunction(linkProps) ? linkProps(_items[index].fst()) : undefined}
      />
    </SwrWrapper>
  );
};

export default ConfigurableTable;
