import {
  curry,
  getPathOr,
  getProp,
  isArray,
  isSame,
  safe,
} from 'crocks';
import Maybe from 'crocks/Maybe';

/**
 * @param {any} fallback A value to fallback to if event.target.value unavailable
 * @param {function} map A function that receives the events value
 * @param {object} event An Event that has path target.value
 */
export const mapInputEventOr = curry((fallback, map, event) => map(getPathOr(fallback, ['target', 'value'], event)));

/**
 * @param {function} map A function that receives the events value
 * @param {object} event An Event that has path target.value
 */
export const mapInputEvent = mapInputEventOr('');

/**
 * @param {any} which A value that event.which must match
 * @param {function} callback A function that receives the events value
 * @param {object} event An event object
 */
export const runWithEventWhich = curry((which, callback, event) => 
  Maybe.of(event)
  .chain(getProp('which'))
  .chain(safe(isSame(which)))
  .map(() => callback(event))
)

/**
 * @param {any} fallback A value to fallback to if event.target.checked unavailable
 * @param {function} map A function that receives the events value
 * @param {object} event An object that has path target.checked
 */
export const mapCheckBoxEvent = curry((fallback, map, event) => map(getPathOr(fallback, ['target', 'checked'], event)));

export const forceArray = a => safe(isArray, a).option([]);
