import React from "react";
import {useNestedTranslation} from "../hooks";
import ChatLayout from "../layout/ChatLayout";

const ChatListPage = () => {
  const [t] = useNestedTranslation(['chatLayout']);

  return (
    <ChatLayout>
      <div className={'text-center h-full flex items-center justify-center'}>
        {t('selectChatToGetStarted')}
      </div>
    </ChatLayout>
  )
}

export default ChatListPage
