import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import lt from './i18n/lt.json'


i18n
  .use(initReactI18next)
  .init({
    interpolation: {escapeValue: false},
    lng: 'lt',
    resources: {lt: {translation: lt}}
  })
