import {memo} from "react";
import {styleCombo} from "../util";
import {concatBySpace} from "../util/helper";

const CardWithImage = memo(({className, children, src, alt = '', ...props}) => (
  <div className={concatBySpace(`shadow-2xl flex rounded-md bg-white overflow-hidden md:min-w-[640px] ${styleCombo.responsiveMargin}`, className)} {...props}>
    <div className="card w-full space-y-8  sm:min-w-[320px]">
      <div className="card-body">
        {children}
      </div>
    </div>
    <img className="bg-gray-100 select-none w-full object-cover hidden md:block" src={src} alt={alt} />
  </div>
));

export default CardWithImage;
