import {useLayoutEffect, useMemo} from "react";
import {API_ENDPOINT} from "../api";
import {routes} from "../App";
import {Select} from "../components";
import {useIdParam, useMergeReducer, useNestedTranslation} from "../hooks";
import {mapInputEvent} from "../util/mapper";
import EditLayout from './EditLayout';
import {map, getPropOr} from 'crocks';
import {mappedObjectsToOptions, objectsToOptions, SelectMultiple} from "../components/Select";
import {RouteToLink} from "../components/BreadCrumbs";
import {Input, LabeledFormControl} from "../components/Form";

const DevicePackLayout = () => {
  const id = useIdParam(routes.devicePacks.path);
  const swr = API_ENDPOINT.GET_DEVICE_PACK.swr(id);
  const [t] = useNestedTranslation(['devicePackLayout']);
  const [state, setState] = useMergeReducer();
  const institutionsSwr = API_ENDPOINT.GET_INSTITUTIONS.swr();
  const devicesSwr = API_ENDPOINT.GET_DEVICES.swr();
  const isNew = useMemo(() => id === 'new', [id]);

  useLayoutEffect(() => {setState(swr?.data)}, [swr, setState]);

  return (
    <EditLayout
      breadcrumbs={RouteToLink(routes.devicePacks)}
      fallbackRedirectPath={routes.devicePacks.path}
      removeFetcher={isNew ? undefined : () => API_ENDPOINT.DELETE_DEVICE_PACK.fetch(id)}
      saveFetcher={() => API_ENDPOINT[`${isNew ? 'POST' : 'PUT'}_DEVICE_PACK`].fetch({...state, id})}
      swr={swr}
      t={t}
    >
      <div className="gap-4 grid xl:grid-cols-2 grid-cols-1">
        <LabeledFormControl label={t('serialNo')}>
          <Input
            value={getPropOr('', 'serialNo', state)}
            onChange={mapInputEvent(serialNo => setState({serialNo}))}
          />
        </LabeledFormControl>
        <LabeledFormControl label={t('institution')}>
          <Select
            value={getPropOr('', 'institution', state)}
            onSelect={institution => setState({institution})}
            options={objectsToOptions(['id', 'title'], institutionsSwr?.data || [])}
          />
        </LabeledFormControl>
        <LabeledFormControl className="col-span-2" label={t('devices')}>
          <SelectMultiple
            value={map(({id}) => id, getPropOr([], 'devices', state))}
            onSelect={devices => setState({devices: map(id => ({id}), devices)})}
            options={mappedObjectsToOptions(
              [
                getPropOr('', 'id'),
                ({ serialNo, deviceApi }) => `${serialNo}: ${deviceApi}`
              ],
              devicesSwr?.data || []
            )}
          />
        </LabeledFormControl>
      </div>
    </EditLayout>
  );
}

export default DevicePackLayout;
