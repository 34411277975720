import {useLogin, ROLES} from '../api/login';
import {caseMap, concatBySpace, filterJustAndFold, hasLength, isNumeric, mFirstByIdRev, titleCaseWord} from '../util/helper';
import {useNestedTranslation} from '../hooks';
import {API_ENDPOINT, DEVICE_API} from '../api';
import SwrWrapper from '../components/SwrWrapper';
import {useCallback, useMemo} from 'react';
import {isPast, parse} from 'date-fns';
import PatientLayout from './PatientLayout';
import {Nothing} from 'crocks/Maybe';
import {
  First,
  chain,
  equals,
  filter,
  getPath,
  getProp,
  isArray,
  map,
  mconcatMap,
  not,
  option,
  pipe,
  safe,
  valueOf,
} from 'crocks';
import {RISKS} from '../business';

const PROP_SESSION_DATE_TO = 'dateTo';

const DashBoardLayout = () => {
  const {data} = useLogin();
  return caseMap(
    () => null,
    [
      [isAdmin, () => (
        <div className="space-y-8">
          <PatientStats />
          <SessionStats />
          <DoctorStats />
          <DevicePackStats />
          <DeviceStats />
        </div>
      )],
      [isPatient, () => <PatientLayout id={data?.patient}/>]
    ],
    getPath(['data', 'roles'], useLogin()).option([])
  );
};

const DeviceStats = () => {
  const [tb] = useNestedTranslation(['dashboardLayout.block.device']);
  const swr = API_ENDPOINT.GET_DEVICES.swr();
  const deviceApisSwr =  API_ENDPOINT.GET_DEVICE_APIS.swr();
  const total = useMemo(() => (
    getPath(['data', 'length'], swr)
    .chain(safe(isNumeric))
    .option(0)
  ), [swr]);

  const devicesApiNames = useMemo(() => pipe(
    getProp('data'),
    chain(safe(isArray)),
     map(map(pipe(
      getProp('deviceApi'),
      chain(mFirstByIdRev(deviceApisSwr.data || [])),
      chain(getProp('title')),
    ))),
    map(filterJustAndFold),
    chain(safe(hasLength)),
    option([])
  )(swr), [swr, deviceApisSwr.data]);

  const omrons = useMemo(() => filter(equals(DEVICE_API.OMRON), devicesApiNames).length, [devicesApiNames]);
  const fitbits = useMemo(() => filter(equals(DEVICE_API.FITBIT), devicesApiNames).length, [devicesApiNames]);
  const polars = useMemo(() => filter(equals(DEVICE_API.POLAR), devicesApiNames).length, [devicesApiNames]);

  return (
    <StatWrapper swr={swr} t={tb}>
      <Stat title={tb('total')} value={total} />
      <Stat title={tb('omrons')} value={omrons} />
      <Stat title={tb('fitbits')} value={fitbits} />
      <Stat title={tb('polars')} value={polars} />
    </StatWrapper>
  );
};

const DevicePackStats = () => {
  const [tb] = useNestedTranslation(['dashboardLayout.block.devicePack']);
  const swr = API_ENDPOINT.GET_DEVICE_PACKS.swr();
  const deviceApisSwr =  API_ENDPOINT.GET_DEVICE_APIS.swr();
  const total = useMemo(() => (
    getPath(['data', 'length'], swr)
    .chain(safe(isNumeric))
    .option(0)
  ), [swr]);

  const deviceApiIds = useMemo(() => pipe(
    getProp('data'),
    map(map(getProp('id'))),
    map(filterJustAndFold),
    map(a => a.sort()),
    option([]),
  )(deviceApisSwr), [deviceApisSwr]);

  const isDevicePackComplete = useCallback(devicePack => pipe(
    getProp('devices'),
    map(map(getProp('deviceApi'))),
    map(filterJustAndFold),
    chain(safe(a => [...a].sort().toString() === deviceApiIds.toString())),
    map(() => true),
    option(false),
  )(devicePack), [deviceApiIds]);

  const complete = useMemo(() => pipe(
    getProp('data'),
    map(filter(isDevicePackComplete)),
    chain(getProp('length')),
    chain(safe(isNumeric)),
    option(0),
  )(swr), [swr, isDevicePackComplete]);

  const incomplete = useMemo(() => total - complete, [total, complete]);

  return (
    <StatWrapper swr={swr} t={tb}>
      <Stat title={tb('total')} value={total} />
      <Stat title={tb('complete')} value={complete} valueClassName="text-success" />
      <Stat title={tb('incomplete')} value={incomplete} valueClassName="text-error" />
    </StatWrapper>
  );
};

const SessionStats = () => {
  const [tb] = useNestedTranslation(['dashboardLayout.block.session']);
  const swr = API_ENDPOINT.GET_SESSIONS.swr();
  const total = useMemo(() => (
    getPath(['data', 'length'], swr)
    .chain(safe(isNumeric))
    .option(0)
  ), [swr]);

  const active = useMemo(() => pipe(
    getProp('data'),
    chain(safe(isArray)),
    map(map(getValidSessionEnd)),
    map(filter(not(equals(Nothing())))),
    chain(getProp('length')),
    chain(safe(isNumeric)),
    option(0),
  )(swr), [swr]);

  return (
    <StatWrapper swr={swr} t={tb}>
      <Stat title={tb('total')} value={total} />
      <Stat title={tb('active')} value={active} valueClassName="text-success" />
    </StatWrapper>
  );
};

const DoctorStats = () => {
  const [tb] = useNestedTranslation(['dashboardLayout.block.doctor']);
  const swr = API_ENDPOINT.GET_DOCTORS.swr();
  const total = useMemo(() => (
    getPath(['data', 'length'], swr)
    .chain(safe(isNumeric))
    .option(0)
  ), [swr]);

  return (
    <StatWrapper swr={swr} t={tb}>
      <Stat title={tb('total')} value={total} />
    </StatWrapper>
  );
};

const PatientStats = () => {
  const [tb] = useNestedTranslation(['dashboardLayout.block.patient']);
  const swr = API_ENDPOINT.GET_PATIENTS.swr();
  const sessionSwr = API_ENDPOINT.GET_SESSIONS.swr();
  const total = useMemo(() => (
    getPath(['data', 'length'], swr)
    .chain(safe(isNumeric))
    .option(0)
  ), [swr]);

  const active = useMemo(() => pipe(
    getProp('data'),
    chain(safe(isArray)),
    map(pipe(
      map(pickFirstActiveSessions(sessionSwr.data || [])),
      filter(not(equals(Nothing()))),
    )),
    chain(getProp('length')),
    chain(safe(isNumeric)),
    option(0),
  )(swr), [swr, sessionSwr]);

  const riskNames = useMemo(() => Object.values(RISKS).map(a => a.name), []);

  const patientRisks = useMemo(() => pipe(
    getProp('data'),
    chain(safe(isArray)),
    map(map(pipe(
      getProp('riskName'),
      chain(safe(a => riskNames.includes(a))),
    ))),
    map(filterJustAndFold),
    chain(safe(hasLength)),
    option([])
  )(swr), [swr, riskNames]);


  return (
    <StatWrapper swr={swr} t={tb}>
      <Stat title={tb('total')} value={total} />
      <Stat title={tb('active')} value={active} valueClassName="text-success" />
      {
        riskNames.map(riskName => (
          <Stat
            key={riskName}
            title={tb(`risk${titleCaseWord(riskName)}`)}
            value={patientRisks.filter(equals(riskName)).length || 0}
            valueColor={RISKS[riskName].color}
          />
        ))
      }
    </StatWrapper>
  );
};

const StatWrapper = ({t, children, swr}) => (
  <SwrWrapper {...swr}>
    <h2 className="text-xl font-semibold">{t('title')}</h2>
    <div className="w-full shadow stats">{children}</div>
  </SwrWrapper>
);

const Stat = ({title, value, valueClassName, valueColor}) => (
  <div className="stat place-items-center place-content-center">
    <div className="stat-title">{title}</div>
    <div className={concatBySpace('stat-value', valueClassName)} style={{color: valueColor}}>{value}</div>
  </div>
);

const isAdmin = roles => roles.includes(ROLES.DOCTOR) || roles.includes(ROLES.SUPER);
const isPatient = roles => roles.includes(ROLES.PATIENT);

const getNonPastProp = prop => pipe(
  getProp(prop),
  map(str => parse(str, 'yyyy-MM-dd', new Date())),
  chain(safe(not(isPast))),
);

const getValidSessionEnd = getNonPastProp(PROP_SESSION_DATE_TO);

const mSessionIdIsActive = sessions => pipe(
  mFirstByIdRev(sessions),
  chain(getValidSessionEnd),
);

const pickFirstActiveSessions = sessions => pipe(
  getProp('sessions'),
  map(mconcatMap(First, mSessionIdIsActive(sessions))),
  chain(valueOf),
);

export default DashBoardLayout;
