import React, {useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory, useLocation, useParams} from "react-router-dom";
import {caseMap, parseInt10} from "../util/helper";
import {useNotification} from "../components/Notification";
import {
  First,
  firstToMaybe,
  getProp,
  hasProp,
  identity,
  ifElse,
  isArray,
  isDefined,
  isNumber,
  isObject,
  isString,
  mconcat,
  not,
  or,
  safe,
} from 'crocks';

export const useNestedTranslation = (paths) => {
  const {t} = useTranslation()
  if (typeof paths === 'string') {
    paths = [paths]
  }

  return useMemo(() => paths.map(path => str => t(`${path}.${str}`)), [t, paths])
}

export const useMergeReducer = (defaults) => useReducer((older = {}, newer = {}) => ifElse(
  isDefined,
  () => ({...older, ...newer}),
  () => older,
  Object.entries(newer).find(([key, value]) => !(hasProp(key, older) && value === older?.[key])),
), defaults);

export const useOutsideClick = (ref, callback, shouldListen) => {
  const _callback = useCallback((event) => {
    if (!event?.path?.includes(ref?.current)) {
      callback(event);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const startListener = useCallback(() => {
    document.removeEventListener('click', _callback);
    document.addEventListener('click', _callback);
  }, [_callback]); //eslint-disable-line react-hooks/exhaustive-deps

  const stopListener = useCallback(() => {
    document.removeEventListener('click', _callback);
  }, [_callback]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof shouldListen === 'undefined') {
      return;
    }

    if (shouldListen) {
      startListener();
    } else {
      stopListener();
    }

    return () => {
      stopListener()
    };
  }, [shouldListen, startListener, stopListener]);

  return {
    startListener,
    stopListener,
  }
}

export const useIdParam = (fallbackPath, fallbackValue) => {
  const [te] = useNestedTranslation(['error']);
  const params = useParams();
  const history = useHistory();
  const {notify} = useNotification();
  const isNewIdParam = a => a === 'new';

  const newId = useMemo(() =>
      getProp('id', params)
        .chain(safe(isNewIdParam))
    , [params]);

  const id = useMemo(() =>
      getProp('id', params)
        .map(parseInt10)
        .chain(safe(not(isNaN)))
        .alt(newId)
        .alt(safe(isNumber, fallbackValue))
        .option(te('missingUrlParamId'))
    , [params, te, newId, fallbackValue]);

  const guards = useMemo(() => ({
    missingUrlParamId: ifElse(or(isNumber, isNewIdParam), identity, (id) => {
      notify({children: id, className: 'alert-error'});
      history.replace(fallbackPath);
    }),
  }), [history, notify, fallbackPath])

  useLayoutEffect(() => {
    guards.missingUrlParamId(id)
  }, [guards, id]);

  return id;
};

export const useNotFoundErrorGuard = (swr, fallbackPath, errorMessage) => {
  const [te] = useNestedTranslation(['error']);
  const history = useHistory();
  const {notify} = useNotification();

  useLayoutEffect(() => {
    if (swr?.error?.[0]?.match(/not found/gi)) {
      history.replace(fallbackPath);
      notify({
        children: errorMessage || te('notFound'),
        className: 'alert-error',
      })
    }
  }, [swr?.error, errorMessage, history, notify, te, fallbackPath]);
}

export const useRemoveEntity = (fetcher, successRedirectPath) => {
  const [t, te] = useNestedTranslation(['useRemoveEntity', 'useRemoveEntity.error']);

  const {notify} = useNotification();
  const history = useHistory();

  const remove = fetcher ? () => {
    fetcher()
      .then(
        () => {
          notify({children: t('removed'), className: 'alert-success'});
          safe(isString, successRedirectPath).map(history.push);
        },
        caseMap(
          () => notify({children: te('generalRemovingError'), className: 'alert-error'}),
          [
            [isString, children => notify({children, className: 'alert-error'})],
            [isArray, es => es.map(children => notify({children, className: 'alert-error'}))],
            [isObject, e => Object.entries(e).map(([k, v]) => notify({
              children: `${k}: ${v}`,
              className: 'alert-error'
            }))],
          ]
        )
      )
  } : null;

  return {remove};
}

export const useSaveEntity = (fetcher, successRedirectPath) => {
  const [t, te] = useNestedTranslation(['useSaveEntity', 'useSaveEntity.error']);

  const {notify} = useNotification();
  const history = useHistory();

  const save = fetcher ? () => {
    fetcher()
      .then(
        () => {
          notify({children: t('saved'), className: 'alert-success'});
          safe(isString, successRedirectPath).map(history.push);
        },
        caseMap(
          () => notify({children: te('generalSavingError'), className: 'alert-error'}),
          [
            [isString, children => notify({children, className: 'alert-error'})],
            [isArray, es => es.map(children => notify({children, className: 'alert-error'}))],
            [isObject, e => Object.entries(e).map(([k, v]) => notify({
              children: `${k}: ${v}`,
              className: 'alert-error'
            }))],
          ]
        )
      )
  } : null;

  return {save};
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return windowSize;
}

export const useEventListener = (ref, eventType, callback) => {
  useEffect(() => {
    const node = ref?.current;
    node?.addEventListener(eventType, callback);

    return () => {
      node?.addEventListener(eventType, callback);
    };
  }, [ref, callback, eventType]);
};

export const useMutationObserver = (ref, callback) => {
  useEffect(() => {
    const observer = new MutationObserver(callback);

    observer.observe(
      ref?.current,
      {attributes: true, childList: true, subtree: true}
    );

    return () => {
      observer.disconnect();
    };
  }, [ref, callback]);
};

/**
 * Debounce a function.
 *
 * Be sure to wrap a callback in useCallback!
 *
 * @function
 * @param {function} callback Function to run after given timeout
 * @param {number}   timeout  Milliseconds to wait to run the callback
 * @returns {function}
 */
export const useDebounced = (callback, timeout) => {
  const r = useRef(null);

  return useCallback(() => {
    if (r.current) {
      clearTimeout(r.current);
      r.current = null;
    }

    r.current = setTimeout(callback, timeout);
  }, [callback, timeout]);
};

export const useSwitchState = (switches) => {
  const initial = useMemo(() => (
    safe(isObject, switches)
      .map(Object.keys)
      .chain(firstToMaybe(mconcat(First)))
      .chain(p => getProp(p, switches))
      .option(null)
  ), [switches]);

  return useReducer((o, s) => getProp(s, switches).option(switches?.[o] || initial), initial);
};


export const useQuery = () => {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
