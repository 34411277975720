import {API_ENDPOINT} from "../api";
import {LINK_PROPS_FN} from "./TableLayout";
import {LOCAL_STORAGE_KEYS, routes} from "../App";
import TableLayout from "./TableLayout";
import {TableColumnRenderer} from "../components/Table";
import {curry, compose, getProp, chain, safe, isArray, option, map, pick} from 'crocks';
import {useNestedTranslation} from "../hooks";
import {useHistory} from "react-router-dom";
import {createNewItemPath} from "../util/helper";

const DevicePacksLayout = () => {
  const [t, tt] = useNestedTranslation(['devicePacksLayout', 'devicePacksLayout.table']);
  const institutionSwr = API_ENDPOINT.GET_INSTITUTIONS.swr();
  const history = useHistory();

  return (
    <TableLayout {...{
      title: t('title') ,
      onCreateCallback: createNewItemPath(history, routes.devicePack.path),
      swr: API_ENDPOINT.GET_DEVICE_PACKS.swr(),
      localStorageSettingsKey: LOCAL_STORAGE_KEYS.DEVICE_PACK_TABLE_SETTINGS,
      linkPropFn: LINK_PROPS_FN.idProp(routes.devicePack.path),
      columnSettings: {
        id: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknownId'), 'id'),
          translated: tt('id'),
        },
        serialNo: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknownId'), 'serialNo'),
          translated: tt('serialNo'),
        },
        institution: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.mapIdPropToListItemProp(
            'institution',
            institutionSwr.data || [],
            'title',
            tt('unknownInstitution'),
            TableColumnRenderer.span
          ),
          translated: tt('institution'),
        },
        devices: {
          order: 0,
          on: true,
          translated: tt('devices'),
          renderer: compose(
            option(null),
            map(TableColumnRenderer.span),
            map(join(', ')),
            map(mapDevices),
            chain(safe(isArray)),
            getProp('devices'),
          )
        }
      }
    }}/>
  );
};

const objectValues = obj => Object.values(obj);
const join = curry((join, arr) => arr.join(join));
const mapDevices = list => list.map(
  compose(
    join(' - '),
    objectValues,
    pick(['deviceApi', 'serialNo'])
  )
);

export default DevicePacksLayout;
