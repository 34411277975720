import {
  chain,
  compose,
  curry,
  getPath,
  getPropOr,
  hasProp,
  hasProps,
  ifElse,
  isString,
  map,
  option,
  pick,
  safe,
  setProp,
} from 'crocks';
import {eachDayOfInterval, format, isBefore, isWithinInterval, parseISO} from 'date-fns';
import {MEASUREMENT_TYPE} from './api';
import {caseMap, pickLowestNumber, safeLowerCase, sortArrayByPred, prepareThrow, isNonEmptyArray, parseInt10, parseTime, parseTodayTime} from './util/helper';

const RRMS_TIMESTAMP_KEY = 'RRMS TIMESTAMP'
const RRMS_KEY = 'RRMS'

export const RISKS = {
  high: {
    rank: 4,
    color: "#F87171",
    name: "high",
  },
  med: {
    rank: 3,
    color: "#F59E0B",
    name: "med",
  },
  low: {
    rank: 2,
    color: "#FCD34D",
    name: "low",
  },
  hypo: {
    rank: 1,
    color: "#93C5FD",
    name: "hypo",
  },
  none: {
    rank: 0,
    color: "#34D399",
    name: "none",
  },
};

const compareTimes = ({time: a}, {time: b}) => isBefore(parseTodayTime(a), parseTodayTime(b));
const nextMeasurementPred = measurement => nextMeasurement => measurement.time !== nextMeasurement.time && nextMeasurement.type === measurement.type;
const timetableAddFromToProps = timetables => timetables.map((item) => ({
  ...item,
  from: item.time,
  to: caseMap(() => '23:59', [
    [hasProp('time'), ({time}) => time],
  ], timetables.find(nextMeasurementPred(item))),
}));


export const isSessionActive = s => (
  hasProps(['dateFrom', 'dateTo'], s)
  && isWithinInterval(new Date(), {
    start: parseISO(s?.dateFrom),
    end: parseISO(s?.dateTo)
  })
);

export const pickHighestRisk = curry((a, b) => {
  a = safeLowerCase(a);
  b = safeLowerCase(b);

  return getPropOr(
    RISKS.none,
    Object.keys(RISKS).find(k => {
      const key = safeLowerCase(k);
      return key === a || key === b;
    }) || 'not-found',
    RISKS
  );
});

export const nameToRisk = compose(
  option(RISKS.none),
  chain(key => getPath([key], RISKS)),
  map(s => String(s).toLowerCase()),
  safe(isString)
);

export const isTimetableMeasurement = ({type}) => type === MEASUREMENT_TYPE.MEASUREMENT;
export const isTimetableMedication = ({type}) => type === MEASUREMENT_TYPE.MEDICATION;

export const getSessionDayList = compose(
  option([]),
  map(eachDayOfInterval),
  map(([start, end]) => ({start, end})),
  map(map(parseISO)),
  map(Object.values),
  map(pick(['dateFrom', 'dateTo'])),
  safe(hasProps(['dateFrom', 'dateTo'])),
);

/**
 * Use this to create data for displaying blood pressure table
 *
 * @param {array} sessionTimetables Session.timetables from /api/v1/measurement/graph/patient/{ID}
 * @returns {Maybe<Array>} an updated sessionTimetables list with from & to props in a Maybe
 */
export const createTimetableRange = ({dateFrom, dateTo, sessionTimetables = [], measurements = [], medications = []}) => {
  // [] -> Maybe []
  const sortTimetable = compose(
    map(compose(
      map(pick(['id', 'name', 'time', 'type', 'from', 'to'])),
      timetableAddFromToProps,
    )),
    map(sortArrayByPred(compareTimes)),
    safe(isNonEmptyArray),
  );

  const isClosestTimetable = timetableId => ({timetable: {id}}) => id === timetableId;
  const isRecordMeasurement = hasProps(['date', 'values']);
  const isRecordMedication = hasProp('takenAt');
  const compareTypes = recordType => ({type}) => type === recordType;
  const getRecordType = caseMap(
    prepareThrow('unexpected record that is neither a measurement, nor a medication'),
    [
      [isRecordMeasurement, () => MEASUREMENT_TYPE.MEASUREMENT],
      [isRecordMedication, () => MEASUREMENT_TYPE.MEDICATION],
    ]
  );

  const pickClosestTimetableItem = curry((timetables, dateAccessFn, values) => values.map((item) => {
    const date = dateAccessFn(item);
    const timetablesWithDiff = timetables
      .filter(compareTypes(getRecordType(item)))
      .map(timetable => ({
        ...timetable,
        timeDiff: Math.abs(date - parseTime(date, timetable.time))
      }));
    const lowestDiff = pickLowestNumber(timetablesWithDiff.map(getPropOr(null, 'timeDiff')));
    return {
      ...item,
      timetable: timetablesWithDiff.find(t => t.timeDiff === lowestDiff)
    };
  }));

  const sorted = sortTimetable(sessionTimetables).option([]);
  const sortedMeasurements = pickClosestTimetableItem(sorted, ({date}) => parseISO(date), measurements);
  const sortedMedications = pickClosestTimetableItem(sorted, ({takenAt}) => parseISO(takenAt), medications);
  const sessionDays = getSessionDayList({dateFrom, dateTo});
  const pushToEachDayList = timetable => dateProp => item => ifElse(
    key => hasProp(key, timetable.valuesEachDay),
    key => {timetable.valuesEachDay[key].push(item); return item},
    key => {timetable.valuesEachDay[key] = [item]; return item},
    format(parseISO(item[dateProp]), 'yyyy-MM-dd')
  );

  return safe(
    isNonEmptyArray, 
    sorted
    .map(timetable => ({
      ...timetable,
      values: caseMap(
        prepareThrow('unknow measurement type detected while sorting them to timetable'),
        [
          [isTimetableMeasurement, () => sortedMeasurements.filter(isClosestTimetable(timetable.id))],
          [isTimetableMedication, () => sortedMedications.filter(isClosestTimetable(timetable.id))],
        ],
        timetable
      )
    }))
    .map(timetable => ({
      ...timetable,
      valuesEachDay: sessionDays.reduce((carry, item) => setProp(
        format(item, 'yyyy-MM-dd'),
        [],
        carry
      ), {}),
    }))
    .map(timetable => {
      timetable.values.forEach(caseMap(
        prepareThrow('Unknow timetable record detected'),
        [
          [isRecordMeasurement, pushToEachDayList(timetable)('date')],
          [isRecordMedication, pushToEachDayList(timetable)('takenAt')],
        ],
      ))
      return timetable;
    })
  )
  .option([]);
};

export const getMean = (arr, accessor = null) => arr.reduce(
  (carry, value) => accessor === null
  ? carry + value
  : carry + accessor(value),
  0
) / arr.length;

export const getSd = (arr, accessor = null) => {
  const mean = getMean(arr, accessor);
  return Math.sqrt(
    arr.map(x => accessor === null
      ? Math.pow(x - mean, 2)
      : Math.pow(accessor(x) - mean, 2)).reduce(
        (a, b) => accessor === null
        ? a + b : a + b,
        0
      ) / arr.length
  );
};

export const removeInterference = ({dataArray =  [], rrmsKey, lookAhead = 30}) => {
  const computablePart = dataArray.length > lookAhead ? dataArray.slice(0, dataArray.length - lookAhead - 1) : dataArray;
  const restPart = dataArray.length > lookAhead ? dataArray.slice(-lookAhead) : [];

  let mean = 0;
  let sd = 0;

  const computedPart = computablePart.map((value, index, array) => {
    const slice = array.slice(index, index + lookAhead);

    if (index === 0) {
      mean = getMean(slice, i => parseInt(i[rrmsKey]));
      sd = getSd(slice, i => parseInt(i[rrmsKey]), mean);
    }

    const rrms = parseInt(value[rrmsKey]);
    const tsd = 3 * sd;

    if (rrms <= mean - tsd || rrms >= mean + tsd) {
      value[rrmsKey] = typeof value[rrmsKey] === 'string' ? Math.round(mean).toString() : Math.round(mean);
    }

    mean = getMean(slice, i => parseInt(i[rrmsKey]));
    sd = getSd(slice, i => parseInt(i[rrmsKey]), mean);

    return value;
  })

  return [
    ...computedPart,
    ...restPart,
  ];
};

export const renderRmssdChart = polarData => {
  const groupIntoTimeIntervals = ({
    rrmsRecords,
    timestampKey,
    chunkSizeInMilliseconds,
    map = null
  }) => rrmsRecords.reduce((carry, item) => {
    const timestamp = parseInt(item[timestampKey])
    const carryTs = carry?.chunkTimestamp
    const hasExeeded = carryTs && (timestamp - carryTs) > chunkSizeInMilliseconds

    if (isNaN(timestamp)) {
      return carry;
    }

    if (!carryTs || hasExeeded) {
      carry.chunkTimestamp = timestamp;
    }

    if (!Array.isArray(carry.chunked[carry.chunkTimestamp])) {
      carry.chunked[carry.chunkTimestamp] = [];
    }

    carry.chunked[carry.chunkTimestamp].push(map ? map(item) : item);

    return carry;
  }, {chunked: {}}).chunked;

  const filterErrors = ({items, rrmsKey, maxDiff = 100}) => items.filter((value, index, all) => {
    if (index === 0) return true;

    const now = parseInt(value[rrmsKey]);
    const prev = parseInt(all[index - 1][rrmsKey]);
    const isBelowMax = Math.abs(now - prev) <= maxDiff;

    return isBelowMax;
  });

  const calculateRmssd = ({values, rrmsKey, timestampKey, map = null}) => values.reduce((carry, chunk) => {
    const chunkWithoutErrors = filterErrors({items: chunk, rrmsKey});

    const sumOfDiffSquares = chunkWithoutErrors.reduce((carry, item, index, all) => {
      if (index === 0) return carry;

      const now = parseInt(item[rrmsKey]);
      const prev = parseInt(all[index - 1][rrmsKey]);

      return carry + Math.pow(now - prev, 2);
    }, 0);

    const returnValue = {
      rmssd: Math.sqrt(sumOfDiffSquares / chunkWithoutErrors.length),
      timestamp: parseInt(chunkWithoutErrors[0][timestampKey]),
      values: chunkWithoutErrors,
    };

    carry.push(map ? map(returnValue) : returnValue);

    return carry;
  }, []);

  return calculateRmssd({
    values: Object.values(
      groupIntoTimeIntervals({
        rrmsRecords: polarData,
        timestampKey: RRMS_TIMESTAMP_KEY,
        chunkSizeInMilliseconds: 5 * 60 * 1000,
      })
    ),
    rrmsKey: RRMS_KEY,
    timestampKey: RRMS_TIMESTAMP_KEY,
    map: ({ rmssd, timestamp }) => ({
      y: rmssd,
      x: new Date(timestamp),
    }),
  })
};

export const renderHeartRateChart = map(data => ({
  y: 60000 / parseInt10(data[RRMS_KEY]),
  x: new Date(parseInt10(data[RRMS_TIMESTAMP_KEY])),
}));

export const renderPolarRrChart = map(data => ({
  x: new Date(Number(data[RRMS_TIMESTAMP_KEY])),
  y: parseInt10(data[RRMS_KEY]),
}));
