import React, {useCallback} from "react";
import {compose, isString, option, safe} from "crocks";
import {concatBySpace} from "../../util/helper";
import ChatListItem from "./ChatListItem";
import {useNestedTranslation} from "../../hooks";
import {Link, useParams} from "react-router-dom";
import {useChat} from "../../context/ChatContext";

const List = () => {
  const [t] = useNestedTranslation(['chatLayout']);
  const {conversationList} = useChat()
  const {receiver} = useParams()

  const getName = useCallback(name => compose(
    option(t('unknownPerson')),
    safe(isString),
  )(name), [t])


  return (
    <div className="min-w-16 overflow-x-hidden overflow-y-scroll md:w-4/12 xl:w-3/12"
         style={{borderRight: '1px solid #D4D6DA'}}>
      <div className="divide-y">
        {conversationList.length > 0 ? conversationList.map(p => (
            <Link
              key={p.conversation}
              to={`/chat/${p.otherName}`}
              // onClick={() => getConv(p)}
              className={concatBySpace(
                'transition-all duration-200 p-2 block border-l-[4px] cursor-pointer',
                p?.otherName === receiver ? 'border-l-primary' : 'border-l-transparent'
              )}
            >
              <ChatListItem
                name={getName(p?.otherName)}
                isSelected={p?.otherName === receiver}
              />
            </Link>
          )) :
          <p className="font-semibold">{t('noExistingConversations')}</p>
        }
      </div>
    </div>)
}

export default List
