import {useLayoutEffect, useMemo} from "react";
import {API_ENDPOINT, DEVICE_API} from "../api";
import {routes} from "../App";
import {Select} from "../components";
import {RouteToLink} from "../components/BreadCrumbs";
import {Input, LabeledFormControl} from "../components/Form";
import {mappedObjectsToOptions, objectsToOptions} from "../components/Select";
import {useIdParam, useMergeReducer, useNestedTranslation} from "../hooks";
import {mapInputEvent} from "../util/mapper";
import EditLayout from "./EditLayout";
import {compose, getPropOr, isNumber} from "crocks";

const DeviceLayout = () => {
  const [t] = useNestedTranslation(['deviceLayout']);
  const [state, setState] = useMergeReducer({});
  const id = useIdParam(routes.devices.path);
  const swr = API_ENDPOINT.GET_DEVICE.swr(id);
  const institutionsSwr = API_ENDPOINT.GET_INSTITUTIONS.swr();
  const devicePacksSwr = API_ENDPOINT.GET_DEVICE_PACKS.swr();
  const isNew = useMemo(() => id === 'new', [id]);
  const deviceApiSwr = API_ENDPOINT.GET_DEVICE_APIS.swr();

  useLayoutEffect(() => { setState({...swr?.data}) }, [setState, swr]);

  return (
    <EditLayout
      breadcrumbs={RouteToLink(routes.devices)}
      fallbackRedirectPath={routes.devices.path}
      removeFetcher={isNew ? undefined : () => API_ENDPOINT.DELETE_DEVICE.fetch(id)}
      saveFetcher={() => API_ENDPOINT[`${isNew ? 'POST' : 'PUT'}_DEVICE`].fetch({...state, id})}
      swr={swr}
      t={t}
    >
      <div className="lg:gap-4 lg:grid lg:grid-cols-2 space-y-4 lg:space-y-0">
        <LabeledFormControl label={t('serialNo')}>
          <Input value={state?.serialNo || ''} onChange={mapInputEvent(serialNo => setState({serialNo}))}/>
        </LabeledFormControl>
        <LabeledFormControl label={t('deviceApi.label')}>
          <Select
            value={isNumber(state?.deviceApi) ? state?.deviceApi : ''}
            onSelect={deviceApi => setState({deviceApi})}
            options={
              mappedObjectsToOptions([
                getPropOr('', 'id'),
                compose(
                  a => t(`deviceApi.${a}`),
                  getPropOr('', 'title'),
                ),
              ], (deviceApiSwr?.data || []).filter(a => Object.values(DEVICE_API).includes(a?.title)))
            }
          />
        </LabeledFormControl>
        <LabeledFormControl label={t('devicePack')}>
          <Select
            options={objectsToOptions(['id', 'serialNo'], devicePacksSwr?.data || [])}
            value={state?.devicePack || ''}
            onSelect={devicePack => setState({devicePack})}
          />
        </LabeledFormControl>
        <LabeledFormControl label={t('institution')}>
          <Select
            options={objectsToOptions(['id', 'title'], institutionsSwr?.data || [])}
            value={state?.institution || ''}
            onSelect={institution => setState({institution})}
          />
        </LabeledFormControl>
      </div>
    </EditLayout>
  );
};

export default DeviceLayout;

