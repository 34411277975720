import {useMemo, useState} from 'react'
import {
  add,
  eachDayOfInterval,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  format,
  formatISO,
  isFuture,
  isSameDay,
  isToday,
  isWithinInterval,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  sub,
} from 'date-fns'
import {lt} from 'date-fns/locale'
import {concatBySpace} from '../util/helper'

const Calendar = ({
  className,
  cellContainerProps,
  onSelect = () => {},
  ranged = false,
  lookBack = false,
  fromToday = false,
  selectedDates = [],
  availableDates = [],
  ...props
}) => {
  const dateFnsLocale = lt
  const currentDate = new Date()
  const [showMonthOf, onShowMonthOf] = useState(selectedDates.length ? selectedDates[selectedDates.length - 1] : currentDate)
  const [_selectedDates, onSelectedDates] = useState(selectedDates)
  const currentMonthDate = startOfMonth(currentDate)
  const prevMonthDate = startOfMonth(sub(startOfMonth(showMonthOf), {days: 1}))
  const nextMonthDate = startOfMonth(add(endOfMonth(showMonthOf), {days: 1}))
  const canGoBack = lookBack ? true : +currentMonthDate <= +prevMonthDate
  const canGoFwd = lookBack ? +currentMonthDate >= +nextMonthDate : true
  const monthName = dateFnsLocale.localize.month(showMonthOf.getMonth())
  const weekDayNames = [1, 2, 3, 4, 5, 6, 0].map((d) => {
    const day = dateFnsLocale.localize.day(d)
    return {full: day, short: day[0].toUpperCase()}
  })

  const goPrevMonth = () => (canGoBack ? onShowMonthOf(prevMonthDate) : null)
  const goNextMonth = () => (canGoFwd ? onShowMonthOf(nextMonthDate) : null)

  const _onSelect = (day) => {
    const newValue =
      ranged && _selectedDates.length < 2
        ? [..._selectedDates, day.date].sort((a, b) => a - b).map((d, i) => i === 0 ? startOfDay(d) : endOfDay(d))
        : [day.date]

    onSelectedDates(newValue)
    onSelect(newValue)
  }

  const availableIsoDates = useMemo(
    () =>
      availableDates.length
        ? [
            ...new Set(
              availableDates.map((d) => format(new Date(d), 'yyyy-MM-dd')),
            ),
          ]
        : [],
    [availableDates],
  )

  const days = [
    ...eachDayOfInterval({
      start: startOfISOWeek(startOfMonth(showMonthOf)),
      end: endOfISOWeek(endOfMonth(showMonthOf)),
    }).map((date) => {
      date = startOfDay(date)
      const isoDate = format(date, 'yyyy-MM-dd')
      const isAvailable = availableIsoDates.length
        ? availableIsoDates.includes(isoDate)
        : fromToday
        ? isFuture(add(date, {days: 1}))
        : lookBack
        ? !isFuture(date)
        : true
      const isNow = isToday(date)
      const isoFormatted = formatISO(date)
      const isRangeEdge =
        (_selectedDates.length && isSameDay(_selectedDates[0], date)) ||
        isSameDay(_selectedDates[_selectedDates.length - 1], date)
      const isSelected =
        _selectedDates.length &&
        isWithinInterval(date, {
          start: startOfDay(_selectedDates[0]),
          end: endOfDay(_selectedDates[_selectedDates.length - 1]),
        })

      return {
        date,
        isoFormatted,
        formatted: format(date, 'dd'),
        isSelected,
        isAvailable,
        style: [
          isAvailable ? null : 'opacity-20',
          isNow ? 'bg-gray-200' : null,
          isSelected ? 'bg-gray-500' : null,
          isRangeEdge ? null : null,
        ].filter(str => str).join(' '),
        textStyle: [
          isAvailable ? null : null,
          isRangeEdge ? null : null,
          isSelected ? 'text-white' : null,
        ].filter(str => str).join(' '),
      }
    }),
  ]

  return (
    <div className={concatBySpace('rounded-md p-2 mt-3 bg-white border border-gray-200 shadow-xl', className)} {...props}>
      <div className='flex items-center justify-between'>
        <button className={concatBySpace('focus:outline-none flex py-6 px-6', canGoBack ? null : 'opacity-20')} onClick={goPrevMonth}>&larr;</button>
        <div className='flex items-center justify-center min-w-36'>
          <p className='block text-base text-gray-800 capitalize'>{monthName}</p>
          <p className='block ml-2 text-base text-gray-800 capitalize'>{showMonthOf.getUTCFullYear()}</p>
        </div>
        <button className={concatBySpace('focus:outline-none flex py-6 px-6', canGoFwd ? null : 'opacity-20')} onClick={goNextMonth}>&rarr;</button>
      </div>
      <div className='grid grid-cols-7 gap-2' id='calendar-cell-container' {...cellContainerProps}>
        {weekDayNames.map(d => <p key={d.full} className='inline-block px-3 py-2 text-center'>{d.short}</p>)}
        {days.map((d) => (
          <button
            onClick={() => _onSelect(d)}
            disabled={!d.isAvailable}
            key={d.isoFormatted}
            className={concatBySpace('focus:outline-none rounded-md border border-gray-200 py-1', d.style)}>
            <p className={concatBySpace('', d.textStyle)}>{d.formatted}</p>
          </button>
        ))}
      </div>
    </div>
  )
}

export const filterByDateRange = (dateRange, date) => {
  if (dateRange.length < 2) {
    return true
  }

  return isWithinInterval(new Date(date), {
    start: startOfDay(dateRange[0]),
    end: endOfDay(dateRange[1]),
  })
}

export default Calendar
