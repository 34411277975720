import {useCallback, useEffect, useLayoutEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useMergeReducer, useNestedTranslation} from "../hooks";
import {caseMap, getQueryParameters, hasLength} from "../util/helper";
import {Input, LabeledFormControl} from "../components/Form";
import {routes} from "../App";
import {mapInputEvent, runWithEventWhich} from "../util/mapper";
import {propSatisfies} from "crocks/predicates";
import {
  and,
  not,
  ifElse,
  isSame,
  getProp,
} from 'crocks';
import {API_ENDPOINT} from "../api";
import {useNotification} from "../components/Notification";

const PasswordChangeForm = () => {
  const history = useHistory();
  const [t, tl] = useNestedTranslation(['passwordChangeForm', 'label']);
  const location = useLocation();
  const {notify} = useNotification();

  const [state, setState] = useMergeReducer({
    pass: '',
    repeat: '',
    slug: '',
    error: '',
    allowPost: null,
  });

  const renew = useCallback(() => {
    if (state.allowPost !== true) { return; }
    API_ENDPOINT.POST_PASSWORD_CHANGE.fetchCommit({
      password: state.pass,
      slug: state.slug
    }).then(
      () => {
        notify({className: 'alert-success', children: t('success')});
        history.replace(routes.login.path);
      },
      (error) => setState({error: t('serverError')})
    );
  }, [state, history, notify, setState, t]);

  useLayoutEffect(() => {
    ifElse(
      hasLength,
      slug => setState({slug}),
      () => history.replace(routes.login.path),
      getQueryParameters(location).chain(getProp('slug')).option(null)
    );
  }, [history, setState, location]);

  useEffect(() => {
    caseMap(
      () => setState({error: null, allowPost: true}),
      [
        [and(hasLength, not(isSame(state.pass))), () => setState({error: t('passwordsDontMatch'), allowPost: false})],
        [and(hasLength, propSatisfies('length', l => l < 6)), () => setState({error: t('passwordTooShort'), allowPost: false})],
        [() => state.error || !hasLength(state.pass) || !hasLength(state.repeat), () => setState({error: null, allowPost: false})],
      ],
      state.repeat
    );
  }, [state, setState, t]);

  return (
    <>
      <LabeledFormControl label={tl('newPassword')}>
        <Input id="input-password" type="password" value={state.pass} onChange={mapInputEvent(pass => setState({pass}))} />
      </LabeledFormControl>

      <LabeledFormControl label={tl('repeatPassword')}>
        <Input id="input-repeat-password" type="password" onKeyUp={runWithEventWhich(13, renew)} value={state.repeat} onChange={mapInputEvent(repeat => setState({repeat}))} />
      </LabeledFormControl>

      <button className="btn" onClick={renew} disabled={state.allowPost !== true}>{t('renew')}</button>

      {state.error ? (
        <div className="rounded-lg p-2 text-center badge-error w-full overflow-hidden">
          {state.error}
        </div>
      ) : null}
    </>
  );
};

export default PasswordChangeForm;
