import {useHistory} from "react-router-dom";
import {API_ENDPOINT} from "../api";
import {LOCAL_STORAGE_KEYS, routes} from "../App";
import {TableColumnRenderer} from "../components/Table";
import {useNestedTranslation} from "../hooks";
import {createNewItemPath} from "../util/helper";
import TableLayout, {LINK_PROPS_FN} from "./TableLayout";

const InstitutionLayout = () => {
  const [t, tt] = useNestedTranslation(['institutionsLayout', 'institutionsLayout.table']);
  const swr = API_ENDPOINT.GET_INSTITUTIONS.swr();
  const history = useHistory();

  return (
    <TableLayout {...{
      title: t('title'),
      localStorageSettingsKey: LOCAL_STORAGE_KEYS.INSTITUTION_TABLE_SETTINGS,
      onCreateCallback: createNewItemPath(history, routes.institution.path),
      linkPropFn: LINK_PROPS_FN.idProp(routes.institution.path),
      swr,
      columnSettings: {
        id: {
          order: 0,
          on: true,
          translated: tt('id'),
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'id')
        },
        title: {
          order: 0,
          on: true,
          translated: tt('title'),
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'title')
        },
        devices: {
          order: 0,
          on: true,
          translated: tt('devices'),
          renderer: TableColumnRenderer.pathSpan('0', ['devices', 'length']),
        },
        doctors: {
          order: 0,
          on: true,
          translated: tt('doctors'),
          renderer: TableColumnRenderer.pathSpan('0', ['doctors', 'length']),
        },
      }
    }}/>
  );
};

export default InstitutionLayout;
