import Candle from '../components/Candle';
import EditLayout from './EditLayout';
import IconToggle from '../components/IconToggle';
import OptimizedChart from '../components/OptimizedChart';
import SwrWrapper from '../components/SwrWrapper';
import Tabs from './Tabs';
import env from '../env';
import {API_ENDPOINT, DEVICE_API, mapRelationToIdObj, SEX} from '../api';
import {AppendVerticalButton, DeleteButton} from '../components/Button';
import {CogIcon, InformationCircleIcon} from '@heroicons/react/solid';
import {Hr, Select, Title} from '../components';
import {Input, InputPostAddon, LabeledFormControl} from '../components/Form';
import {ReactComponent as IconFemale} from "../assets/svg/sex-female.svg";
import {ReactComponent as IconMale} from "../assets/svg/sex-male.svg";
import {RouteToLink} from '../components/BreadCrumbs';
import {TransitionVariant} from '../components/Transition';
import {Transition} from '@headlessui/react';
import {VictoryArea, VictoryAxis, VictoryBar, VictoryCandlestick, VictoryChart, VictoryLine, VictoryTooltip, VictoryZoomContainer} from 'victory';
import {addMinutes, differenceInMinutes, format, isBefore, isSameDay, isValid, parseISO, startOfDay} from 'date-fns';
import {caseMap, componentToString, concatBySpace, formatIsoDateInput, hasLength, isNonEmptyArray, isNumeric, mapListOrNull, findIndexByProp, mreduceMapOriginal, parseInt10, pickHighestNumber, pickLowestNumber, prepareThrow, safeOrNull, sortArrayById, sortArrayByPred} from '../util/helper';
import {createTimetableRange, getSessionDayList, isTimetableMeasurement, isTimetableMedication, nameToRisk, pickHighestRisk, removeInterference, renderHeartRateChart, renderPolarRrChart, renderRmssdChart} from '../business';
import {forceArray, mapInputEvent, mapInputEventOr} from '../util/mapper';
import {mappedObjectsToOptions, objectsToOptions} from '../components/Select';
import {routes} from '../App';
import {useCallback, useLayoutEffect, useEffect, useMemo, useState, useRef, memo} from 'react';
import {useIdParam, useMergeReducer, useNestedTranslation, useWindowSize} from '../hooks';
import {useNotification} from '../components/Notification';
import {useSessionForm} from './SessionLayout';
import {
  Max,
  Sum,
  bimap,
  branch,
  compose,
  curry,
  getPath,
  getPathOr,
  getProp,
  getPropOr,
  hasProps,
  identity,
  ifElse,
  isArray,
  isDefined,
  isNumber,
  isSame,
  isString,
  map,
  merge,
  mreduceMap,
  unsetProp,
  option,
  pick,
  reduce,
  safe,
  setProp,
  tap,
} from 'crocks';
import {getMercureEvent} from '../util';

const matchNotFoundError = swr => swr.error?.[0]?.match(/not.*found/gi);
const hasResponseSessions = swr => swr.data?.sessions?.length;
const sortSessionsByDateDesc = swr => ({data: {sessions: sortArrayById(swr?.data?.sessions)}});
const getSessionName = compose(
  arr => arr.join(' - '),
  Object.values,
  pick(['dateFrom', 'dateTo'])
);

const measurementDate = getPathOr('', ['date']);
const measurementSysValue = getPathOr(0, ['values', 'systolicBloodPressure', 'value']);
const measurementDiaValue = getPathOr(0, ['values', 'diastolicBloodPressure', 'value']);
const measurementPulseValue = getPathOr(0, ['values', 'pulse', 'value']);
const measurementSysRisk = getPathOr(0, ['values', 'systolicBloodPressure', 'riskName']);
const measurementDiaRisk = getPathOr(0, ['values', 'diastolicBloodPressure', 'riskName']);
const pickBarColor = ({datum: {sysRisk, diaRisk}}) => pickHighestRisk(sysRisk, diaRisk).color;
const FONT_SIZE = 9;
const defSleepCellClass = '!border-l !border-r !border-t-0 !border-b-0 !border-gray-300 !border-opacity-100 !text-center';
const defCellClass = 'font-semibold text-center';
const defTHeadClass = '!z-10 !border-0 !bg-base-100';
const defTBodyClass = 'border-t border-b border-gray-300 border-opacity-100 table-row-group';
const padZero = num => num < 10 ? `0${num}` : `${num}`;
const scrollToHorizontalEnd = element => safe(hasProps(['scrollTo', 'scrollWidth']), element)
  .map(tap(e => e.scrollTo(e.scrollWidth, e.scrolTop)))

// weight = Y axis - the higher the number, the higher it will appear in the chart
const mapSleepLevels = typeStages => compose(
  merge((original, chartData) => ({...original, typeStages, chartData})),
  map(compose( // insert a copy every 2-nth index to create horizontal chart.
    merge((l, r) => {
      const merged = [];

      for (let i = 0; i < l.length; ++i) {
        merged.push({...l[i]});

        if (i + 1 < l.length) {
          merged.push({
            ...r[i],
            x: r[i+1].x
          });
        }
      }

      return merged;
    }),
    branch,
  )),
  map(option([])),
  map(map(map(({dateTime, level, seconds}) => {
    const sleepStage = typeStages.find(({stage}) => String(stage).toLowerCase() === String(level).toLowerCase());
    return {
      level,
      seconds,
      x: parseISO(dateTime),
      y: getPropOr(-1, 'weight', sleepStage)
    };
  }))),
  map(getPath(['levels', 'data'])),
  branch,
);

const classicSleepToChart = mapSleepLevels([
  {
    weight: 3,
    stage: 'awake',
  },
  {
    weight: 2,
    stage: 'restless',
  },
  {
    weight: 1,
    stage: 'asleep',
  },
]);

const stagesSleepToChat = mapSleepLevels([
  {
    weight: 4,
    stage: 'wake',
  },
  {
    weight: 3,
    stage: 'rem',
  },
  {
    weight: 2,
    stage: 'light',
  },
  {
    weight: 1,
    stage: 'deep',
  },
]);

/**
 * @param {number} num Milliseconds of duration
 * @returns {string}
 */
const millisToHumanDuration = num => {
  const mins = (num * 0.001) / 60;
  const hour = Math.floor(mins / 60);
  const min = Math.round(mins - (hour * 60));
  return `${padZero(hour)}:${padZero(min)}`;
};

/**
 * Non destructive way to get human readable duration of a sleep record
 *
 * @param {{duration: number}} sleep Fitbit sleep object that has duration prop with int value
 * @returns {{humanDuration: string}}
 */
const setPropHumanDuration = compose(
  merge((sleep, str) => setProp('humanDuration', str, sleep)),
  map(option(null)),
  map(map(millisToHumanDuration)),
  map(getProp('duration')),
  branch,
);

const DATA_MODES = {
  dailyMinMax: 0,
  full: 1,
  closeAvg: 2,
};

const axisStyle = {
  tickLabels: {fontSize: FONT_SIZE},
  grid: {
    strokeWidth: 1,
    stroke: '#000000',
    strokeOpacity: 0.3,
  }
};

const dependentAxisStyle = {
  ...axisStyle,
  grid: {
  ...axisStyle.grid,
  strokeOpacity: 0.1,
  }
};

const EmptyCell = (props) => (<td className={defCellClass} {...props}>-</td>);

const PatientSleepTab = memo(({state}) => {
  const tableWrapperRef = useRef();
  const windowSize = useWindowSize();
  const [t] = useNestedTranslation('patientLayout.sleepTab');
  const swr = (caseMap(
    swr => ({
      ...swr,
      data: {
        sleep: sortArrayByPred(
          ({dateOfSleep: a}, {dateOfSleep: b}) => isBefore(parseISO(a), parseISO(b)),
          swr?.data?.sleep
        ),
      }
    }),
    [
      [matchNotFoundError, (data) => ({...data, error: t('error.noMeasurements')})]
    ]
  ))(
    API_ENDPOINT.GET_MEASUREMENTS_SLEEP.swr(state?.id)

    /**
     * Fake Dev
     *
     *({ error: undefined, data: require('../fakes/fake_patient_sleep_1.json') })
     */
  );

  const sleepByDate = useMemo(() => {
    const groupSleepsByDate = reduce(
      (carry, item) => setProp(
        item.dateOfSleep,
        [
          ...safe(isArray, carry?.[item.dateOfSleep]).option([]),
          item,
        ],
        carry
      ),
      {}
    );

    const getHumanMeanDuration = compose(
      map(
        compose(
          entry => setPropHumanDuration(entry[1]),
          entry => setProp(
            1,
            {
              dateOfSleep: entry[1][0].dateOfSleep,
              duration: mreduceMap(Sum, getPropOr(0, 'duration'), entry[1]),
              sleeps: entry[1],
            },
            entry
          )
        )
      ),
      Object.entries
    );

    return getProp('sleep', swr?.data)
    .map(groupSleepsByDate)
    .map(getHumanMeanDuration)
    .option([]);
  }, [swr?.data]);

  const meanSleepDurationByWeek = useMemo(() => {
    const chunkInto = curry((parts, list) => reduce((carry, item, index, all) => {
      const lastIndex = carry?.length - 1;
      if (carry?.[lastIndex]?.length < parts) {
        carry[lastIndex].push(item);
      } else {
        carry.push([item]);
      }
      return carry;
    }, [], list));

    return safe(isNonEmptyArray, sleepByDate)
    .map(chunkInto(7))
    .map(
      map(
        compose(
          setPropHumanDuration,
          days => ({
            days,
            duration: (
              mreduceMap(
                Sum,
                getPropOr(0, 'duration'),
                days
              ) / days.length
            ),
          }),
        )
      )
    )
    .option([]);
  }, [sleepByDate]);

  const chartData = useMemo(() => map(({dateOfSleep: x, duration: y}) => ({x, y}), sleepByDate), [sleepByDate]);

  const [selectedDatum, setSelectedDatum] = useState(null);

  useEffect(() => {
    scrollToHorizontalEnd(tableWrapperRef.current)
  }, [tableWrapperRef?.current?.scrollWidth]);

  const selectedDatumSleepLevels = useMemo(() => (
    getProp('index', selectedDatum)
      .map(getPropOr(null))
      .ap(safe(isNonEmptyArray, sleepByDate))
      .chain(getProp('sleeps'))
      .map(map(compose(
        caseMap(
          () => 'unknown sleep type',
          [
            [({type}) => type === 'classic', classicSleepToChart],
            [({type}) => type === 'stages', stagesSleepToChat],
          ]
        ),
      )))
      .option([])
  ), [selectedDatum, sleepByDate]);

  return (
    <SwrWrapper {...swr}>
      <VictoryChart
        width={windowSize.width * 0.65}
        height={windowSize.height * 0.2}
        padding={{top:5, right:5, bottom: 50, left: 50}}
        domainPadding={50}
      >
        <VictoryBar
          style={{
            data: {
              fill: '#1f2937',
            },
          }}
          data={chartData}
          labels={(a) => {
            const efficiencyMean = (
              getProp('index', a)
              .map(getPropOr(null))
              .ap(safe(isNonEmptyArray, sleepByDate))
              .chain(getProp('sleeps'))
              .map(es => mreduceMap(Sum, getPropOr(0, 'efficiency'), es) / getPropOr(0, 'length', es))
              .option(0)
            );
            return `${t('efficiencyMean')}: ${efficiencyMean}`;
          }}
          labelComponent={
            <VictoryTooltip
              pointerLength={0}
              cornerRadius={0}
              flyoutPadding={15}
              style={{
                fontSize: FONT_SIZE,
              }}
              flyoutStyle={{
                fill: '#ffffff',
                stroke: '#000000',
                strokeWidth: 1,
                strokeOpacity: 0.2,
              }}
            />}
          events={[{
            target: 'data',
            eventHandlers: {
              onMouseOver: (_, data) => ({
                target: 'labels', mutation: () => { setSelectedDatum(data); return {active: true} }
              }),
            }
          }]}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={millisToHumanDuration}
          style={{
            tickLabels: {fontSize: FONT_SIZE},
              grid: {
                strokeWidth: 1,
                  stroke: '#000000',
                  strokeOpacity: 0.2,
              }
          }}
        />
        <VictoryAxis fixLabelOverlap/>
      </VictoryChart>
      <div ref={tableWrapperRef} className="overflow-x-auto overflow-y-visible">
        <table className="table table-compact overflow-y-visible w-full">
          <thead>
            <tr>
              <th className={concatBySpace('!bg-gray-300 !bg-opacity-100 !rounded-none', defSleepCellClass)}></th>
              {mapListOrNull(({dateOfSleep: a}) => <th className={concatBySpace('!bg-gray-300 !bg-opacity-100 !rounded-none', defSleepCellClass)} key={a}>{a}</th>, sleepByDate)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{t('duration')}</th>
              {
                mapListOrNull(
                  safeOrNull(
                    hasProps(['dateOfSleep', 'duration']),
                    compose(
                      ({dateOfSleep, humanDuration}) => (<td  className={defSleepCellClass} key={dateOfSleep}>{humanDuration}</td>),
                      setPropHumanDuration,
                    )
                  ),
                  sleepByDate
                )
              }
            </tr>
            <tr>
              <th>{t('time')}</th>
              {
                mapListOrNull(
                  compose(
                    a => <td className={concatBySpace('divide-x divide-base-content', defSleepCellClass)} key={componentToString(a)}>{a}</td>,
                    ifElse(
                      as => as.length > 1,
                      map(a => <span className="even:pl-2 odd:pr-2" key={a}>{a}</span>),
                      identity
                    ),
                    map(
                      compose(// extract from - to as a human readable format
                        merge((date, minutes) => `${format(date, 'HH:mm')} - ${format(addMinutes(date, minutes), 'HH:mm')}`),
                        bimap(parseISO, identity),
                        bimap(getPropOr(null, 'startTime'), getPropOr(0, 'timeInBed')),
                        branch,
                      ),
                    ),
                    getPropOr([], 'sleeps')
                  ),
                  sleepByDate
                )
              }
            </tr>
            <tr>
              <th className="min-w-16">{t('efficiency')}</th>
              {
                mapListOrNull((sleep) => (
                  <td className={concatBySpace('divide-x divide-base-content', defSleepCellClass)} key={sleep.dateOfSleep}>
                  {
                    sleep?.sleeps?.map(
                      ({efficiency, logId}) => (
                        <span className={sleep.sleeps.length ? 'even:pl-2 odd:pr-2' : ''} key={logId}>
                          {efficiency}
                        </span>
                      )
                    )
                  }
                  </td>
                ), sleepByDate)
              }
            </tr>
            <tr>
              <th className="min-w-16">{t('mean')}</th>
              {
                mapListOrNull((week) => (
                  <td
                    className={concatBySpace('left-16 text-center', defSleepCellClass)}
                    key={week.days.map(d => d.dateOfSleep).join(',')}
                    colSpan={week.days.length}>
                    {week.humanDuration}
                  </td>
                ), meanSleepDurationByWeek)
              }
            </tr>
          </tbody>
        </table>
      </div>
      <div className={`mt-4 grid xl:gap-4 grid-cols-1 ${selectedDatumSleepLevels?.length < 2 ? '' : 'xl:grid-cols-2'}`}>
      {
        mapListOrNull(
          (sleep) => (
            <div>
              <VictoryChart
                key={sleep.logId} domainPadding={{x: 10, y: 10}}
                padding={{top: 10, right: 10, bottom: 50, left: 50}}
                width={selectedDatumSleepLevels?.length < 2 ? windowSize.width * 0.5 : windowSize.width * (windowSize.width < 1024 ? 0.5 : 0.25)}
                height={windowSize.height * 0.15}>
                <VictoryLine style={{data: {stroke: '#1f2937'}}} data={sleep.chartData}  domain={{}}/>
                <VictoryAxis style={axisStyle} tickFormat={date => format(date, 'HH:mm')}/>
                <VictoryAxis style={axisStyle} dependentAxis tickFormat={(a) => {
                  const stage = sleep?.typeStages?.find(stage => stage.weight === a)?.stage;
                  return stage ? t(`sleepStage.${stage}`) : '';
                }} />
              </VictoryChart>
            </div>
          ),
          selectedDatumSleepLevels
        )
      }
      </div>
    </SwrWrapper>
  );
}, (prev, next) => prev?.state?.id === next?.state?.id);

const PatientPolarTab = memo(({state}) => {
  const [t] = useNestedTranslation('patientLayout.polarTab');
  const swr = (caseMap(identity, [
    [matchNotFoundError, (data) => ({...data, error: t('error.noMeasurements')})],
  ]))(
    API_ENDPOINT.GET_MEASUREMENTS_RR.swr(state?.id),

    /**
     * Fake Dev
     ({ error: undefined, data: require('../fakes/fake_patient_rr_1.json') })
     */
  )

  const processedData = useMemo(() => removeInterference({
    dataArray: swr?.data,
    rrmsKey: 'RRMS',
    lookAhead: 30,
  }), [swr?.data]);

  const rrData = useMemo(() => renderPolarRrChart(processedData), [processedData]);
  const heartRateData = useMemo(() => renderHeartRateChart(processedData), [processedData]);
  const rmssdData = useMemo(() => renderRmssdChart(processedData), [processedData]);

  return (
    <SwrWrapper {...swr} className="w-11/12 ml-auto">
      <Title>RR/ms</Title>
      <OptimizedChart
        Chart={VictoryLine}
        data={rrData}
        zoomDimension="y"
        maxPoints={500}
        padding={{top: 5, right: 0, bottom: 30, left: 50}}
      />
      <Hr />
      <Title>{t('heartRate')}</Title>
      <OptimizedChart
        Chart={VictoryLine}
        data={heartRateData}
        maxPoints={500}
        zoomDimension="y"
        padding={{top: 5, right: 0, bottom: 30, left: 50}}
      />
      <Hr />
      <Title>RMSSD</Title>
      <OptimizedChart
        Chart={RmssdChart}
        data={rmssdData}
        maxPoints={500}
        zoomDimension="y"
        padding={{top: 5, right: 0, bottom: 30, left: 50}}
        minDomainY={19}
        maxDomainY={75}
      />
      <label htmlFor="rmssd-info-modal" className="btn btn-primary modal-button mt-4">
          <InformationCircleIcon className="w-6 h-6 mr-2" />
          {t('aboutRmmsd')}
      </label>
      <input type="checkbox" id="rmssd-info-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <div className="prose max-h-[70vh] overflow-y-auto"> 
            <p>
              Širdies ritmo variabilumas - tai specializuotas neinvazinis širdies ritmo rodiklis, kuris suteikia
              informacijos apie širdies bei autonominės nervų sistemos (kitaip dar žinomos kaip vegetacinės nervų
              sistemos) veiklą.
            </p>
            <p>
              Šio tyrimo metu širdies funkcija leidžia įvertinti autonominę nervų sistemą, kuri kontroliuoja daugelį
              organizme vykstančių procesų: ji yra pirminis mechanizmas, kontroliuojantis „kovos arba pabėgimo“
              (angl. fight or flight) ir „ilsėkis ir virškink“ (angl. rest and digest) režimus. Taigi, vegetacinė nervų
              sistema padeda organizmui išgyventi įvairiose situacijose ir ramybės, ir ekstremalios grėsmės metu,
              todėl labai svarbu, kad ji galėtų dirbti teisingai ir harmoningai.
            </p>
            <p>
              Autonominė nervų sistema smegenyse yra kontroliuojama pagumburio ir sudaryta iš dviejų dalių:
              simpatinės ir parasimpatinės nervų sistemų. Daugeliu atveju šios dvi dalys yra atsakingos už gana
              priešingus veiksmus, nes vienai aktyvavus tam tikrą fiziologinį atsaką, kita jį slopina. Iš dalies galima
              teigti, kad simpatinė nervų sistema yra greitą atsaką generuojanti, o parasimpatinė – slopinančiai
              veikianti sistema.
            </p>
            <p>
              RMSSD rodiklis atspindi širdies ritmo svyravimus tarp širdies susitraukimų ir yra pagrindinis laiko
              sekų analizės rodiklis, kuris naudojamas vertinant parasimpatinės sistemos sąlygojamus širdies
              ritmo variabilumo pokyčius [1]. Mažesnės RMSSD vertės yra siejamos su didesne staigios mirties
              rizika esant įvairioms būklėms (pvz., epilepsija) [2].
            </p>
            <p>RMSSD rodiklio normalios vertės pagrįstos 15 tyrimų apibendrintais rezultatais [3].</p>
            <p>
              1. Shaffer F, McCraty R, Zerr CL. A healthy heart is not a metronome: an integrative review of the
              heart's anatomy and heart rate variability. Front Psychol. 2014 Sep 30;5:1040. doi:
              10.3389/fpsyg.2014.01040. PMID: 25324790; PMCID: PMC4179748.
            </p>
            <p>
              2. DeGiorgio CM, Miller P, Meymandi S, Chin A, Epps J, Gordon S, Gornbein J, Harper RM. RMSSD, a
              measure of vagus-mediated heart rate variability, is associated with risk factors for SUDEP: the
              SUDEP-7 Inventory. Epilepsy Behav. 2010 Sep;19(1):78-81. doi: 10.1016/j.yebeh.2010.06.011. Epub
              2010 Jul 27. PMID: 20667792; PMCID: PMC2943000.
            </p>
            <p>
              3. Nunan D, Sandercock GR, Brodie DA. A quantitative systematic review of normal values for shortterm heart rate variability in healthy adults.
              Pacing Clin Electrophysiol. 2010 Nov;33(11):1407-17.
              doi: 10.1111/j.1540-8159.2010.02841.x. PMID: 20663071.
            </p>
          </div>
          <div className="modal-action">
            <label htmlFor="rmssd-info-modal" className="btn">{t('closeRmssdModal')}</label>
          </div>
        </div>
      </div>
    </SwrWrapper>
  );
}, (prev, next) => prev?.state?.id === next?.state?.id);

const RmssdChart = ({minYGreen = 19, maxYGreen = 75, ...props}) => {
  const datum = {
    y: maxYGreen,
    y0: minYGreen,
  };

  return (
    <g>
      <VictoryArea {...{
        ...props,
        data: [
          setProp('x', props?.data?.[0]?.x, datum),
          setProp('x', props?.data?.[props?.data?.length - 1]?.x, datum),
        ],
        style: {data: {strokeWidth: 0, fill: 'green', fillOpacity: 0.1}},
      }} />
      <VictoryLine {...props} />
    </g>
  );
};

const PatientBloodPressureTab = memo(({state}) => {
  const [closeAvgMinutes, setCloseAvgMinutes] = useState(10);
  const tableWrapperRef = useRef();
  const [dataMode, setDataMode] = useState(Object.values(DATA_MODES)[0]);
  const [t] = useNestedTranslation('patientLayout.bloodPressureTab');
  const bloodPressure = (caseMap(identity, [
    [matchNotFoundError, (data) => ({...data, error: t('error.noMeasurements')})],
    [hasResponseSessions, sortSessionsByDateDesc],
  ]))(
    API_ENDPOINT.GET_MEASUREMENTS_BLOOD_PRESSURE.swr(state?.id)

    /**
     * Fake Dev (select one)
     ({ error: undefined, data: require(
       '../fakes/fake_session_2.json',
       '../fakes/fake_session.json'
       '../fakes/fake_measurements_graph_patient_1.json'
     )})
     */
  );

  useEffect(() => {
    scrollToHorizontalEnd(tableWrapperRef.current)
  }, [tableWrapperRef?.current?.scrollWidth]);

  const [sessionId, setSessionId] = useState('');
  const session = useMemo(() => bloodPressure?.data?.sessions?.find(s => s.id === sessionId), [sessionId, bloodPressure]);

  useLayoutEffect(() => {
    if (sessionId) return;
    setSessionId(bloodPressure?.data?.sessions?.[0]?.id);
  }, [setSessionId, bloodPressure, sessionId]);

  const chartData = useMemo(() => {
    if (!session?.measurements?.length) {
      return {
        data: [],
        tickValues: [],
        timetableRange: [],
      }
    }

    return {
      timetableRange: createTimetableRange(session),
      tickValues: getSessionDayList(session),
      data: session?.measurements?.map(m => {
        const sys = measurementSysValue(m);
        const dia = measurementDiaValue(m);
        const sysRisk = measurementSysRisk(m);
        const diaRisk = measurementDiaRisk(m);
        const risk = pickHighestRisk(sysRisk, diaRisk);

        return ({
          ...m,
          dia,
          diaRisk,
          sys,
          sysRisk,
          risk,
          x: parseISO(m.date),
          y: [dia, dia, sys, sys],
        })
      }),
    }
  }, [session]);

  const bigChartDataFull = useCallback(() => {
    if (!chartData?.data?.length) {
      return [];
    }

    return chartData.data.map((item) => {
      const diaLow = item.dia;
      const diaHigh = item.dia;
      const sysLow = item.sys;
      const sysHigh = item.sys;

      return {
        ...item,
        count: 1,
        low: diaLow,
        open: diaHigh,
        close: sysLow,
        high: sysHigh,
        diaLow,
        diaHigh,
        sysLow,
        sysHigh,
        y: [diaLow, diaHigh, sysLow, sysHigh],
        risk: item.risk,
      };
    });
  }, [chartData.data]);

  const bigChartDataDailyMinMax = useCallback(() => {
    if (!chartData?.data?.length) {
      return [];
    }

    return chartData.data.reduce((carry, item, index, list) => {
      if (index === 0) {
        const diaLow = item.dia;
        const diaHigh = item.dia;
        const sysLow = item.sys;
        const sysHigh = item.sys;

        carry.temp = {
          ...item,
          count: 1,
          low: diaLow,
          open: diaHigh,
          close: sysLow,
          high: sysHigh,
          diaLow,
          diaHigh,
          sysLow,
          sysHigh,
          y: [diaLow, diaHigh, sysLow, sysHigh],
          risk: item.risk,
        }

        return carry;
      }

      const last = list[index - 1];

      if (isSameDay(item.x, last.x)) {
        const diaLow = pickLowestNumber([item.dia, carry.temp.y[0]]);
        const diaHigh = pickHighestNumber([item.dia, carry.temp.y[1]]);
        const sysLow = pickLowestNumber([item.sys, carry.temp.y[2]]);
        const sysHigh = pickHighestNumber([item.sys, carry.temp.y[3]]);

        carry.temp = {
          ...carry.temp,
          count: carry.temp.count + 1,
          x: startOfDay(carry.temp.x),
          low: diaLow,
          open: diaHigh,
          close: sysLow,
          high: sysHigh,
          diaLow,
          diaHigh,
          sysLow,
          sysHigh,
          y: [diaLow, diaHigh, sysLow, sysHigh],
          risk: carry.temp.risk.rank > item.risk.rank ? carry.temp.risk : item.risk,
        }
      } else {
        carry.final.push({...carry.temp});

        const diaLow = item.dia;
        const diaHigh = item.dia;
        const sysLow = item.sys;
        const sysHigh = item.sys;

        carry.temp = {
          ...item,
          count: 1,
          low: diaLow,
          open: diaHigh,
          close: sysLow,
          high: sysHigh,
          diaLow,
          diaHigh,
          sysLow,
          sysHigh,
          y: [diaLow, diaHigh, sysLow, sysHigh],
          risk: item.risk,
        };

        if (index === list.length - 1) {
          carry.final.push({...carry.temp});
        }
      }

      return carry;
    }, {temp: null, final: []}).final;
  }, [chartData.data]);

  const bigChartDataCloseAvg = useCallback(() => {
    if (!chartData?.data?.length) {
      return [];
    }

    return chartData.data.reduce((carry, item, index, list) => {
      if (index === 0) {
        const diaLow = item.dia;
        const diaHigh = item.dia;
        const sysLow = item.sys;
        const sysHigh = item.sys;

        carry.temp = {
          ...item,
          count: 1,
          low: diaLow,
          open: diaHigh,
          close: sysLow,
          high: sysHigh,
          diaLow,
          diaHigh,
          sysLow,
          sysHigh,
          y: [diaLow, diaHigh, sysLow, sysHigh],
          risk: item.risk,
        }

        return carry;
      }

      const last = list[index - 1];

      if (differenceInMinutes(item.x, last.x) <= closeAvgMinutes) {
        const diaLow = pickLowestNumber([item.dia, carry.temp.y[0]]);
        const diaHigh = pickHighestNumber([item.dia, carry.temp.y[1]]);
        const sysLow = pickLowestNumber([item.sys, carry.temp.y[2]]);
        const sysHigh = pickHighestNumber([item.sys, carry.temp.y[3]]);

        carry.temp = {
          ...carry.temp,
          count: carry.temp.count + 1,
          x: item.x,
          low: diaLow,
          open: diaHigh,
          close: sysLow,
          high: sysHigh,
          diaLow,
          diaHigh,
          sysLow,
          sysHigh,
          y: [diaLow, diaHigh, sysLow, sysHigh],
          risk: carry.temp.risk.rank > item.risk.rank ? carry.temp.risk : item.risk,
        }
      } else {
        carry.final.push({...carry.temp});

        const diaLow = item.dia;
        const diaHigh = item.dia;
        const sysLow = item.sys;
        const sysHigh = item.sys;

        carry.temp = {
          ...item,
          count: 1,
          low: diaLow,
          open: diaHigh,
          close: sysLow,
          high: sysHigh,
          diaLow,
          diaHigh,
          sysLow,
          sysHigh,
          y: [diaLow, diaHigh, sysLow, sysHigh],
          risk: item.risk,
        };
      }

      if (index === list.length - 1) {
        carry.final.push({...carry.temp});
      }

      return carry;
    }, {temp: null, final: []}).final;
  }, [chartData.data, closeAvgMinutes]);

  const bigChartData = useMemo(() => caseMap(
    () => bigChartDataFull(),
    [
      [m => m === DATA_MODES.dailyMinMax, () => bigChartDataDailyMinMax()],
      [m => m === DATA_MODES.closeAvg, () => bigChartDataCloseAvg()],
    ],
    dataMode
  ), [dataMode, bigChartDataFull, bigChartDataCloseAvg, bigChartDataDailyMinMax]);

  const chartSize = useMemo(() => ({
    h: Math.floor(window.innerHeight * 0.15),
    w: Math.floor(window.innerWidth * 0.5),
  }), []);

  return (
    <SwrWrapper {...bloodPressure}>
      <div className="mb-4 space-y-4 xl:space-y-0 xl:grid xl:gap-4 xl:grid-cols-3 xl:items-center xl:justify-center">
        {!bloodPressure?.error ? (
          <LabeledFormControl label={t('session')}>
            <Select className="xl:select-xs" options={mappedObjectsToOptions([getPropOr(null, 'id'), getSessionName], bloodPressure.data?.sessions)} value={sessionId} onSelect={setSessionId}/>
          </LabeledFormControl>
        ) : <div/>}
        <LabeledFormControl label={t('dataMode.label')} className="mx-auto" labelClassName="xl:text-center xl:mx-auto">
          <div className="btn-group xl:mx-auto">
            {
              Object.entries(DATA_MODES).map(([key, value]) => (
                <button key={key} className={concatBySpace('btn xl:btn-xs btn-outline', dataMode === value ? 'btn-active' : null)} onClick={() => setDataMode(value)}>
                  {t(`dataMode.${key}`)}
                </button>
              ))
            }
          </div>
        </LabeledFormControl>
        {dataMode === DATA_MODES.closeAvg ? (
          <LabeledFormControl label={t('dataMode.closeAvgLabel')} labelClassName="xl:text-right xl:ml-auto">
            <InputPostAddon wrapperClass="h-12 xl:h-6" className="xl:input-xs" addon={t('closeAvgInputAddon')} type="number" value={closeAvgMinutes} onChange={mapInputEventOr(10, value => setCloseAvgMinutes(parseInt10(value)))}/>
          </LabeledFormControl>
        ) : <div/>}
      </div>
      <VictoryChart
        height={chartSize.h}
        width={chartSize.w}
        padding={{top: 0, right: 0, bottom: 30, left: 30}}
        domainPadding={10}
        containerComponent={<VictoryZoomContainer zoomDimension="x"/>}
      >
        <VictoryCandlestick
          dataComponent={<Candle />}
          data={bigChartData}
          candleWidth={7}
          labels={({datum}) => `${format(datum.x, 'yyyy-MM-dd H:mm')}\nSYS: ${datum.close} - ${datum.high}\nDIA: ${datum.low} - ${datum.open}`}
          labelComponent={
            <VictoryTooltip
              pointerLength={0}
              cornerRadius={0}
              flyoutPadding={15}
              style={{
                fontSize: FONT_SIZE,
              }}
              flyoutStyle={{
                fill: '#ffffff',
                stroke: '#000000',
                strokeWidth: 1,
                strokeOpacity: 0.2,
              }}
            />}
          events={[{
            target: 'data',
            eventHandlers: {
              onMouseOver: () => ({
                target: 'labels', mutation: () => ({ active: true })
              }),
              onMouseOut: () => ({
                target: 'labels', mutation: () => ({ active: false })
              })
            }
          }]}
          style={{
            data: {
              fill: pickBarColor,
              stroke: pickBarColor,
              strokeWidth: 2,
            },
            minLabels: {fill: '#444444'},
            maxLabels: {fill: '#444444'},
          }}
        />
        <VictoryAxis dependentAxis style={dependentAxisStyle} />
        <VictoryAxis
          fixLabelOverlap
          tickFormat={x => isValid(x) ? format(new Date(x), 'yyyy-MM-dd') : x}
          tickValues={chartData.tickValues}
          style={axisStyle}
        />
      </VictoryChart>
      <div ref={tableWrapperRef} className="overflow-x-auto">
        <table className="table w-full table-compact">
          <thead>
            <tr>
              <th className={concatBySpace(defTHeadClass, 'min-w-16')}></th>
              <th className={concatBySpace(defTHeadClass, 'sticky left-16')}></th>
              {chartData?.tickValues.map((date, index) => (
                <th className={defTHeadClass} key={index}>{format(date, 'yyyy-MM-dd')}</th>
              ))}
            </tr>
          </thead>
          {chartData?.timetableRange?.map(caseMap(
            prepareThrow(''),
            [
              [isTimetableMedication, (timetable) => (
                <tbody key={timetable.id} className={defTBodyClass}>
                  <tr>
                    <th className={concatBySpace(defTHeadClass, 'min-w-16')}>{timetable.time}</th>
                    <th className={concatBySpace(defTHeadClass, 'sticky left-16 shadow-right')}>{t('timetable.medication')}</th>
                    {Object.entries(timetable.valuesEachDay).map(([dateKey, values]) => {
                      const key = `${timetable.id}-${dateKey}`;
                      if (hasLength(values) < 1) {
                        return <EmptyCell key={key} />
                      }
                      return (<td key={key} className={defCellClass}>{format(parseISO(values[0].takenAt), 'HH:mm')}</td>);
                    })}
                  </tr>
                </tbody>
              )],
              [isTimetableMeasurement, (timetable) => (
                <tbody key={timetable.id} className={defTBodyClass}>
                  <tr>
                    <th className={concatBySpace(defTHeadClass, 'min-w-16')}>{timetable.time}</th>
                    <th className={concatBySpace(defTHeadClass, 'sticky left-16 shadow-right')}>{t('timetable.systolic')}</th>
                    {Object.entries(timetable.valuesEachDay).map(([dateKey, values]) => {
                      const key = `${timetable.id}-${dateKey}`;

                      if (hasLength(values) < 1) {
                        return <EmptyCell key={key} />
                      }

                      const highestRisk = mreduceMapOriginal(
                        Max,
                        ({rank}) => rank,
                        map(
                          compose(
                            nameToRisk,
                            measurementSysRisk,
                          ),
                          values
                        )
                      );

                      return (
                        <td key={key} className={defCellClass} style={{backgroundColor: highestRisk.color}}>
                          <div className="tooltip w-full" data-tip={values.map(measurementSysValue).join(' / ')}>
                            {Math.round(mreduceMap(Sum, measurementSysValue, values) / values.length)}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th className={concatBySpace(defTHeadClass, 'min-w-16')}></th>
                    <th className={concatBySpace(defTHeadClass, 'sticky left-16 shadow-right')}>{t('timetable.diastolic')}</th>
                    {Object.entries(timetable.valuesEachDay).map(([dateKey, values]) => {
                      const key = `${timetable.id}-${dateKey}`;

                      if (hasLength(values) < 1) {
                        return <EmptyCell key={key} />
                      }

                      const highestRisk = mreduceMapOriginal(
                        Max,
                        ({rank}) => rank,
                        map(
                          compose(
                            nameToRisk,
                            measurementDiaRisk,
                          ),
                          values
                        )
                      );

                      return (
                        <td key={key} className={defCellClass} style={{backgroundColor: highestRisk.color}}>
                          <div className="tooltip w-full" data-tip={values.map(measurementDiaValue).join(' / ')}>
                            {Math.round(mreduceMap(Sum, measurementDiaValue, values) / values.length)}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th className={concatBySpace(defTHeadClass, 'min-w-16')}></th>
                    <th className={concatBySpace(defTHeadClass, 'sticky left-16 shadow-right')}>{t('timetable.pulse')}</th>
                    {Object.entries(timetable.valuesEachDay).map(([dateKey, values]) => {
                      const key = `${timetable.id}-${dateKey}`;

                      if (hasLength(values) < 1) {
                        return <EmptyCell key={key} />
                      }

                      return (
                        <td key={key} className={defCellClass}>
                          <div className="tooltip w-full" data-tip={values.map(measurementPulseValue).join(' / ')}>
                            {Math.round(mreduceMap(Sum, measurementPulseValue, values) / values.length)}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th className={concatBySpace(defTHeadClass, 'min-w-16')}></th>
                    <th className={concatBySpace(defTHeadClass, 'sticky left-16 shadow-right')}>{t('timetable.measurementDate')}</th>
                    {Object.entries(timetable.valuesEachDay).map(([dateKey, values]) => {
                      const key = `${timetable.id}-${dateKey}`;

                      if (hasLength(values) < 1) {
                        return <EmptyCell key={key} />
                      }

                      const measurementToTime = compose(
                        dt => format(dt, 'HH:mm'),
                        parseISO,
                        measurementDate
                      );

                      const times = values.map(measurementToTime);

                      return (
                        <td key={key} className={defCellClass}>
                          <div className="tooltip w-full" data-tip={times.join(' / ')}>
                            {times[0]}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              )],
            ],
          ))}
        </table>
      </div>
    </SwrWrapper>
  );
}, (prev, next) => prev?.state?.id === next?.state?.id);

const PatientBasicTab = ({state, setState, isNew}) => {
  const [t] = useNestedTranslation(['patientLayout']);
  const doctorsSwr = API_ENDPOINT.GET_DOCTORS.swr();

  const updateOauthByIndex = callback => compose(
    map(tap(oauthAccesses => setState({oauthAccesses}))),
    merge((newItem, maybePair) =>
      maybePair.map(pair =>
        callback(pair.fst(), newItem, pair.snd())
      )
    ),
    map(findIndexByProp('id', state?.oauthAccesses)),
    branch,
  );

  const setOauthAccess = updateOauthByIndex((prop, value, list) => setProp(prop, value, list));
  const removeOauthAccess = updateOauthByIndex((prop, _, list) =>  unsetProp(prop, list));

  return (
    <>
      <div className="space-y-4 xl:space-y-0 xl:gap-4 xl:grid xl:grid-cols-3">
        <LabeledFormControl label={t('firstName')}>
          <Input value={state?.firstName || ''} onChange={mapInputEvent(firstName => setState({firstName}))}/>
        </LabeledFormControl>

        <LabeledFormControl label={t('lastName')}>
          <Input value={state?.lastName || ''} onChange={mapInputEvent(lastName => setState({lastName}))}/>
        </LabeledFormControl>

        <LabeledFormControl label={t('dob')}>
          <Input value={state?.dob || ''} onChange={mapInputEvent(dob => setState({dob: formatIsoDateInput(dob)}))}/>
        </LabeledFormControl>

        <LabeledFormControl label={t('personCode')}>
          <Input value={state?.personCode || ''} onChange={mapInputEvent(personCode => setState({personCode}))}/>
        </LabeledFormControl>

        <LabeledFormControl label={t('email')}>
          <Input value={state?.email || ''} onChange={mapInputEvent(email => setState({email}))}/>
        </LabeledFormControl>

        <LabeledFormControl label={t('password')}>
          <Input value={state?.password || ''} onChange={mapInputEvent(password => setState({password}))}/>
        </LabeledFormControl>

        <LabeledFormControl label={t('phoneNo')}>
          <Input value={state?.phoneNo || ''} onChange={mapInputEvent(phoneNo => setState({phoneNo}))}/>
        </LabeledFormControl>

        <LabeledFormControl label={t('doctor')}>
          <Select options={objectsToOptions(['id', 'fullName'], forceArray(doctorsSwr?.data))} value={state?.doctor} onSelect={doctor => setState({doctor})}/>
        </LabeledFormControl>

        <LabeledFormControl className="col-span-3" label={t('sex')}>
          <IconToggle
            value={isDefined(state?.sex) ? state?.sex === SEX.MALE ? 1 : 0 : undefined} onChange={useCallback(num => {setState({sex: num === 0 ? SEX.FEMALE : SEX.MALE})}, [setState])}>
            <IconFemale className="w-full"/>
            <IconMale className="w-full"/>
          </IconToggle>
        </LabeledFormControl>

        <LabeledFormControl className="col-span-3" label={t('oauthAccesses')}>
          <div className="space-y-4">
            {map(
              entry => <OauthCard key={entry.id} {...{entry, setOauthAccess, removeOauthAccess}} />,
              forceArray(state?.oauthAccesses)
            )}
          </div>
          <AppendVerticalButton onClick={
              useCallback(() => setState({oauthAccesses: [
                ...(state?.oauthAccesses || []),
                {id: `${+ new Date()}-fresh`}
              ]}), [setState, state])
          }/>
        </LabeledFormControl>
      </div>
    </>
  );
};

const OauthCard = ({entry, setOauthAccess = () => {}, removeOauthAccess = () => {}}) => {
  const omronUrlSwr = API_ENDPOINT.GET_OMRON_LOGIN_URL.swr();
  const deviceApisSwr = API_ENDPOINT.GET_DEVICE_APIS.swr();
  const [t, td] = useNestedTranslation(['patientLayout', 'patientLayout.deviceApi']);
  const {notify} = useNotification();
  const [isAdvanced, setIsAdvanced] = useState(false);
  const socketConnectionRef = useRef(null);

  const deviceApiMap = useMemo(() => deviceApisSwr?.data?.reduce(
    (carry, item) => {
      carry[item.id] = item.title;
      carry[item.title] = item.id;
      return carry;
    }, {}
  ), [deviceApisSwr.data]);
  const deviceApiOptions = useMemo(() => mappedObjectsToOptions([
    compose(
      option(null),
      getProp('id'),
    ),
    compose(
      option(null),
      map(td),
      getProp('title')
    )
  ], forceArray(deviceApisSwr?.data)), [deviceApisSwr?.data, td]);

  const deviceApiTitle = useMemo(() => ifElse(isString, identity, num => deviceApiMap?.[num], entry?.deviceApi), [deviceApiMap, entry?.deviceApi]);
  const deviceApiId = useMemo(() => ifElse(isNumber, identity, num => deviceApiMap?.[num], entry?.deviceApi), [deviceApiMap, entry?.deviceApi]);

  useEffect(() => {
    if (socketConnectionRef.current !== null) {
      socketConnectionRef.current?.close();
      socketConnectionRef.current = null;
    }

    const getCodeUpdaterEvent = compose(
      map(setOauthAccess),
      map(code => ({
        ...entry,
        code,
        accessToken: null,
        expiresAt: null,
        refreshToken: null,
      })),
      getProp('data'),
    );

    socketConnectionRef.current = caseMap(
      () => null,
      [
        [isSame(DEVICE_API.OMRON), () => getMercureEvent(env.MERCURE_OMRON_OAUTH_TOPIC, getCodeUpdaterEvent)],
        [isSame(DEVICE_API.FITBIT), () => getMercureEvent(env.MERCURE_FITBIT_OAUTH_TOPIC, getCodeUpdaterEvent)],
      ],
      deviceApiTitle
    );

    return () => {
      if (socketConnectionRef.current !== null) {
        socketConnectionRef.current?.close();
        socketConnectionRef.current = null;
      }
    };
  }, [deviceApiTitle, entry, setOauthAccess]);

  useEffect(() => {
    if (deviceApiTitle === DEVICE_API.OMRON && omronUrlSwr.error) {
      notify({
        children: omronUrlSwr.error,
        className: 'alert-error'
      });
    }
  }, [notify, omronUrlSwr.error, deviceApiTitle]);

  return (
    <div key={entry?.id} className="card shadow-md bg-white">
      <div className="card-body p-4">
        <div className="items-center w-full space-y-4 lg:space-y-0 lg:gap-4 lg:grid lg:grid-cols-3">
          <LabeledFormControl label={t('deviceApi.label')}>
            <Select
                onSelect={deviceApi => setOauthAccess({...entry, deviceApi})}
                value={deviceApiId}
                options={deviceApiOptions}
            />
          </LabeledFormControl>

          {deviceApiTitle === DEVICE_API.FITBIT ? (
            <LabeledFormControl label={t('userId')}>
              <Input
                value={entry?.userId || ''}
                onChange={mapInputEvent(userId => setOauthAccess({...entry, userId}))}
              />
            </LabeledFormControl>
          ) : null}

          <div className={deviceApiTitle === DEVICE_API.FITBIT ? '' : 'col-span-2'}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">{t('code')}</span>
                {
                  caseMap(
                    () => null,
                    [
                      [title => title === DEVICE_API.FITBIT, () => <a rel="noreferrer" target="_blank" href={env.FITBIT_LOGIN_URL} className={`link`}>{t('getCode')}</a>],
                      [title => title === DEVICE_API.OMRON && omronUrlSwr.error,  () => <span className={`link text-error`}>{omronUrlSwr.error}</span>],
                      [title => title === DEVICE_API.OMRON,  () => <a rel="noreferrer" target="_blank" href={omronUrlSwr.data} className={`link`}>{t('getCode')}</a>],
                    ],
                    deviceApiTitle,
                  )
                }
              </label>
              <Input value={entry?.code || ''} onChange={mapInputEvent(code => setOauthAccess({...entry, code}))}/>
            </div>
          </div>

          <Transition show={isAdvanced} {...TransitionVariant.opacity} className="lg:grid lg:grid-cols-3 lg:col-span-3 lg:gap-x-4 space-y-4 lg:space-y-0">
            <LabeledFormControl label={t('accessToken')}>
              <Input value={entry?.accessToken || ''} onChange={mapInputEvent(accessToken => setOauthAccess({...entry, accessToken}))}/>
            </LabeledFormControl>
            <LabeledFormControl label={t('refreshToken')}>
              <Input value={entry?.refreshToken || ''} onChange={mapInputEvent(refreshToken => setOauthAccess({...entry, refreshToken}))}/>
            </LabeledFormControl>
            <LabeledFormControl label={t('expiresAt')}>
              <Input value={entry?.expiresAt || ''} onChange={mapInputEvent(expiresAt => setOauthAccess({...entry, expiresAt: formatIsoDateInput(expiresAt)}))}/>
            </LabeledFormControl>
          </Transition>

        </div>
        <div className="text-right mt-4 lg:space-x-4">
          <button onClick={() => setIsAdvanced(!isAdvanced)} className={`btn btn-ghost`}>
            <span>{t('deviceApi.showAdvanced')}</span>
            <CogIcon className="w-6 h-6 ml-4"/>
          </button>
          <DeleteButton onClick={() => removeOauthAccess(entry)} disableWait={200} confirmWait={2000}/>
        </div>
      </div>
    </div>
  );
};

const TestField = ({state, setState, label, addon, name}) => {
  const [t] = useNestedTranslation('patientLayout.testsTab');
  const statePatientTests = useMemo(() => forceArray(state?.patientTests), [state?.patientTests]);
  const setTest = useCallback((name, value) => {
    setState({
      ...state,
      patientTests: ifElse(
        isDefined,
        () => statePatientTests.map(ifElse(
          (test) => test.name === name,
          test => ({...test, value}),
          identity
        )),
        () => [...statePatientTests, {name, value}],
        statePatientTests.find((test) => test.name === name)
      )
    })
  }, [setState, state, statePatientTests]);
  const getTest = name => statePatientTests.find(test => test?.name === name);
  const getTestValue = compose(
    x => x?.value || '',
    getTest,
  );

  return (
    <LabeledFormControl label={t(label)} labelTextClassName="overflow-ellipsis overflow-hidden w-full whitespace-nowrap">
      <InputPostAddon id={label} addon={addon} value={getTestValue(name)} onChange={mapInputEvent(value => setTest(name, value))}/>
    </LabeledFormControl>
  )
};

const PatientTestsTab = ({state, setState}) => {
  const [t] = useNestedTranslation('patientLayout.testsTab');
  return (
    <>
      <div className="space-y-12">
        <div>
          <h3 className="text-xl text-primary">{t('measurements')}</h3>
          <div className="space-y-4 xl:space-y-0 xl:gap-4 xl:grid xl:grid-cols-4">
            <TestField {...{label: 'height', addon: 'cm', name: 'height', state, setState}} />
            <TestField {...{label: 'weight', addon: 'cm', name: 'weight', state, setState}} />
            <TestField {...{label: 'weight', addon: 'cm', name: 'waistSize', state, setState}} />
            <TestField {...{label: 'bmi', addon: 'bmi', name: 'bmi', state, setState}} />
          </div>
        </div>

        <div>
          <h3 className="text-xl text-primary">{t('bloodPressure')}</h3>
          <div className="space-y-4 xl:space-y-0 xl:gap-4 xl:grid xl:grid-cols-3">
            <TestField {...{label: 'systolic', addon: 'mmHg', name: 'systolic', state, setState}} />
            <TestField {...{label: 'diastolic', addon: 'mmHg', name: 'diastolic', state, setState}} />
            <TestField {...{label: 'bpm', addon: 'bpm', name: 'calmHr', state, setState}} />
          </div>
        </div>

        <div>
          <h3 className="text-xl text-primary">{t('labWork')}</h3>
          <div className="space-y-4 xl:space-y-0 xl:gap-4 xl:grid  xl:grid-cols-5">
            <TestField {...{label: 'triglycerides', addon: 'mmol/L', name: 'triglycerides', state, setState}} />
            <TestField {...{label: 'ldlCholesterol', addon: 'mmol/L', name: 'mtlCholesterol', state, setState}} />
            <TestField {...{label: 'hdlCholesterol', addon: 'mmol/L', name: 'dtlCholesterol', state, setState}} />
            <TestField {...{label: 'cReactive', addon: 'mmol/L', name: 'creactive', state, setState}} />
            <TestField {...{label: 'bloodSugar', addon: 'mmol/L', name: 'bloodSugar', state, setState}} />
          </div>
        </div>
      </div>
    </>
  );
};

const PatientLayout = (props) => {
  const [state, setState] = useMergeReducer({});
  const [t, tt] = useNestedTranslation(['patientLayout', 'patientLayout.tab']);
  const id = useIdParam(routes.patients.path, props?.id);
  const swr = API_ENDPOINT.GET_PATIENT.swr(id);
  const isNew = useMemo(() => id === 'new', [id]);
  const sessionForm = useSessionForm({showPatientSelect: false});
  const deviceApisSwr = API_ENDPOINT.GET_DEVICE_APIS.swr();
  const deviceApiMap = useMemo(() => deviceApisSwr?.data?.reduce(
    (carry, item) => {
      carry[item.id] = item.title;
      carry[item.title] = item.id;
      return carry;
    }, {}
  ), [deviceApisSwr.data]);
  const getDeviceApiId = useCallback(input => ifElse(isNumber, identity, num => deviceApiMap?.[num], input), [deviceApiMap]);

  useLayoutEffect(() => {
    setState({...swr?.data});
  }, [setState, swr]);

  return (
    <EditLayout
      breadcrumbs={RouteToLink(routes.patients)}
      swr={swr}
      t={t}
      fallbackRedirectPath={routes.patients.path}
      removeFetcher={isNumeric(id) ? () => API_ENDPOINT.DELETE_PATIENT.fetch(state.id) : undefined}
      saveFetcher={() => API_ENDPOINT[isNew ? 'POST_PATIENT_USER' : 'PUT_PATIENT'].fetch({
        ...state,
        sessions: [sessionForm.state],
        oauthAccesses: forceArray(state?.oauthAccesses).map(oa => ({
          ...oa,
          deviceApi: mapRelationToIdObj(getDeviceApiId(oa?.deviceApi))
        }))
      })}>
      {isNew ? (
        <Tabs className="mb-8" tabMap={[
          ['#basic', tt('basic')],
          ['#session', tt('session')],
          ['#tests', tt('tests')]
        ]}>
          <PatientBasicTab {...{state, setState, isNew}} />
          <>{sessionForm.Form}</>
          <PatientTestsTab {...{state, setState}} />
        </Tabs>
      ) : (
        <Tabs className="mb-8" tabMap={[
          ['#blood-pressure', tt('bloodPressure')],
          ['#polar', tt('polar')],
          ['#sleep', tt('sleep')],
          ['#basic', tt('basic')],
          ['#tests', tt('tests')]
        ]}>
          <PatientBloodPressureTab {...{state}} />
          <PatientPolarTab {...{state}} />
          <PatientSleepTab {...{state}} />
          <PatientBasicTab {...{state, setState}} />
          <PatientTestsTab {...{state, setState}} />
        </Tabs>
      )}
    </EditLayout>
  );
};

export default PatientLayout;
