import {Transition} from '@headlessui/react';
import {PlusIcon, QuestionMarkCircleIcon, SaveIcon, TrashIcon} from '@heroicons/react/solid';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {concatBySpace} from '../util/helper';
import {TransitionVariant} from './Transition';

const DeleteButton = ({
  className,
  onClick = () => {},
  disabled = false,
  confirmWait = 5000,
  disableWait = 1000,
  children,
  ...props
}) => {
  const STATE = useMemo(() => ({
    UNCLICKED: 0,
    TRANS_TO_DISABLED: 1,
    DISABLED: 2,
    CONFIRMABLE: 3,
    CONFIRMED: 4,
  }), []);
  const WIDTHS = useMemo(() => ({
    lt: ['w-32', 'w-36'],
  }), []);
  const timeoutRef = useRef(null);
  const inc = useCallback(num => num + 1, []);
  const [t, {language}] = useTranslation();
  const [state, setState] = useState(STATE.UNCLICKED);
  const handle = useCallback(() => setState(inc), [setState, inc]);
  const buttonWidth = useMemo(() => WIDTHS?.[language] || WIDTHS.lt, [WIDTHS, language]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    switch (state) {
      case STATE.TRANS_TO_DISABLED:
        timeoutRef.current = setTimeout(() => setState(STATE.DISABLED), 250);
        break;
      case STATE.CONFIRMABLE:
        timeoutRef.current = setTimeout(() => setState(STATE.UNCLICKED), confirmWait);
        break;
      case STATE.DISABLED:
        timeoutRef.current = setTimeout(() => setState(STATE.CONFIRMABLE), disableWait);
        break;
      case STATE.CONFIRMED:
        onClick();
        timeoutRef.current = setTimeout(() => setState(STATE.UNCLICKED), 250);
        break;
      default:
        break;
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [state]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <button
      className={
        concatBySpace(
          `btn transition-all h-12 overflow-hidden duration-250 ${state === STATE.UNCLICKED ? buttonWidth[0] : buttonWidth[1]} ${[STATE.TRANS_TO_DISABLED, STATE.DISABLED].includes(state) || disabled ? 'btn-disabled' : 'btn-error'}`,
          className
        )
      }
      onClick={handle}
      disabled={state === STATE.DISABLED}
      {...props}
      >
      <Transition
        {...TransitionVariant.opacity}
        show={state === STATE.UNCLICKED}
        afterLeave={() => setState(STATE.TRANS_TO_DISABLED)}
        className="flex justify-between items-center w-full">
        {children || t('deleteButton.delete')}
        <TrashIcon className="w-6 h-6 ml-4"/>
      </Transition>
      <Transition
        show={[STATE.DISABLED, STATE.CONFIRMABLE].includes(state)}
        className="flex justify-between items-center w-full">
        {t('deleteButton.deleteReally')}
        <QuestionMarkCircleIcon className="w-6 h-6 ml-4"/>
      </Transition>
    </button>
  );
}

const SaveButton = ({className, disabled, ...props}) => {
  const [t] = useTranslation();
  return (
    <button {...props} className={className || `btn ${disabled ? 'btn-disabled' : 'btn-success'}`}>
      {t('saveButton')}
      <SaveIcon className="w-8 h-8 m-auto ml-4" />
    </button>
  );
}

const AppendVerticalButton = ({className = 'py-4', ...props}) => {
  const [t] = useTranslation();
  return (
    <button className="mt-5 btn btn-block text-base-content bg-transparent border-transparent shadow-inner hover:bg-transparent" {...props}>
      {t('appendVerticalButton')}
      <PlusIcon className="w-4 h-4 ml-4"/>
    </button>
  );
};

export {
  DeleteButton,
  SaveButton,
  AppendVerticalButton,
};
