import {Transition} from "@headlessui/react";
import {createContext, useCallback, useContext, useState} from "react";
import {concatBySpace} from "../util/helper";
import {TransitionVariant} from "./Transition";


const NotificationContext = createContext();

const NotificationContextProvider = ({children}) => {
  const [data, setData] = useState([]);

  const notify = useCallback(({children, timeout = 8000, className}) => {
    setData(d => {
      const notification = {
        id: `${d.length}-${+ new Date()}`,
        className,
        children,
        show: true,
        timeout,
      };

      setTimeout(() => {
        setData(d => d.map(d => d.id === notification.id ? ({...d, show: false}) : d))
      }, timeout)

      return [notification, ...d]
    });
  }, []);

  return (
    <NotificationContext.Provider value={{notify}}>
      {children}
      <div className="transition-all duration-1000 ease-in-out fixed top-0 left-0 right-0 pointer-events-none flex items-center flex-col z-10 my-16 mx-32">
        {
          data.map((notification) => (
            <Transition appear key={notification.id} show={notification.show} {...TransitionVariant.slideFromTop} className="mt-4">
              <div className="bg-white rounded-2xl shadow-lg">
                <div className={concatBySpace('alert', notification?.className)}>
                  <div className="flex-1">
                    <label>{notification.children}</label>
                  </div>
                </div>
              </div>
            </Transition>
          ))
        }
      </div>
    </NotificationContext.Provider>
  );
}

const useNotification = () => {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }

  return context;
};

export {
  NotificationContextProvider,
  useNotification,
}
