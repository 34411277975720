import {ArrowLeftIcon, InformationCircleIcon} from "@heroicons/react/outline";
import {useCallback, useEffect, useState} from "react";
import {API_ENDPOINT} from "../api";
import {Input, LabeledFormControl} from "../components/Form";
import {useNestedTranslation} from "../hooks";
import {concatBySpace} from "../util/helper";
import {mapInputEvent, runWithEventWhich} from "../util/mapper";

const ForgotPasswordForm = ({email, onCancel, setEmail}) => {
  const [t, tl] = useNestedTranslation(['forgotPasswordForm', 'label']);
  const [_email, _setEmail] = useState(email)
  const defaultInstructions = t('instructions');
  const [instructions, setInstructions] = useState(defaultInstructions);
  const remind = useCallback(() => {
    API_ENDPOINT.POST_PASSWORD_CHANGE.fetchInit({email: _email}).then(() => setInstructions(t('instructionsSent')))
  }, [_email, t]);

  useEffect(() => { setEmail(_email) }, [_email, setEmail]);

  return (
    <>
      <LabeledFormControl label={tl('email')} >
        <Input id="input-email" value={_email} onChange={mapInputEvent(_setEmail)} onKeyUp={runWithEventWhich(13, remind)}/>
      </LabeledFormControl>

      <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between lg:items-center">
        <button className="btn" onClick={remind}>{t('button')}</button>
        <button className="btn btn-outline" onClick={onCancel}><ArrowLeftIcon className="h-4 w-4 mr-2" />{t('back')}</button>
      </div>

      <div className={concatBySpace('alert', instructions === defaultInstructions ? null : 'alert-success')}>
        <div>
          <InformationCircleIcon className="w-16 mr-2 self-start block" />
          {instructions}
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
