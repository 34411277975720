import env from "../env";
import {Reader, curry, compose} from 'crocks';

export const styleCombo = {
  absoluteMiddle: 'absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
}

/**
 * @type {Reader}
 */
export const getMercureTopic = Reader(() => new URL(env.MERCURE_URL))
  .chain(url => Reader.ask(topic => {
    url.searchParams.append('topic', topic);
    return url;
  }))
  .map(url => url.toString());

/**
* @function
* @param {string} topic               Mercure Topic to subscribe
* @param {function} onMessageCallback Callback function that receives the event
* @returns {EventSource}
*/
export const getMercureEvent = curry((topic, onMessageCallback) => compose(
  eventSource => {
    eventSource.onmessage = onMessageCallback;
    return eventSource;
  },
  url => new EventSource(url),
  getMercureTopic.runWith
)(topic));
