import {
  getProp,
  isTrue,
  safe,
  composeK,
  compose,
  merge,
  map,
  branch,
} from 'crocks';
import {fromPromise} from 'crocks/Async';
import eitherToAsync from 'crocks/Async/eitherToAsync';
import {Left, Right} from 'crocks/Either';

export const eitherResponseOk = compose(
  merge((r, mBool) => mBool.option(false) ? Right(r) : Left(r)),
  map(composeK(safe(isTrue), getProp('ok'))),
  branch
);

export const asyncResponseOk = eitherToAsync(eitherResponseOk);

/**
 * Includes asyncResponseOk
 */
export const asyncToJson = composeK(
  response => fromPromise(() => response?.json())(),
  asyncResponseOk,
);
