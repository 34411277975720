import {fromPromise} from "crocks/Async";
import {compose} from "crocks";
import {API_ENDPOINT} from "../api";
import {useLogin} from "../api/login";
import {Title} from "../components";
import {useMergeReducer, useNestedTranslation} from "../hooks";
import {asyncToJson} from "../util/fetch";
import {mapInputEvent, runWithEventWhich} from "../util/mapper";
import {useCallback} from "react";
import {CheckIcon} from "@heroicons/react/solid";
import {concatBySpace} from "../util/helper";

const SettingsLayout = () => {
  const {data} = useLogin();
  const [tsl, tc] = useNestedTranslation(['settingsLayout', 'settingsLayout.section.contacts']);

  const [emailState, setEmailState] = useMergeReducer({
    email: data?.email,
    disabled: false,
    tag: null
  });

  const emailChangeFail = useCallback(
    error => compose(
      () => setEmailState({
        disabled: false,
        tag: (
          <div className="badge badge-error w-full p-2">
            {tc('emailChangeFail')}
          </div>
        )
      }),
      console.error,
    )(error),
    [setEmailState, tc]
  );

  const emailChangeSuccess = useCallback(
    () => {
      setEmailState({
        disabled: false,
        tag: (
          <div className="badge badge-success w-full p-2">
            {tc('emailChangeSuccess')}
          </div>
        )
      })
    },
    [setEmailState, tc]
  );

  const onEmailChange = useCallback(() => {
    setEmailState({disabled: true});
    fromPromise(API_ENDPOINT.POST_EMAIL_CHANGE.fetch)
    (emailState.email)
      .chain(asyncToJson)
      .fork(emailChangeFail, emailChangeSuccess);
  }, [emailState.email, setEmailState, emailChangeFail, emailChangeSuccess]);

  return (
    <>
        <Title>{tsl('title')}</Title>
        <div className="mt-8">
          <Title tag='h3' className="font-semibold text-lg">{tc('title')}</Title>
          <div>
            <div className="grid grid-cols-6 items-center gap-4 mt-4">
              <InputSectionRow
                id="email-change"
                label={tc('emailChange')}
                button={<CheckIcon className="w-6 h-6"/>}
                inputProps={{
                  value: emailState.email,
                  onChange: mapInputEvent(email => setEmailState({email})),
                  onKeyUp: runWithEventWhich(13, onEmailChange),
                  disabled: emailState.disabled
                }}
                buttonProps={{onClick: onEmailChange, className: concatBySpace('btn btn-success btn-block', emailState?.disabled ? 'loading' : null), disabled: emailState?.disabled}}
                bottom={emailState.tag}
              />
            </div>
          </div>
        </div>
    </>
  );
};

const SectionRow = ({left, middle, right, bottom}) => (
  <>
    <div className="col-span-6 border-t border-gray-200" />
    <div className="col-span-6 lg:col-span-2 text-left">{left}</div>
    <div className="col-span-6 lg:col-span-3 text-center">{middle}</div>
    <div className="col-span-6 lg:col-span-1 text-right">{right}</div>
    {bottom ? <div className="col-span-6">{bottom}</div> : null}
  </>
);

const InputSectionRow = ({id, label, inputProps, button, buttonProps, labelProps, bottom}) => (
  <SectionRow
    left={<label htmlFor={id} className={labelProps?.className || 'text-gray-600'} {...labelProps}>{label}</label>}
    middle={<input className="input w-full input-bordered" id={id} {...inputProps}/>}
    right={<button className={buttonProps?.className || `btn btn-block`} {...buttonProps}>{button}</button>}
    bottom={bottom}
  />
);


export default SettingsLayout;
