import {useHistory} from "react-router-dom";
import {API_ENDPOINT} from "../api";
import {LOCAL_STORAGE_KEYS, routes} from "../App";
import {TableColumnRenderer} from "../components/Table";
import {useNestedTranslation} from "../hooks";
import {createNewItemPath} from "../util/helper";
import TableLayout, {LINK_PROPS_FN} from "./TableLayout";

const DevicesLayout = () => {
  const [t, tt] = useNestedTranslation(['devicesLayout', 'devicesLayout.table']);
  const swr = API_ENDPOINT.GET_DEVICES.swr();
  const institutionSwr = API_ENDPOINT.GET_INSTITUTIONS.swr();
  const deviceApisSwr = API_ENDPOINT.GET_DEVICE_APIS.swr();
  const history = useHistory();

  return (
    <TableLayout {...{
      title: t('title'),
      onCreateCallback: createNewItemPath(history, routes.device.path),
      localStorageSettingsKey: LOCAL_STORAGE_KEYS.DEVICE_TABLE_SETTINGS,
      linkPropFn: LINK_PROPS_FN.idProp(routes.device.path),
      swr,
      columnSettings: {
        id: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'id'),
          translated: tt('id'),
        },
        devicePack: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'devicePack'),
          translated: tt('devicePack'),
        },
        serialNo: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'serialNo'),
          translated: tt('serialNo'),
        },
        deviceApi: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.mapIdPropToListItemProp(
            'deviceApi',
            deviceApisSwr.data || [],
            'title',
            tt('unknown'),
            TableColumnRenderer.span
          ),
          translated: tt('deviceApi'),
        },
        institution: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.mapIdPropToListItemProp(
            'institution',
            institutionSwr.data || [],
            'title',
            tt('unknown'),
            TableColumnRenderer.span
          ),
          translated: tt('institution'),
        },
      }
    }}
    />
  );
};

export default DevicesLayout;
