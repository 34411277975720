import {InformationCircleIcon} from "@heroicons/react/outline";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {fromPromise, Rejected, Resolved} from "crocks/Async";
import isFunction from "crocks/core/isFunction";
import isString from "crocks/core/isString";
import ifElse from "crocks/logic/ifElse";
import {hasProps} from "crocks/predicates";
import propEq from "crocks/predicates/propEq";
import {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import {API_ENDPOINT} from "../api";
import {useLogin} from "../api/login";
import {routes} from "../App";
import {Input, LabeledFormControl} from "../components/Form";
import {useNestedTranslation} from "../hooks";
import {asyncToJson} from "../util/fetch";
import {caseMap} from "../util/helper";
import {mapInputEvent, runWithEventWhich} from "../util/mapper";

const TwoFactorForm = ({onCancel}) => {
  const [t, tl] = useNestedTranslation(['twoFactorForm', 'label']);
  const [authCode, setAuthCode] = useState('');
  const [error, setError] = useState(null);
  const history = useHistory();
  const login = useLogin();

  const toLogin = useCallback(() => {
    if (isFunction(onCancel)) {
      onCancel();
    }
  }, [onCancel]);

  const sendAuthCode = useCallback(() => (
    fromPromise(API_ENDPOINT.POST_2FA.fetch)(authCode)
    .chain(asyncToJson)
    .chain(
      caseMap(() => Rejected(t('unexpectedResponse')), [
        [hasProps([
        'doctor',
        'email',
        'fullName',
        'hidden',
        'id',
        'patient',
        'roles',
        'username',
      ]), Resolved],
        [propEq('error', '2fa_failed'), () => Rejected(t('badAuthCode'))],
      ])
    )
    .fork(
      ifElse(isString, setError, toLogin),
      data => {
        login.setData(data);
        history.replace(routes.dashboard.path);
      }
    )
  ), [authCode, t, toLogin, login, history]);

  return (
    <>
      <LabeledFormControl label={tl('authCode')}>
        <Input id="input-auth-code" value={authCode} onChange={mapInputEvent(setAuthCode)} onKeyUp={runWithEventWhich(13, sendAuthCode)} />
      </LabeledFormControl>

      <div className="flex justify-between w-full">
        <button className="btn" onClick={sendAuthCode}>
          {t('sendAuthCode')}
        </button>
        <button className="btn btn-outline" onClick={toLogin}>
          <ArrowLeftIcon className="h-4 w-4 mr-2" />
          {t('back')}
        </button>
      </div>

      {error ? (
      <div className="rounded-lg p-2 text-center badge-error w-full overflow-hidden">
        {error}
      </div>) : null}

      <div className="alert">
        <div>
          <InformationCircleIcon className="w-16 mr-2 self-start block" />
          {t('instructions')}
        </div>
      </div>
    </>
  );
}
export default TwoFactorForm;
