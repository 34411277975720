import env from "../env";

const getHeaders = (customHeaders = {}) => {
  return {
    ...customHeaders,
    'Content-Type': 'application/json;charset=UTF-8'
  };
};

// Utility for GET requests
export const get = async (url, customHeaders = {}) => {
  const response = await fetch(`${env.BACKEND_URL}/api/v1${url}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders(customHeaders)
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
};

// Utility for POST requests
export const post = async (url, data, customHeaders = {}) => {
  const response = await fetch(`${env.BACKEND_URL}/api/v1${url}`, {
    method: 'POST',
    credentials: 'include',
    headers: getHeaders(customHeaders),
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
};

export const uploadFile = async (url, file) => {
  const response = await fetch(`${env.BACKEND_URL}${url}`, {
    method: 'POST',
    headers: getHeaders(),
    body: file // No need to JSON.stringify
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

// Utility for DELETE requests
export const del = async (url, data = {}, customHeaders = {
  'Content-Type': 'application/json',
}) => {
  const response = await fetch(`${env.BACKEND_URL}${url}`, {
    method: 'DELETE',
    headers: getHeaders(customHeaders),
    body: Object.keys(data).length === 0 ? null : JSON.stringify(data)
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Assuming the DELETE request may or may not return a body
  return response.status === 204 ? null : await response.json();
};

