import {useCallback, useEffect} from "react";
import {concatBySpace, isNumeric, isObjectWithProps} from "../util/helper";
import {isDefined, map, curry, safe, and, isArray, pick, not, isEmpty, ifElse, identity, getPropOr} from 'crocks';

/**
 * @param {array} arr An Array consisting of 0: value, 1: text
 */
const arrayToOption = arr => safe(
  and(isArray, ({length}) => length > 1), arr
).map(
  ([value, text]) => ({value, text})
).option(null)

/**
 * @param {array} props Props that are mapped 0: value, 1: text
 * @param {object} object Object that value and text is extracted from
 */
const objectToOption = curry((props, object) => 
  safe(isObjectWithProps(props), object)
  .map(pick(props))
  .map(Object.values)
  .map(arrayToOption)
  .option(null),
);

/**
 * @param {array} props Props that are mapped 0: value, 1: text
 * @param {array} objects Objects that value and text is extracted from
 */
const objectsToOptions = curry((props, objects) => 
  safe(isArray, objects)
  .map(arr => arr.map(objectToOption(props)))
  .map(arr => arr.filter(item => item))
  .option([])
);

/**
 * @param {Array[function:string, function:string]>} props Props that are mapped 0: fn => value, 1: fn => text
 * @param {array} objects Objects that value and text is extracted from
 */
const mappedObjectsToOptions = curry((props, objects) => 
  safe(isArray, objects)
  .map(arr => arr.map(item => ({value: props[0](item), text: props[1](item)})))
  .map(arr => arr.filter(item => item?.value && item?.text))
  .option([])
);

export const Select = ({
  className,
  onSelect = () => null,
  options = [],
  ...props
}) => {
  useEffect(() => {
    const value = options?.[0]?.value;
    const isSelected = isDefined(options?.find(({value}) => value === props?.value));
    const optionExists = isDefined(value);

    if (!isSelected && optionExists) {
      onSelect(value);
    }
  }, [options]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <select
      className={concatBySpace(`select select-bordered`, className)}
      onChange={useCallback(({target: {selectedIndex}}) => onSelect(options[selectedIndex].value), [onSelect, options])}
      {...props}
      value={props?.value || ''}
    >
      {options?.map((o, i) => <option key={i} value={o.value}>{o.text}</option>)}
    </select>
  )
};

export const SelectMultiple = ({
  className,
  onSelect = () => null,
  options = [],
  ...props
}) => (
  <select
    multiple
    className={concatBySpace(`select select-bordered h-52`, className)}
    onChange={useCallback(({target: {selectedOptions}}) => onSelect(
      safe(not(isEmpty), selectedOptions)
      .map(Array.from)
      .map(map(getPropOr(null, 'value')))
      .map(ifElse(values => values.every(isNumeric), map(parseFloat), identity))
      .option([])
    ), [onSelect])}
    {...props}>
    {
      safe(isArray, options)
      .map(map(({value, text}) => ({
        key: value,
        value,
        className: 'my-1 py-1',
        children: text,
      })))
      .map(map(props => <option {...props}/>))
      .option(null)
    }
  </select>
)

export default Select;

export {
  arrayToOption,
  mappedObjectsToOptions,
  objectToOption,
  objectsToOptions,
}
