import {useLayoutEffect, useMemo} from "react";
import {API_ENDPOINT} from "../api";
import {routes} from "../App";
import {Select} from "../components";
import {RouteToLink} from "../components/BreadCrumbs";
import {Input, LabeledFormControl} from "../components/Form";
import {objectsToOptions, SelectMultiple} from "../components/Select";
import {useIdParam, useMergeReducer, useNestedTranslation} from "../hooks";
import {mapInputEvent} from "../util/mapper";
import EditLayout from "./EditLayout";

const DoctorLayout = () => {
  const [t] = useNestedTranslation(['doctorLayout']);
  const id = useIdParam(routes.doctors.path);
  const swr = API_ENDPOINT.GET_DOCTOR.swr(id);
  const [state, setState] = useMergeReducer({
    firstName: '',
    lastName: '',
    ...swr?.data
  })

  const patientsSwr = API_ENDPOINT.GET_PATIENTS.swr();
  const isNew = useMemo(() => id === 'new', [id]);
  const institutionSwr = API_ENDPOINT.GET_INSTITUTIONS.swr();

  useLayoutEffect(() => {
    setState({...swr?.data})
  }, [swr?.data, setState]);

  return (
    <EditLayout
      breadcrumbs={RouteToLink(routes.doctors)}
      t={t}
      swr={swr}
      fallbackRedirectPath={routes.doctors.path}
      saveFetcher={() => API_ENDPOINT[isNew ? 'POST_DOCTOR_USER' : 'PUT_DOCTOR'].fetch({id, ...state})}
      removeFetcher={isNew ? undefined : () => API_ENDPOINT.DELETE_DOCTOR.fetch(id)}
    >
      <div className="lg:gap-4 lg:grid lg:grid-cols-3 space-y-4 lg:space-y-0">
        <LabeledFormControl label={t('firstName')}>
          <Input value={state?.firstName} onChange={mapInputEvent(firstName => setState({firstName}))} />
        </LabeledFormControl>
        <LabeledFormControl label={t('lastName')}>
          <Input value={state?.lastName} onChange={mapInputEvent(lastName => setState({lastName}))} />
        </LabeledFormControl>
        <LabeledFormControl label={t('email')}>
          <Input value={state?.email} onChange={mapInputEvent(email => setState({email}))} />
        </LabeledFormControl>
        <LabeledFormControl label={t('password')}>
          <Input value={state?.password} onChange={mapInputEvent(password => setState({password}))} />
        </LabeledFormControl>
        <LabeledFormControl label={t('institution')}>
          <Select value={state?.institution} options={objectsToOptions(['id', 'title'], institutionSwr?.data || [])} onSelect={institution => setState({institution})}/>
        </LabeledFormControl>
        <LabeledFormControl className="col-span-3" label={t('patients')}>
          <SelectMultiple value={state?.patients} options={objectsToOptions(['id', 'fullName'], patientsSwr?.data || [])} onSelect={patients => setState({patients})}/>
        </LabeledFormControl>
      </div>
    </EditLayout>
  );
};

export default DoctorLayout;
