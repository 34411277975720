import maybeToAsync from 'crocks/Async/maybeToAsync';
import {API_ENDPOINT} from './api';
import {LoginContextProvider, ROLES, useLogin} from './api/login';
import {NotificationContextProvider} from './components/Notification';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {asyncToJson} from './util/fetch';
import {fromPromise} from 'crocks/Async';
import {caseMap, gtz, isNonEmptyString} from './util/helper';
import {useHistory, useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import {memo, useLayoutEffect, useMemo} from 'react';
import {SidebarWithCardLayout} from './layout/SidebarLayout';
import {and, chain, getPath, identity, ifElse, IO, isEmpty, isNumber, isObject, map, option, pipe, safe,} from 'crocks';
import {
  DashBoardLayout,
  DeviceLayout,
  DevicePackLayout,
  DevicePacksLayout,
  DevicesLayout,
  DoctorLayout,
  DoctorsLayout,
  InstitutionLayout,
  InstitutionsLayout,
  LoginLayout,
  PatientLayout,
  PatientsLayout,
  SessionLayout,
  SessionsLayout,
  SettingsLayout,
} from './layout';
import ChatListPage from "./pages/ChatListPage";
import ChatDetailPage from "./pages/ChatDetailPage";
import {ChatContextProvider} from "./context/ChatContext";

export const LOCAL_STORAGE_KEYS = {
  USER_ID: 'uid',
  PATIENT_TABLE_SETTINGS: 'patientsTableSettings',
  SESSION_TABLE_SETTINGS: 'sessionsTableSettings',
  DOCTOR_TABLE_SETTINGS: 'doctorsTableSettings',
  DEVICE_PACK_TABLE_SETTINGS: 'devicePacksTableSettings',
  INSTITUTION_TABLE_SETTINGS: 'institutionTableSettings',
  DEVICE_TABLE_SETTINGS: 'deviceTableSettings',
};

export const routes = {
  passwordChange: {
    component: LoginLayout,
    exact: true,
    ignoreInSidebar: true,
    path: '/password-change',
    translationKey: 'loginLayout.title',
  },
  dashboard: {
    path: '/',
    component: DashBoardLayout,
    exact: true,
    translationKey: 'dashboardLayout.title',
  },
  session: {
    path: '/session/:id',
    component: SessionLayout,
    exact: true,
    translationKey: 'sessionLayout.title',
    ignoreInSidebar: true,
  },
  sessions: {
    path: '/session',
    component: SessionsLayout,
    exact: true,
    translationKey: 'sessionsLayout.title',
    roles: [ROLES.SUPER, ROLES.DOCTOR],
  },
  patient: {
    path: '/patient/:id',
    component: PatientLayout,
    exact: false,
    translationKey: 'patientLayout.title',
    ignoreInSidebar: true,
  },
  patients: {
    path: '/patient',
    component: PatientsLayout,
    exact: true,
    translationKey: 'patientsLayout.title',
    roles: [ROLES.SUPER, ROLES.DOCTOR],
  },
  doctor: {
    path: '/doctor/:id',
    component: DoctorLayout,
    exact: true,
    translationKey: 'doctorLayout.title',
    ignoreInSidebar: true,
  },
  doctors: {
    path: '/doctor',
    component: DoctorsLayout,
    exact: true,
    translationKey: 'doctorsLayout.title',
    roles: [ROLES.SUPER],
  },
  devicePacks: {
    path: '/device-pack',
    component: DevicePacksLayout,
    exact: true,
    translationKey: 'devicePacksLayout.title',
    roles: [ROLES.SUPER],
  },
  devicePack: {
    path: '/device-pack/:id',
    component: DevicePackLayout,
    exact: true,
    translationKey: 'devicePackLayout.title',
    ignoreInSidebar: true,
  },
  devices: {
    path: '/device',
    component: DevicesLayout,
    exact: true,
    translationKey: 'devicesLayout.title',
    roles: [ROLES.SUPER],
  },
  device: {
    path: '/device/:id',
    component: DeviceLayout,
    exact: true,
    translationKey: 'deviceLayout.title',
    ignoreInSidebar: true,
  },
  institution: {
    path: '/institution/:id',
    component: InstitutionLayout,
    exact: true,
    translationKey: 'institutionLayout.title',
    ignoreInSidebar: true,
  },
  institutions: {
    path: '/institution',
    component: InstitutionsLayout,
    exact: true,
    translationKey: 'institutionsLayout.title',
    roles: [ROLES.SUPER],
  },
  settings: {
    path: '/settings',
    component: SettingsLayout,
    exact: true,
    translationKey: 'settingsLayout.title',
    ignoreInSidebar: true,
  },
  login: {
    path: '/login',
    component: LoginLayout,
    exact: true,
    translationKey: 'loginLayout.title',
    ignoreInSidebar: true,
  },
};

export const chatRoutes = {
  chat: {
    path: '/chat',
    component: ChatListPage,
    exact: true,
    translationKey: 'chatLayout.title',
    hideForRoles: [ROLES.SUPER],
  },
  chatDetail: {
    path: '/chat/:receiver',
    component: ChatDetailPage,
    exact: true,
    translationKey: 'chatLayout.title',
    hideForRoles: [ROLES.SUPER],
    RightContent: memo(
      IO(API_ENDPOINT.GET_MESSAGE_UNREAD_COUNT.swr)
        .map(pipe(
          getPath(['data', 'count']),
          chain(safe(and(isNumber, gtz))),
          map(children => <span className="ml-2 badge badge-success !border-l" {...{children}}/>),
          option(null),
        ))
        .run
    ),
    ignoreInSidebar: true,
  },
}

const Auth = ({children}) => {
  const {data, setData} = useLogin();
  const history = useHistory();
  const location = useLocation();
  const isPathPublic = () => [
    routes.login.path,
    routes.passwordChange.path,
  ].includes(location.pathname);

  useLayoutEffect(() => {
    const forceToDashboard = () => history.replace(routes.dashboard.path);
    const forceToLogin = () => history.replace(routes.login.path);
    const unauthorized = ifElse(isPathPublic, identity, forceToLogin);

    const authorized = pipe(
      setData,
      ifElse(isPathPublic, forceToDashboard, identity),
    );

    const fetchUser = pipe(
      safe(isNonEmptyString),
      maybeToAsync('Could not extract id from last login JSON in local storage.'),
      chain(fromPromise(API_ENDPOINT.GET_USER.fetch)),
      chain(asyncToJson),
    );

    const ioActOnLoginState = ifElse(
      isObject,
      identity,
      IO.of(() => {
        fetchUser(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)).fork(
          unauthorized,
          authorized
        );
      }).run()
    );

    ioActOnLoginState(data);

  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return caseMap(
    () => null,
    [
      [() => !isEmpty(data), () => <SidebarWithCardLayout>{children}</SidebarWithCardLayout>],
      [isPathPublic, () => children]
    ],
    null
  );
};

function App() {
  return (
    <NotificationContextProvider>
      <BrowserRouter>
        <LoginContextProvider>
          <Auth>
            <Switch>
              {
                useMemo(() => Object.values(routes).map((r) =>
                  <Route key={r.path} exact={r.exact} path={r.path} children={<r.component/>}/>
                ), [])
              }

            </Switch>
            <ChatContextProvider>
              <Switch>
                {
                  useMemo(() => Object.values(chatRoutes).map((r) =>
                    <Route key={r.path} exact={r.exact} path={r.path} children={<r.component/>}/>
                  ), [])
                }
              </Switch>
            </ChatContextProvider>
          </Auth>
        </LoginContextProvider>
      </BrowserRouter>
    </NotificationContextProvider>
  );
}

export default App;
