import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Transition} from '@headlessui/react';
import {TransitionVariant} from './Transition';
import {concatBySpace} from '../util/helper';

const SwrWrapper = ({children, error, isValidating = false, revalidate, mutate, className, ...props}) => {
  const {t} = useTranslation();
  const _error = useMemo(() => {
    if (!error) {
      return null;
    } else if (error instanceof Error) {
      return error.message;
    } else if (typeof error === 'string') {
      return error;
    } else {
      return JSON.stringify(error);
    }
  }, [error]);

  return (
    <div className={concatBySpace('relative', className)} {...props}>
      {children}

      <Transition {...TransitionVariant.opacity} show={isValidating && _error === null ? true : false} className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
        <div className="badge p-2 shadow-lg">{t('loading')}</div>
      </Transition>

      <Transition {...TransitionVariant.opacity} show={!isValidating && _error !== null ? true : false} className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-base-100 bg-opacity-50">
        <div className="badge badge-error p-2 shadow-lg">{_error}</div>
      </Transition>
    </div>
  );
};

export default SwrWrapper;
