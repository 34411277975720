import {Link} from "react-router-dom";
import {HomeIcon} from '@heroicons/react/solid';
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {fillInBetween} from "../util/helper";

const DefaultDivider = ({className, children = '/', ...props}) => (
  <span className={className || 'leading-none block'} {...props}>{children}</span>
);

const defaultLinkStyle = `btn btn-ghost opacity-50 hover:opacity-100 focus:opacity-100`;
const RouteToLink = ({path, translationKey}) => {
  const {t} = useTranslation();

  return <Link to={path} className={defaultLinkStyle}>{t(translationKey)}</Link>;
}

const BreadCrumbs = ({Divider=DefaultDivider, size=6, children, ...props}) => {
  return (
  <div {...props} className="flex flex-wrap items-center justify-center space-x-2">
    <Link to="/" className={defaultLinkStyle}>
      <HomeIcon className={`mb-1 h-${size} w-${size} opacity-50 hover:opacity-100 focus:opacity-100 block`}/>
    </Link>
    {useMemo(() => children ? <Divider /> : null, [children])}
    {useMemo(() => fillInBetween(i => <Divider key={`divide-${i}`}/>, children).option([]), [children])}
  </div>
)
};


export {
  RouteToLink,
};
export default BreadCrumbs;
