import {useLayoutEffect, useMemo, useState} from 'react';
import {CardWithImage, Bg, Brand} from '../components';
import {useNestedTranslation, useSwitchState} from '../hooks';
import {mPickRandom} from '../util/helper';
import TwoFactorForm from '../form/TwoFactorForm';
import LoginForm from '../form/LoginForm';
import ForgotPasswordForm from '../form/ForgotPasswordForm';
import {routes} from '../App';
import PasswordChangeForm from '../form/PasswordChangeForm';
import {useLocation} from 'react-router-dom';

const LoginLayout = () => {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [t] = useNestedTranslation(['loginLayout']);
  const randomImage = useMemo(() => mPickRandom([
    'blood-pressure-1.jpg',
    'blood-pressure-2.jpg',
    'doctor-on-phone-1.jpg',
    'fitness-tracker-1.jpg',
  ]).option(null), []);

  const [form, setForm] = useSwitchState({
    'LOGIN': <LoginForm onTwoFactor={() => setForm('2FA')} onForgotPassword={() => setForm('FORGOT_PASSWORD')} email={email} setEmail={setEmail}/>,
    '2FA': <TwoFactorForm onCancel={() => setForm('LOGIN')}/>,
    'FORGOT_PASSWORD': <ForgotPasswordForm email={email} onCancel={() => setForm('LOGIN')} setEmail={setEmail}/>,
    'PASSWORD_CHANGE': <PasswordChangeForm />
  });

  useLayoutEffect(() => {
    if (location.pathname === routes.passwordChange.path) {
      setForm('PASSWORD_CHANGE');
    }
  }, [setForm, location.pathname]);

  return (
    <Bg className="flex justify-center items-center">
      <CardWithImage className="m-8 w-full md:max-w-4xl" src={`${process.env.PUBLIC_URL}/assets/img/${randomImage}`} alt="tasteful">
        <div className="space-y-4">
          <Brand title={t('title')}/>
          <div className="space-y-4">{form}</div>
        </div>
      </CardWithImage>
    </Bg>
  );
};

export default LoginLayout;
