import {parse} from 'date-fns';
import {and} from 'crocks/logic';
import Maybe, {Nothing} from 'crocks/Maybe';
import {bimap, option} from 'crocks/pointfree';
import {
  First,
  Max,
  Min,
  Pair,
  branch,
  chain,
  compose,
  curry,
  equals,
  filter,
  getPath,
  getProp,
  hasPropPath,
  hasProps,
  identity,
  ifElse,
  isArray,
  isDefined,
  isEmpty,
  isNumber,
  isObject,
  isString,
  map,
  mconcatMap,
  merge,
  mreduce,
  mreduceMap,
  not,
  pipe,
  reduce,
  safe,
  unit,
} from 'crocks';
import {Fragment} from 'react';
import {generatePath} from 'react-router-dom';

export const prepareThrow = message => () => {
  throw new Error(message);
}

export const prepareCustomThrow = message => () => {
  throw message;
}

/**
 * @param {function} fallback A fallback function to run when no case resolves.
 * @param {Array<function, function>} caseMap An array of case-arrays [predicate function, resolving function].
 * @param {any} value A value to test with provided cases.
 * @returns any
 */
export const caseMap = curry((fallback, caseMap, value) => ifElse(
  isArray,
  () => ifElse(
    isDefined,
    ([_, fn]) => fn(value),
    () => fallback(value),
    caseMap.find(([pred]) => pred(value))
  ),
  prepareThrow('caseMap arguments must be #1 fallback fn, #2 array of cases, #3 any.'),
  caseMap
));

export const isNumeric = ifElse(
  isNumber,
  () => true,
  ifElse(
    isString,
    str => (/^(-|\+)?([0-9]+)\.?([0-9]+)?$/).test(str),
    () => false,
  ),
);
export const hasLength = foldable => foldable?.length || 0;
export const isNonEmptyArray = and(isArray, not(isEmpty));
export const isNonEmptyString = and(isString, hasLength);
export const throwPath = curry(
  (path, object) => ifElse(
    and(isObject, hasPropPath(path)),
    (obj) => { throw getPath(path, obj).option(obj) },
    identity,
    object,
  )
);

export const createForm = (obj) =>
  Object.keys(obj).reduce((form, key) => {
    form.append(key, obj[key]);
    return form;
  }, new FormData());

export const getRandomInt = curry((min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
});

export const mPickRandom = list => safe(isArray, list).map(
  compose(
    merge((list, i) => list?.[i]),
    map(getRandomInt(0)),
    map(list => list?.length),
    branch,
  )
);

/**
 * @param {number|string} prop Key of an object
 * @param {array}      list List of objects
 * @param {object}     item An object to find in list
 * @returns Just<Pair<int, array>>|Nothing
 */
export const findIndexByProp = curry((prop, list, item) => compose(
  map(index => Pair(index, list)),
  chain(safe(num => isNumber(num) && num >= 0)),
  map(p => list?.findIndex((f) => f?.[prop] === p)),
  getProp(prop)
)(item));
export const mFirstByMaybe = curry((m, list) => mconcatMap(First, m, list).valueOf());
export const mFirstById = curry((id, list) => mFirstByMaybe(safe(x => x?.id === id), list));
export const mFirstByIdRev = curry((list, id) => mFirstByMaybe(safe(x => x?.id === id), list));
export const concatNonEmptyStr = curry((join, a, b) => b ? (a + join + b) : a);
export const splitString = curry((split, str) => str.split(split))
export const joinString = curry((join, str) => str.join(join))
export const insertAtString= curry((pos, a, b) => `${b.substring(0, pos)}${a}${b.substring(pos)}`);
export const onlyNumericString = str => str.replace(/\D+/gi, '').trim();
export const formatIsoDateInput = compose(
  option(''),
  map(ifElse(str => str.length > 10, str => str.slice(0, 10), identity)),
  map(ifElse(str => str.length > 7, insertAtString(7, '-'), identity)),
  map(ifElse(str => str.length > 4, insertAtString(4, '-'), identity)),
  map(onlyNumericString),
  safe(isString),
);

const ceilNumberOnly = (limit, number) => ifElse(
  num => parseInt10(num) > limit,
  () => limit,
  identity,
  number
);

export const formatIsoHoursInput = compose(
  option(''),
  map(arr => arr.join(':')),
  map(str => str.map((part, index) => caseMap(() => part, [
    [() => index === 0, () => ceilNumberOnly(23, part)],
    [() => index === 1, () => ceilNumberOnly(59, part)],
  ], null))),
  map(str => str.split(':')),
  map(ifElse(str => str.length > 5, str => str.slice(0, 5), identity)),
  map(ifElse(str => str.length > 5, insertAtString(5, ':'), identity)),
  map(ifElse(str => str.length > 2, insertAtString(2, ':'), identity)),
  map(onlyNumericString),
  safe(isString),
);

export const mLowerCase = compose(
  map(s => s.toLowerCase()),
  safe(isNonEmptyString),
);
export const safeLowerCase = s => mLowerCase(s).option(s);
export const concatBySpace = concatNonEmptyStr(' ');
export const titleCaseWord = compose(
  merge((l, r) => l+r),
  bimap(l => l.toUpperCase(), r => r.toLowerCase()),
  bimap(l => l.slice(0, 1), r => r.slice(1)),
  branch,
);

export const titleCase = compose(
  option(''),
  map(joinString(' ')),
  map(items => items.map(titleCaseWord)),
  map(splitString(' ')),
  safe(isNonEmptyString)
);

export const strToDates = curry((join, format, datesStr) =>
  safe(isString, datesStr)
  .map(x => x.split(join))
  .map(xs => xs.map(x => parse(x, format, new Date())))
  .option([])
);

export const Cookie = (key) => {
  key = key.trim();

  return {
    get: () =>Maybe.of(document.cookie)
    .chain(safe(isNonEmptyString))
    .map(str => str.split(';'))
    .chain(safe(hasLength))
    .map(list => list.find(cookieStr => key === cookieStr?.split('=')?.[0]?.trim()))
    .chain(safe(isNonEmptyString))
    .map(str => str.split('=')?.[1])
    .chain(safe(isNonEmptyString))
    .option(null),

    set: (key, value, daysToLive) => {
      let cookie = typeof daysToLive === 'number'
        ? `${key}=${encodeURIComponent(value)}; max-age=${daysToLive*24*60*60}`
        : `${key}=${encodeURIComponent(value)}`

      document.cookie = cookie;
      return cookie;
    }
  }
}

export const parseJsonOr = curry((fallback, str) => {
  if (!str) {
    return fallback;
  }

  try {
    return JSON.parse(str);
  } catch (e) {
    return fallback;
  }
});

export const componentToString = ifElse(
  isArray,
  items => items.map(componentToString).join(' ').replace(/ {2,}/, ' '),
  ifElse(
    hasPropPath(['props', 'children']),
    x => componentToString(x.props.children),
    identity
  )
);

export const asciifyLT = string => string
  .replace(/a/gi, '(a|ą)')
  .replace(/c/gi, '(c|č)')
  .replace(/e/gi, '(e|ė|ę)')
  .replace(/i/gi, '(i|į)')
  .replace(/s/gi, '(s|š)')
  .replace(/u/gi, '(u|ų|ū)')
  .replace(/z/gi, '(z|ž)')
  .split(' ')
  .join('|');

export const parseInt10 = str => parseInt(str, 10);

export const isObjectWithProps = curry((props, object) => and(isObject, hasProps(props))(object));
export const renderChildren = (renderer, children) => ifElse(
  isArray,
  arr => arr.map(renderer), // we need both (item && index) parameters!
  x => renderer(x, 0, [x]), // simulate map environment
)(children)

export const damp = (min, max) => num => safe(isNumber, num)
.map(ifElse(num => num < min, () => num, identity))
.map(ifElse(num => num > max, () => max, identity))
.option(num);

export const flattenFragments = c => c?.type === Fragment ? flattenFragments(c.props.children) : c;

export const sortArrayByPred = curry(
  (pred, xs) =>
  safe(isArray, xs)
  .map(xs => xs.sort((a, b) => pred(a, b) ? -1 : 1))
  .option(xs)
);

export const sortArrayById = sortArrayByPred(({id: a}, {id: b}) => a > b);
export const pickLowestNumber = nums => mreduce(Min, nums);
export const pickHighestNumber = nums => mreduce(Max, nums);
export const mreduceMapOriginal = curry((m, mapper, foldable) => {
  const value = mreduceMap(m, mapper, foldable);
  return foldable.find(f => mapper(f) === value);
});

export const parseTime = curry((refDate, timeStr) => parse(timeStr, 'HH:mm', refDate));
export const parseTodayTime = parseTime(new Date());
export const filterArrayEveryNth = curry((maxPoints, list) => ifElse(
  () => isArray(list) && list?.length > maxPoints,
  () => {
    const k = Math.ceil(list.length / maxPoints);
    return list.filter((_, i) => ((i % k) === 0));
  },
  () => list,
  unit,
));

export const mapListOrNull = curry((fn, list) => compose(
  option(null),
  map(map(fn)),
  safe(isNonEmptyArray),
)(list));

export const safeOrNull = (pred, fn) => compose(
  option(null),
  map(fn),
  safe(pred),
);

/**
 * @returns Maybe<Array>
 */
export const fillInBetween = curry(
  (fillFn, list) => compose(
    map(children => {
      const copy = [...children].flat();
      Array(2 * copy.length - 1)
        .fill(0)
        .map((_, i) => i)
        .filter(i => i % 2 !== 0)
        .map(i => {
          copy.splice(i, 0, fillFn(i));
          return i;
        });
      return copy;
    }),
    safe(isNonEmptyArray),
  )(list)
);

/**
 * @returns Maybe<Array>
 */
export const fillOnEvery = curry(
  (fillFn, list) => compose(
    map(children => {
      const copy = [...children].flat();
      Array(2 * copy.length)
        .fill(0)
        .map((_, i) => i)
        .map(i => {
          copy.splice(i, 0, fillFn(i));
          return i;
        });
      return copy;
    }),
    safe(isNonEmptyArray),
  )(list)
);

/**
 * @param   {object} history              History object from react-router
 * @param   {string} editPathWithIdParam  Route path string that has /:id ending
 * @returns {function}
 */
export const createNewItemPath = curry((history, editPathWithIdParam) => () => {
  history.push(generatePath(editPathWithIdParam, {id: 'new'}));
});

export const gtz = num => num > 0;
export const split = curry((param, s) => ifElse(
  isString,
  s => s.split(param),
  prepareThrow('Split parameter must be a string.'),
  s
));

export const getQueryParameters = location => (
  getProp('search', location)
  .chain(safe(isString))
  .map(pipe(
    split(/\?|&/gi),
    filter(s => s),
    map(split('=')),
  ))
  .chain(safe(hasLength))
  .map(Object.fromEntries)
);

export const filterJust = reduce((list, item) => ifElse(
  not(equals(Nothing())),
  item => [...list, item],
  () => list,
  item
), []);

export const filterJustAndFold = reduce((list, item) => ifElse(
  not(equals(Nothing())),
  item => [...list, item.option(null)],
  () => list,
  item
), []);
