import {Bg, Brand} from "../../components";
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useLogin} from "../../api/login";
import {useTranslation} from "react-i18next";
import {concatBySpace, titleCase} from "../../util/helper";
import {chatRoutes, LOCAL_STORAGE_KEYS, routes} from "../../App";
import {useHistory, useLocation} from "react-router-dom/cjs/react-router-dom.min";
import {useOutsideClick} from "../../hooks";
import SidebarWithCardLayout from './SidebarWithCardLayout';
import {identity, ifElse, isTrue, propPathOr} from 'crocks';

import {CogIcon, LogoutIcon, MenuIcon, UserIcon, XIcon} from '@heroicons/react/solid';
import {Link} from "react-router-dom";

const SidebarLayout = ({children, menuItems, containerClassName = 'p-4'}) => {
  const drawerInputRef = useRef();
  const [showUser, setShowUser] = useState(false);
  const {t} = useTranslation();
  const userRef = useRef();


  const {startListener, stopListener} = useOutsideClick(
    userRef,
    () => {
      setShowUser(false);
    }
  );

  useEffect(() => {
    if (showUser) {
      startListener();
    } else {
      stopListener();
    }

    return () => {
      stopListener()
    };
  }, [showUser, startListener, stopListener]);

  const {data: loginData, setData} = useLogin();
  const history = useHistory();
  const location = useLocation();

  useLayoutEffect(() => {
    ifElse(
      isTrue,
      () => drawerInputRef.current.checked = false,
      identity,
      propPathOr(false, ['current', 'checked'], drawerInputRef)
    );
  }, [location]);

  const renderMenu = useMemo(() => menuItems?.length
      ? menuItems
      : Object.values(routes).concat(Object.values(chatRoutes))
        .filter(route => route?.ignoreInSidebar !== true)
        .filter(route => ((route?.hideForRoles?.length && route?.hideForRoles.every(role => !loginData?.roles?.includes(role))) || !route?.hideForRoles?.length))
        .filter(route => (route?.roles?.length && route?.roles.some(role => loginData?.roles?.includes(role))) || !route?.roles?.length)
        .map((route) => (
          <li key={route.path} className={location.pathname === route.path ? 'bordered' : ''}>
            <Link to={route.path}>{t(route.translationKey)}{route?.RightContent ? <route.RightContent/> : null}</Link>
          </li>
        ))
    , [menuItems, t, location.pathname, loginData?.roles]);

  const logout = (event) => {
    event.preventDefault();
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ID);
    setData(null);
    history.replace(routes.login.path);
  };

  return (
    <Bg>
      <div className="shadow bg-base-300 drawer drawer-mobile min-h-screen">
        <input id="side-drawer" type="checkbox" className="drawer-toggle" ref={drawerInputRef}/>
        <div className="flex flex-col items-center justify-center drawer-content">
          <div className={concatBySpace('w-full min-h-screen', containerClassName)}>
            {children}
          </div>
        </div>
        <div className="drawer-side">
          <label htmlFor="side-drawer" className="drawer-overlay"></label>
          <div
            className="overflow-y-auto bg-base-content text-base-100 w-10/12 md:w-80 flex flex-col justify-between overflow-x-hidden">
            <div>
              <div className="flex justify-between items-center p-4">
                <Brand className="flex items-center" textClassName="ml-4 font-extrabold"
                       iconClassName="w-8 h-8 glow-white"/>
                <label htmlFor="side-drawer" className="btn btn-ghost lg:hidden">
                  <XIcon className="text-white w-6 h-6 text-current"/>
                </label>
              </div>
              <ul className="menu">
                {renderMenu}
              </ul>
            </div>
            <div className="dropdown dropdown-top">
              <button className="btn w-full ml-0 justify-start rounded-none flex-nowrap">
                <UserIcon className="w-8 h-8 mr-4"/>
                <span className="leading-none mt-2 truncate">
                  {titleCase(loginData?.fullName || loginData?.username || t('anonymous'))}
                </span>
              </button>
              <ul className="shadow-lg menu dropdown-content bg-base-100 text-base-content rounded-box m-2">
                <li>
                  <Link to={routes.settings.path}>
                    <CogIcon className="inline-block w-8 h-8 mr-4"/>
                    {t('settingsLayout.title')}
                  </Link>
                </li>
                <li>
                  <button className="hover:bg-gray-200 flex itemscenter px-5 py-3 text-current transition duration-200"
                          onClick={logout}>
                    <LogoutIcon className="inline-block w-8 h-8 mr-4"/>
                    {t('logout')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <label htmlFor="side-drawer"
                 className="drawer-button fixed top-0 left-0 btn btn-ghost opacity-50 hover:opacity-100 lg:hidden">
            <MenuIcon className="w-6 h-6"/>
          </label>
        </div>
      </div>
    </Bg>
  );
};

export {
  SidebarLayout,
  SidebarWithCardLayout,
};
