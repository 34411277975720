import {SidebarLayout} from "../SidebarLayout";

const SidebarWithCardLayout = ({
  children,
  cardProps,
  ...props
})  => {
  return (
    <SidebarLayout {...props}>
    <div className="pb-4">
      <div className="card overflow-visible bg-base-100 shadow-lg" {...cardProps}>
        <div className="card-body">
          {children}
        </div>
      </div>
    </div>
    </SidebarLayout>
  );
}

export default SidebarWithCardLayout;
