import {VideoCameraIcon} from "@heroicons/react/solid";
import React, {useCallback, useImperativeHandle} from "react";
import {post} from "../../util/apiFetch";
import env from "../../env";
import {useNotification} from "../Notification";
import {useParams} from "react-router-dom";

const VideoCallBtn = React.forwardRef(({selected, setIsLoading, sendMessage}, ref) => {
  const {notify} = useNotification();
  const {receiver} = useParams()

  useImperativeHandle(ref, () => ({
    sendMeetLink,
  }))

  const sendMeetLink = useCallback(async (e) => {
    if (e)
      e.preventDefault();
    if (!selected) {
      console.log("Selected user is not set yet");
      return;
    }

    setIsLoading(true);
    try {
      const data = await post('/generate-google-meet', {
        receiverEmail: selected.otherEmail,
        senderEmail: selected.email,
        receiver,
        originalUrl: 'http://localhost:3000/chat'
      })

      if (data.link) {
        await sendMessage(e, data.link)
        setIsLoading(false)
      } else if (data.oauthLink) {
        window.location.href = data.oauthLink;
      } else {
        notify({
          children: 'Error in sending google meet link',
          className: 'alert-error',
          timeout: 2000,
        })
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error fetching OAuth URL:', error);
      setIsLoading(false)
    }
  }, [selected, setIsLoading, env.BACKEND_URL]);

  return (<button
    disabled={!selected}
    className="px-4"
    style={{height: 'calc(100% - 8px)'}}
    onClick={sendMeetLink}
  >
    <VideoCameraIcon className="w-6 h-6 color-base-content"/>
  </button>)
})

export default VideoCallBtn
