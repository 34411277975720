import {useMemo, useState} from "react";
import {VictoryAxis, VictoryChart, VictoryZoomContainer} from "victory";
import {hasLength, pickHighestNumber, pickLowestNumber} from "../util/helper";
import {
  map,
  compose,
  curry,
  isArray,
  ifElse,
  identity,
  filter,
} from 'crocks';
import {format, isValid} from "date-fns";
import {useWindowSize} from "../hooks";
import {isNumber} from "crocks/predicates";

/**
 * @param {{zoomDimension: 'x'|'y'}}
 */
const OptimizedChart = ({
  data,
  zoomDimension,
  Chart,
  maxPoints = 200,
  yAccessor = ({y}) => y,
  xAccessor = ({x}) => x,
  xTickformat = x => isValid(x) ? format(new Date(x), 'yyyy-MM-dd HH:mm:ss') : x,
  yTickFormat = identity,
  height,
  width,
  fontSize = 9,
  xAxisStyle = {
    tickLabels: {fontSize},
    grid: {
      strokeWidth: 1,
      stroke: '#000000',
      strokeOpacity: 0.2,
    }
  },
  yAxisStyle = {
    ...xAxisStyle,
    grid: {
      ...xAxisStyle.grid,
      strokeOpacity: 0.1,
    }
  },
  minDomainY,
  minDomainX,
  maxDomainY,
  maxDomainX,
  ...props
}) => {
  const {width: winX, height: winY} = useWindowSize();
  const _height = useMemo(() => height || winY * 0.15, [height, winY]);
  const _width = useMemo(() => width || winX * 0.6, [width, winX]);

  const createDomain = (initialList, someList) => compose(
    xs => [pickLowestNumber(xs), pickHighestNumber(xs)],
    xs => [...xs || [], ...initialList],
    filter(isNumber),
  )(someList);

  const entireDomain = useMemo(() => {
    if (hasLength(data)) {
      const xHead = xAccessor(data[0]);
      const xTail = xAccessor(data[data.length - 1]);
      return {
        y: createDomain(map(yAccessor, data), [minDomainY, maxDomainY]),
        x: [
          minDomainX && minDomainX < xHead ? minDomainX : xHead,
          maxDomainX && maxDomainX < xTail ? maxDomainX : xTail,
        ]
      };
    }
    return undefined;
  }, [maxDomainX, maxDomainY, minDomainX, minDomainY, data, xAccessor, yAccessor]);

  const [zoomDomain, onZoomDomainChange] = useState(entireDomain);

  const renderedData = useMemo(() => {
    const isListLongerThan = curry((max, list) => isArray(list) && list.length > max);
    const filterArrayWithinZoomDomain = ds => {
      if (zoomDomain?.x?.[0] && zoomDomain?.y?.[1]) {
        return ds.filter((d) => d.x >= zoomDomain.x[0] && d.x <= zoomDomain.x[1])
      }

      return ds;
    };

    const filterEveryPow2 = ds => {
      const startIndex = ds.findIndex((d) => d.x >= zoomDomain?.x?.[0] || entireDomain?.x?.[0]);
      const k = Math.pow(2, Math.ceil(Math.log2(ds.length / maxPoints)));
      return ds.filter((_, i) => (((i + startIndex) % k) === 0));
    }

    const optimize = compose(
      filterEveryPow2,
      filterArrayWithinZoomDomain,
    );

    return ifElse(
      isListLongerThan(maxPoints),
      optimize,
      identity,
      data
    );
  }, [data, zoomDomain, maxPoints, entireDomain?.x]);

  const zoomProps = useMemo(() => ({
    zoomDimension,
    onZoomDomainChange,
  }), [
    zoomDimension,
    onZoomDomainChange
  ]);

  return (
    <VictoryChart
      containerComponent={<VictoryZoomContainer {...zoomProps} />}
      domain={entireDomain}
      height={_height}
      width={_width}
      {...props}
    >
      <Chart data={renderedData} style={{data: {stroke: '#3d4451', strokeWidth: 1}}}/>
      <VictoryAxis dependentAxis style={yAxisStyle} tickFormat={yTickFormat}/>
      <VictoryAxis tickFormat={xTickformat} style={xAxisStyle} fixLabelOverlap  tickCount={100}/>
    </VictoryChart>
  );
};

export default OptimizedChart
