import {API_ENDPOINT} from "../api";
import {LOCAL_STORAGE_KEYS, routes} from "../App";
import {TableColumnRenderer} from "../components/Table";
import {useNestedTranslation} from "../hooks";
import TableLayout from "./TableLayout";
import {LINK_PROPS_FN} from "./TableLayout";
import {getProp} from 'crocks';
import {createNewItemPath, mFirstByIdRev} from "../util/helper";
import {useCallback} from "react";
import {useHistory} from "react-router-dom";

const DoctorsLayout = () => {
  const [t, tt] = useNestedTranslation(['doctorsLayout', 'doctorsLayout.table']);
  const institutionSwr = API_ENDPOINT.GET_INSTITUTIONS.swr();
  const history = useHistory();

  const getDoctorsInstitutionTitle = useCallback(
    doctor => getProp('institution', doctor)
    .chain(mFirstByIdRev(institutionSwr?.data || []))
    .chain(getProp('title'))
    .option(tt('unknownInstitution'))
    ,[institutionSwr, tt]
  );

  return (
    <TableLayout {...{
      title: t('title'),
      swr: API_ENDPOINT.GET_DOCTORS.swr(),
      onCreateCallback: createNewItemPath(history, routes.doctor.path),
      linkPropFn: LINK_PROPS_FN.idProp(routes.doctor.path),
      pathToItem: routes.doctor.path,
      localStorageSettingsKey: LOCAL_STORAGE_KEYS.DOCTOR_TABLE_SETTINGS,
      columnSettings: {
        id: {
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'id'),
          translated: tt('id'),
        },
        fullName: {
          translated: tt('fullName'),
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'fullName'),
        },
        firstName: {
          translated: tt('firstName'),
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'firstName'),
        },
        lastName: {
          translated: tt('lastName'),
          order: 0,
          on: true,
          renderer: TableColumnRenderer.propSpan(tt('unknown'), 'lastName')
        },
        institution: {
          order: 0,
          on: true,
          translated: tt('institution'),
          renderer: getDoctorsInstitutionTitle
        },
        patients: {
          order: 0,
          on: true,
          translated: tt('patients'),
          renderer: TableColumnRenderer.pathSpan(0, ['patients', 'length']),
        },
      }
    }}/>
  );
};

export default DoctorsLayout;
