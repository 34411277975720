import {Async, hasProps, safe, ifElse} from 'crocks';
import {createForm} from '../util/helper';
import {asyncToJson} from '../util/fetch';
import {LOCAL_STORAGE_KEYS} from '../App';
import {createContext, useContext, useEffect, useState} from 'react';
import env from '../env';
import maybeToAsync from 'crocks/Async/maybeToAsync';
import {fromPromise} from 'crocks/Async';

const ERRORS = {
  UNEXPECTED_LOGIN_RESPONSE: 'unexpected login response',
};

const LoginContext = createContext();

const LoginContextProvider = ({children}) => {
  const [data, setData] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  const login = ({email, password}) => fromPromise(
    ({email, password}) => fetch(`${env.BACKEND_URL}/api/login`, {
      method: 'POST',
      body: createForm({email, password, remember_me: rememberMe, autoLogin: false}),
      credentials: 'include',
    })
  )
  ({email, password})
    .chain(asyncToJson)
    .chain(
      ifElse(
        value => value?.error === null && value?.['2fa_complete'] === false,
        Async.Rejected,
        maybeToAsync(ERRORS.UNEXPECTED_LOGIN_RESPONSE, safe(hasProps([
          'doctor',
          'email',
          'fullName',
          'hidden',
          'id',
          'patient',
          'roles',
          'username',
        ])))
      )
    )
    .bimap(
      (error) => {
        setData(null);
        return error;
      },
      value => {
        setData(value);
        return value;
      }
    );

  useEffect(() => {
    if (rememberMe && data?.id) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, data?.id);
    }
  }, [rememberMe, data?.id]);

  return (
    <LoginContext.Provider value={{data, setData, login, rememberMe, setRememberMe}}>
      {children}
    </LoginContext.Provider>
  );
};

const useLogin = () => {
  const context = useContext(LoginContext);

  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }

  return context;
}

const ROLES = {
  DOCTOR: 'ROLE_ADMIN',
  PATIENT: 'ROLE_USER',
  SUPER: 'ROLE_SUPER_ADMIN',
};

export {
  LoginContextProvider,
  useLogin,
  ERRORS,
  ROLES,
}
