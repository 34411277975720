import {isDefined, getPath, safe, and, isEmpty, isArray, not, compose, option, map, alt, chain, getProp, curry} from 'crocks';
import {Just} from 'crocks/Maybe';
import {mFirstByIdRev, renderChildren} from '../util/helper';
import {Link} from 'react-router-dom';

const TableColumnRenderer = {
  mapIdPropToListItemProp: curry((idProp, list, listItemProp, fallback, mapper) =>  compose(
    option(null),
    map(mapper),
    alt(Just(fallback)),
    chain(getProp(listItemProp)),
    chain(mFirstByIdRev(list)),
    getProp(idProp)
  )),
  span: x => (<span>{x}</span>),
  pathSpan: curry((alt, path, x) => (
    getPath(path, x)
    .map(value => (<span>{value}</span>))
    .option(alt)
  )),
  propSpan: curry((alt, prop, x) => (
    getProp(prop, x)
    .map(value => (<span>{value}</span>))
    .option(alt)
  )),
};

const Table = ({headers, rowRenderer = row => row, items, linkProps}) => (
  <div className="overflow-x-auto">
    <table className="table w-full">
      {
        safe(and(isArray, not(isEmpty)), headers)
        .map(h => (
          <thead>
            <tr>{h?.map((content, index) => <Th key={index}>{content}</Th>)}</tr>
          </thead>
        ))
        .option(null)
      }
      <tbody className="">
        {
          safe(isArray, items).map(l => l.map((item, index) => (
            <tr key={index}>
              {renderColumns(rowRenderer(item), headers, linkProps(index))}
            </tr>
          ))).option(null)
        }
      </tbody>
    </table>
  </div>
);

const Th = ({children}) => (
  <th scope="col" className="">
    {children}
  </th>
);

const Td = ({children, className, linkProps, Tag='td'}) => (
  <Tag>
    {linkProps ? (
      <Link className={className} {...linkProps}>
        {children}
      </Link>
    ) : children}
  </Tag>
);

const renderColumns = (row, headers, linkProps) => renderChildren(
  (column, index) => (
    <Td
      Tag={index < 1 ? 'th' : 'td'}
      key={index}
      headers={isDefined(index) ? headers?.[index] : headers?.[0]}
      linkProps={linkProps}>
      {column}
    </Td>
  ),
  row
);

export {
  Th,
  Td,
  Table,
  TableColumnRenderer,
};
