import useSWR from "swr";
import env from "../env"
import {caseMap, createForm, prepareCustomThrow, sortArrayById, throwPath} from "../util/helper"
import {
  compose,
  hasProp,
  identity,
  ifElse,
  isArray,
  isEmpty,
  isNumber,
  isObject,
  isString,
  map,
  omit,
  pick,
  safe,
} from 'crocks';
import Async from "crocks/Async";
import {asyncResponseOk} from "../util/fetch";
import {fromPromise} from 'crocks/Async';
import {format} from "date-fns";

const revalidateOnMountOnly = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
  errorRetryCount: 0,
};

const throwOnErrors = throwPath(['_errors']);
const mapRelationToIdObj = caseMap(
  (d) => {
    console.error(d);
    throw new Error('relations must have type either {id: int}[] or int.')
  },
  [
    [d => isObject(d) && hasProp('id', d), pick(['id'])],
    [isNumber, id => ({id})],
    [d => !d, () => null],
  ],
);

const mapRelationsToIdObjs = relations => safe(isArray, relations)
  .map(map(mapRelationToIdObj))
  .option([]);

const API_URL = {
  DELETE_DEVICE: (id) => id ? `${env.BACKEND_URL}/api/v1/device/${id}` : null,
  DELETE_DEVICE_PACK: (id) => id ? `${env.BACKEND_URL}/api/v1/device-pack/${id}` : null,
  DELETE_DOCTOR: (id) => id ? `${env.BACKEND_URL}/api/v1/doctor/${id}` : null,
  DELETE_INSTITUTION: (id) => id ? `${env.BACKEND_URL}/api/v1/institution/${id}` : null,
  DELETE_PATIENT: (id) => id ? `${env.BACKEND_URL}/api/v1/patient/${id}` : null,
  DELETE_SESSION: (id) => id ? `${env.BACKEND_URL}/api/v1/session/${id}` : null,
  GET_DEVICE: (id) => id ? `${env.BACKEND_URL}/api/v1/device/${id}` : null,
  GET_DEVICES: `${env.BACKEND_URL}/api/v1/device`,
  GET_DEVICE_APIS: `${env.BACKEND_URL}/api/v1/device-api`,
  GET_DEVICE_PACK: (id) => id ? `${env.BACKEND_URL}/api/v1/device-pack/${id}` : null,
  GET_DEVICE_PACKS: `${env.BACKEND_URL}/api/v1/device-pack`,
  GET_DOCTOR: (id) => id ? `${env.BACKEND_URL}/api/v1/doctor/${id}` : null,
  GET_DOCTORS: `${env.BACKEND_URL}/api/v1/doctor`,
  GET_INSTITUTION: (id) => id ? `${env.BACKEND_URL}/api/v1/institution/${id}` : null,
  GET_INSTITUTIONS: `${env.BACKEND_URL}/api/v1/institution`,
  GET_MEASUREMENTS_ACTIVITY: (patientId) => patientId ? `${env.BACKEND_URL}/api/v1/patient/${patientId}/activity` : null,
  GET_MEASUREMENTS_BLOOD_PRESSURE: (patientId) => patientId ? `${env.BACKEND_URL}/api/v1/measurement/graph/patient/${patientId}` : null,
  GET_MEASUREMENTS_RR: (patientId) => patientId ? `${env.BACKEND_URL}/api/v1/patient/${patientId}/rr` : null,
  GET_MEASUREMENTS_SLEEP: (patientId) => patientId ? `${env.BACKEND_URL}/api/v1/patient/${patientId}/sleep` : null,
  GET_MESSAGE: `${env.BACKEND_URL}/api/v1/message`,
  GET_MESSAGE_CONVERSATION: id => id ? `${env.BACKEND_URL}/api/v1/message/${id}/conversation` : null,
  GET_MESSAGE_HISTORY: id => id ? `${env.BACKEND_URL}/api/v1/message/${id}/history` : null,
  GET_MESSAGE_UNREAD_COUNT: `${env.BACKEND_URL}/api/v1/message/unread`,
  GET_OMRON_LOGIN_URL: `${env.BACKEND_URL}/api/v1/omron/login-link`,
  GET_PATIENT: (id) => id ? `${env.BACKEND_URL}/api/v1/patient/${id}` : null,
  GET_PATIENTS: `${env.BACKEND_URL}/api/v1/patient`,
  GET_SESSION: (id) => id ? `${env.BACKEND_URL}/api/v1/session/${id}` : null,
  GET_SESSIONS: `${env.BACKEND_URL}/api/v1/session`,
  GET_USER: (id) => id ? `${env.BACKEND_URL}/api/v1/user/${id}` : null,
  POST_2FA: `${env.BACKEND_URL}/2fa_check`,
  POST_DEVICE: `${env.BACKEND_URL}/api/v1/device`,
  POST_DEVICE_PACK: `${env.BACKEND_URL}/api/v1/device-pack`,
  POST_DOCTOR: `${env.BACKEND_URL}/api/v1/doctor`,
  POST_EMAIL_CHANGE: `${env.BACKEND_URL}/api/v1/user/email-change`,
  POST_INSTITUTION: `${env.BACKEND_URL}/api/v1/institution`,
  POST_MESSAGE: `${env.BACKEND_URL}/api/v1/message`,
  POST_PATIENT: `${env.BACKEND_URL}/api/v1/patient`,
  POST_PASSWORD_CHANGE: `${env.BACKEND_URL}/api/v1/user/password-change`,
  POST_SESSION: `${env.BACKEND_URL}/api/v1/session`,
  POST_USER: `${env.BACKEND_URL}/api/v1/user`,
  PUT_DEVICE: (id) => id ? `${env.BACKEND_URL}/api/v1/device/${id}` : null,
  PUT_DEVICE_PACK: (id) => id ? `${env.BACKEND_URL}/api/v1/device-pack/${id}` : null,
  PUT_DOCTOR: (id) => id ? `${env.BACKEND_URL}/api/v1/doctor/${id}` : null,
  PUT_INSTITUTION: (id) => id ? `${env.BACKEND_URL}/api/v1/institution/${id}` : null,
  PUT_MESSAGE_SEEN: id => id ? `${env.BACKEND_URL}/api/v1/message/${id}` : null,
  PUT_PATIENT: (id) => id ? `${env.BACKEND_URL}/api/v1/patient/${id}` : null,
  PUT_SESSION: (id) => id ? `${env.BACKEND_URL}/api/v1/session/${id}` : null,
};

const API_FETCH = {
  DELETE_DEVICE: (id) => fetch(API_URL.DELETE_DEVICE(id), {
    method: 'DELETE',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  DELETE_DEVICE_PACK: (id) => fetch(API_URL.DELETE_DEVICE_PACK(id), {
    method: 'DELETE',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  DELETE_DOCTOR: (id) => fetch(API_URL.DELETE_DOCTOR(id), {
    method: 'DELETE',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  DELETE_INSTITUTION: (id) => fetch(API_URL.DELETE_INSTITUTION(id), {
    method: 'DELETE',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  DELETE_PATIENT: (id) => fetch(API_URL.DELETE_PATIENT(id), {
    method: 'DELETE',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  DELETE_SESSION: (id) => fetch(API_URL.DELETE_SESSION(id), {
    method: 'DELETE',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  GET_DEVICE_APIS: () => fetch(API_URL.GET_DEVICE_APIS, {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  GET_USER: id => fetch(API_URL.GET_USER(id), {
    method: 'GET',
    credentials: 'include',
  }),

  GET_DEVICES: () => fetch(API_URL.GET_DEVICES, {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(sortArrayById),

  GET_DEVICE: (id) => fetch(API_URL.GET_DEVICE(id), {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json()),

  GET_DEVICE_PACKS: () => fetch(API_URL.GET_DEVICE_PACKS, {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(sortArrayById),

  GET_DEVICE_PACK: (id) => fetch(API_URL.GET_DEVICE_PACK(id), {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  GET_INSTITUTION: (id) => fetch(API_URL.GET_INSTITUTION(id), {
    method: 'GET',
    credentials: 'include',
  }),

  GET_INSTITUTIONS: () => fetch(API_URL.GET_INSTITUTIONS, {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(sortArrayById),

  GET_MEASUREMENTS_ACTIVITY: (patientId) => fetch(API_URL.GET_MEASUREMENTS_ACTIVITY(patientId), {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  GET_MEASUREMENTS_BLOOD_PRESSURE: (patientId) => fetch(API_URL.GET_MEASUREMENTS_BLOOD_PRESSURE(patientId), {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(data => ({
    ...throwOnErrors(data),
    sessions: sortArrayById(data?.sessions)
  })),


  GET_MEASUREMENTS_RR: (patientId) => fetch(API_URL.GET_MEASUREMENTS_RR(patientId), {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(
    compose(
      caseMap(
        identity,
        [
          [isEmpty, prepareCustomThrow(['not found'])],
        ]
      ),
      throwOnErrors,
    )
  ),

  GET_MEASUREMENTS_SLEEP: (patientId) => fetch(API_URL.GET_MEASUREMENTS_SLEEP(patientId), {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(compose(
    caseMap(
      identity,
      [
        [d => d?.sleep?.length < 1, prepareCustomThrow(['not found'])],
      ],
    ),
    throwOnErrors,
  )),

  GET_MESSAGE: () => fetch(API_URL.GET_MESSAGE, {
    method: 'GET',
    credentials: 'include'
  })
  .then(r => r.json())
  .then(sortArrayById),

  GET_MESSAGE_HISTORY: id => fetch(API_URL.GET_MESSAGE_HISTORY(id), {
    method: 'GET',
    credentials: 'include'
  })
  .then(r => r.json()),

  GET_MESSAGE_UNREAD_COUNT: () => fetch(API_URL.GET_MESSAGE_UNREAD_COUNT, {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  GET_OMRON_LOGIN_URL: () => fromPromise(() => fetch(API_URL.GET_OMRON_LOGIN_URL, {
    method: 'GET',
    credentials: 'include',
  }))()
  .chain(asyncResponseOk)
  .bichain(
    r => Async((reject, resolve) => r.text().then(reject, reject)),
    r => Async((reject, resolve) => r.text().then(resolve, reject)),
  )
  .toPromise(),

  GET_DOCTOR: (id) => fetch(API_URL.GET_DOCTOR(id), {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  GET_DOCTORS: () => fetch(API_URL.GET_DOCTORS, {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(sortArrayById),

  GET_SESSION: (id) => fetch(API_URL.GET_SESSION(id), {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(throwOnErrors),

  GET_SESSIONS: () => fetch(API_URL.GET_SESSIONS, {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(sortArrayById),

  GET_PATIENT: (id) => fetch(API_URL.GET_PATIENT(id), {
    method: 'GET',
    credentials: 'include',
  }),

  GET_PATIENTS: () => fetch(API_URL.GET_PATIENTS, {
    method: 'GET',
    credentials: 'include',
  })
  .then(r => r.json())
  .then(sortArrayById),

  POST_2FA: authCode => fetch(API_URL.POST_2FA, {
    method: 'POST',
    body: JSON.stringify({authCode}),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  POST_EMAIL_CHANGE: email => fetch(API_URL.POST_EMAIL_CHANGE, {
    method: 'POST',
    body: createForm({email}),
    credentials: 'include',
  }),

  POST_DOCTOR: ({
    id,
    firstName,
    lastName,
    institution,
    patients,
  }) => fetch(API_URL.POST_DOCTOR, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      firstName,
      institution: mapRelationToIdObj(institution),
      lastName,
      patients: mapRelationsToIdObjs(patients),
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_DEVICE: ({
    deviceApi,
    serialNo,
    institution,
    devicePack,
  }) => fetch(API_URL.POST_DEVICE, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      deviceApi: mapRelationToIdObj(deviceApi),
      institution: mapRelationToIdObj(institution),
      devicePack: mapRelationToIdObj(devicePack),
      serialNo,
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_DEVICE_PACK: ({
    devices,
    institution,
    deviceApi,
    serialNo,
  }) => fetch(API_URL.POST_DEVICE_PACK, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      deviceApi,
      devices: mapRelationsToIdObjs(devices),
      institution: mapRelationToIdObj(institution),
      serialNo,
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_INSTITUTION: ({
    devices,
    doctors,
    id,
    title,
  }) => fetch(API_URL.POST_INSTITUTION, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      title,
      devices: mapRelationsToIdObjs(devices),
      doctors: mapRelationsToIdObjs(doctors),
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_PASSWORD_CHANGE_INIT: ({email}) => fetch(API_URL.POST_PASSWORD_CHANGE, {
    method: 'POST',
    body: createForm({email})
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_PASSWORD_CHANGE_COMMIT: ({password, slug}) => fetch(API_URL.POST_PASSWORD_CHANGE, {
    method: 'POST',
    body: createForm({password, slug})
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_PATIENT: ({
    id,
    firstName,
    lastName,
    dob,
    doctor,
    sex,
    personCode,
    phoneNo,
    oauthAccesses,
    patientTests,
    hidden,
    sessions,
  }) => fetch(API_URL.POST_PATIENT, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      firstName,
      lastName,
      dob,
      doctor: mapRelationToIdObj(doctor),
      sex,
      personCode,
      phoneNo,
      sessions: map(s => ({
        ...s,
        sessionTimetables: map(omit(['id']), s?.sessionTimetables || [])
      }), sessions),
      oauthAccesses: map(compose(
        oa => ({
          ...oa,
          deviceApi: mapRelationToIdObj(oa?.deviceApi),
        }),
        omit(['patient']),
        ifElse(oa => isString(oa?.id), omit(['id']), identity)
      ), oauthAccesses),
      patientTests,
      hidden,
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_SESSION: ({
    dateFrom,
    dateTo,
    devicePacks,
    medicationPrescription,
    sessionTimetables,
    patient
  }) => fetch(API_URL.POST_SESSION, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      dateFrom,
      dateTo,
      devicePacks: mapRelationsToIdObjs(devicePacks),
      medicationPrescription,
      patient: mapRelationToIdObj(patient),
      sessionTimetables: map(omit(['session', 'id']), sessionTimetables || []),
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_MESSAGE: ({body, receiver, sender}) => fetch(API_URL.POST_MESSAGE, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify({body, receiver, sender})
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_DOCTOR_USER: ({
    email,
    password,
    firstName,
    lastName,
    institution,
    patients,
  }) => fetch(API_URL.POST_USER, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      email,
      password,
      doctor: {
        firstName,
        institution: mapRelationToIdObj(institution),
        lastName,
        patients: mapRelationsToIdObjs(patients),
      }
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  POST_PATIENT_USER: ({
    email,
    password,
    firstName,
    lastName,
    dob,
    doctor,
    sex,
    personCode,
    phoneNo,
    oauthAccesses,
    patientTests,
    hidden,
    sessions,
  }) => fetch(API_URL.POST_USER, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify({
      email,
      password,
      patient: {
        firstName,
        lastName,
        dob,
        doctor: mapRelationToIdObj(doctor),
        sex,
        personCode,
        phoneNo,
        sessions: map(s => ({
          ...s,
          sessionTimetables: map(omit(['id']), s?.sessionTimetables || [])
        }), sessions),
        oauthAccesses: map(compose(
          oa => ({
            ...oa,
            deviceApi: mapRelationToIdObj(oa?.deviceApi),
          }),
          omit(['patient']),
          ifElse(oa => isString(oa?.id), omit(['id']), identity)
        ), oauthAccesses),
        patientTests,
        hidden,
      }
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  PUT_DOCTOR: ({
    id,
    firstName,
    lastName,
    institution,
    patients,
  }) => fetch(API_URL.PUT_DOCTOR(id), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      firstName,
      institution: mapRelationToIdObj(institution),
      lastName,
      patients: mapRelationsToIdObjs(patients),
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  PUT_DEVICE: ({
    id,
    serialNo,
    institution,
    devicePack,
    deviceApi,
  }) => fetch(API_URL.PUT_DEVICE(id), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      deviceApi: mapRelationToIdObj(deviceApi),
      institution: mapRelationToIdObj(institution),
      devicePack: mapRelationToIdObj(devicePack),
      serialNo,
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  PUT_DEVICE_PACK: ({
    id,
    devices,
    institution,
    deviceApi,
    serialNo,
  }) => fetch(API_URL.PUT_DEVICE_PACK(id), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      deviceApi,
      devices: mapRelationsToIdObjs(devices),
      institution: mapRelationToIdObj(institution),
      serialNo,
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  PUT_MESSAGE_SEEN: ({id}) => fetch(API_URL.PUT_MESSAGE_SEEN(id), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      seenAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
    }),
  })
  .then(r => r.json())
  .then(throwOnErrors),

  PUT_INSTITUTION: ({
    devices,
    doctors,
    id,
    title,
  }) => fetch(API_URL.PUT_INSTITUTION(id), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      id,
      title,
      devices: mapRelationsToIdObjs(devices),
      doctors: mapRelationsToIdObjs(doctors),
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  PUT_PATIENT: ({
    id,
    firstName,
    lastName,
    dob,
    doctor,
    sex,
    personCode,
    phoneNo,
    oauthAccesses,
    patientTests,
    hidden,
  }) => fetch(API_URL.PUT_PATIENT(id), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      firstName,
      lastName,
      dob,
      doctor: mapRelationToIdObj(doctor),
      sex,
      personCode,
      phoneNo,
      oauthAccesses: map(compose(
        oa => ({
          ...oa,
          deviceApi: mapRelationToIdObj(oa?.deviceApi),
        }),
        omit(['patient']),
        ifElse(oa => isString(oa?.id), omit(['id']), identity)
      ), oauthAccesses || []),
      patientTests,
      hidden,
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),

  PUT_SESSION: ({
    id,
    dateFrom,
    dateTo,
    devicePacks,
    medicationPrescription,
    sessionTimetables,
    patient
  }) => fetch(API_URL.PUT_SESSION(id), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      dateFrom,
      dateTo,
      devicePacks: [{id: devicePacks}],
      medicationPrescription,
      patient: mapRelationToIdObj(patient),
      sessionTimetables: map(omit(['session', 'id']), sessionTimetables || []),
    })
  })
  .then(r => r.json())
  .then(throwOnErrors),
};

const useNewSwr = (param, swrUrl, swrFn) => {
  const isParamNew = param === 'new';
  return useSWR(
    isParamNew ? 'empty' : swrUrl,
    isParamNew ? () => Promise.resolve(undefined) : swrFn,
    revalidateOnMountOnly
  )
};

const API_SWR = {
  GET_DEVICE: (id) => useNewSwr(id, API_URL.GET_DEVICE(id), () => API_FETCH.GET_DEVICE(id)),
  GET_DEVICES: () => useSWR(API_URL.GET_DEVICES, API_FETCH.GET_DEVICES),
  GET_DEVICE_APIS: () => useSWR(API_URL.GET_DEVICE_APIS, () => API_FETCH.GET_DEVICE_APIS()),
  GET_DEVICE_PACK: (id) => useNewSwr(id, API_URL.GET_DEVICE_PACK(id), () => API_FETCH.GET_DEVICE_PACK(id)),
  GET_DEVICE_PACKS: () => useSWR(API_URL.GET_DEVICE_PACKS, () => API_FETCH.GET_DEVICE_PACKS()),
  GET_DOCTOR: (id) => useNewSwr(id, API_URL.GET_DOCTOR(id), () => API_FETCH.GET_DOCTOR(id)),
  GET_DOCTORS: () => useSWR(API_URL.GET_DOCTORS, () => API_FETCH.GET_DOCTORS()),
  GET_INSTITUTION: (id) => useNewSwr(id, API_URL.GET_INSTITUTION(id), () => API_FETCH.GET_INSTITUTION(id).then(r => r.json())),
  GET_INSTITUTIONS: () => useSWR(API_URL.GET_INSTITUTIONS, () => API_FETCH.GET_INSTITUTIONS()),
  GET_MEASUREMENTS_ACTIVITY: (patientId) => useSWR(API_URL.GET_MEASUREMENTS_ACTIVITY(patientId), () => API_FETCH.GET_MEASUREMENTS_ACTIVITY(patientId), revalidateOnMountOnly),
  GET_MEASUREMENTS_BLOOD_PRESSURE: (patientId) => useSWR(API_URL.GET_MEASUREMENTS_BLOOD_PRESSURE(patientId), () => API_FETCH.GET_MEASUREMENTS_BLOOD_PRESSURE(patientId), revalidateOnMountOnly),
  GET_MEASUREMENTS_RR: (patientId) => useSWR(API_URL.GET_MEASUREMENTS_RR(patientId), () => API_FETCH.GET_MEASUREMENTS_RR(patientId), revalidateOnMountOnly),
  GET_MEASUREMENTS_SLEEP: (patientId) => useSWR(API_URL.GET_MEASUREMENTS_SLEEP(patientId), () => API_FETCH.GET_MEASUREMENTS_SLEEP(patientId), revalidateOnMountOnly),
  GET_MESSAGE: () => useSWR(API_URL.GET_MESSAGE, API_FETCH.GET_MESSAGE),
  GET_MESSAGE_HISTORY: (id) => useSWR(API_URL.GET_MESSAGE_HISTORY(id), () => API_FETCH.GET_MESSAGE_HISTORY(id)),
  GET_MESSAGE_UNREAD_COUNT: () => useSWR(API_URL.GET_MESSAGE_UNREAD_COUNT, API_FETCH.GET_MESSAGE_UNREAD_COUNT, {refreshInterval: 60000}),
  GET_OMRON_LOGIN_URL: () => useSWR(API_URL.GET_OMRON_LOGIN_URL, () => API_FETCH.GET_OMRON_LOGIN_URL(), revalidateOnMountOnly),
  GET_PATIENT: id => useNewSwr(id, API_URL.GET_PATIENT(id), () => API_FETCH.GET_PATIENT(id).then(r => r.json())),
  GET_PATIENTS: () => useSWR(API_URL.GET_PATIENTS, () => API_FETCH.GET_PATIENTS()),
  GET_SESSION: id => useNewSwr(id, API_URL.GET_SESSION(id), () => API_FETCH.GET_SESSION(id)),
  GET_SESSIONS: () => useSWR(API_URL.GET_SESSIONS, () => API_FETCH.GET_SESSIONS()),
};

const API_ENDPOINT = {
  DELETE_DEVICE: {
    url: API_URL.DELETE_DEVICE,
    fetch: API_FETCH.DELETE_DEVICE,
  },

  DELETE_DEVICE_PACK: {
    url:   API_URL.DELETE_DEVICE_PACK,
    fetch: API_FETCH.DELETE_DEVICE_PACK,
  },

  DELETE_DOCTOR: {
    url:   API_URL.DELETE_DOCTOR,
    fetch: API_FETCH.DELETE_DOCTOR,
  },

  DELETE_INSTITUTION: {
    url:   API_URL.DELETE_INSTITUTION,
    fetch: API_FETCH.DELETE_INSTITUTION,
  },

  DELETE_PATIENT: {
    url:   API_URL.DELETE_PATIENT,
    fetch: API_FETCH.DELETE_PATIENT,
  },

  DELETE_SESSION: {
    url:   API_URL.DELETE_SESSION,
    fetch: API_FETCH.DELETE_SESSION,
  },

  GET_DEVICE_APIS: {
    url: API_URL.GET_DEVICE_APIS,
    fetch: API_FETCH.GET_DEVICE_APIS,
    swr: API_SWR.GET_DEVICE_APIS,
  },

  GET_USER: {
    url: API_URL.GET_USER,
    fetch: API_FETCH.GET_USER,
  },

  GET_INSTITUTION: {
    url: API_URL.GET_INSTITUTION,
    fetch: API_FETCH.GET_INSTITUTION,
    swr: API_SWR.GET_INSTITUTION,
  },

  GET_MEASUREMENTS_ACTIVITY: {
    url: API_URL.GET_MEASUREMENTS_ACTIVITY,
    fetch: API_FETCH.GET_MEASUREMENTS_ACTIVITY,
    swr: API_SWR.GET_MEASUREMENTS_ACTIVITY,
  },

  GET_MEASUREMENTS_BLOOD_PRESSURE: {
    url: API_URL.GET_MEASUREMENTS_BLOOD_PRESSURE,
    fetch: API_FETCH.GET_MEASUREMENTS_BLOOD_PRESSURE,
    swr: API_SWR.GET_MEASUREMENTS_BLOOD_PRESSURE,
  },

  GET_MEASUREMENTS_RR: {
    url: API_URL.GET_MEASUREMENTS_RR,
    fetch: API_FETCH.GET_MEASUREMENTS_RR,
    swr: API_SWR.GET_MEASUREMENTS_RR,
  },

  GET_MEASUREMENTS_SLEEP: {
    url: API_URL.GET_MEASUREMENTS_SLEEP,
    fetch: API_FETCH.GET_MEASUREMENTS_SLEEP,
    swr: API_SWR.GET_MEASUREMENTS_SLEEP,
  },

  GET_MESSAGE: {
    url: API_URL.GET_MESSAGE,
    fetch: API_FETCH.GET_MESSAGE,
    swr: API_SWR.GET_MESSAGE,
  },

  GET_MESSAGE_HISTORY: {
    url: API_URL.GET_MESSAGE_HISTORY,
    fetch: API_FETCH.GET_MESSAGE_HISTORY,
    swr: API_SWR.GET_MESSAGE_HISTORY,
  },

  GET_MESSAGE_UNREAD_COUNT: {
    url: API_URL.GET_MESSAGE_UNREAD_COUNT,
    fetch: API_FETCH.GET_MESSAGE_UNREAD_COUNT,
    swr: API_SWR.GET_MESSAGE_UNREAD_COUNT,
  },

  GET_OMRON_LOGIN_URL: {
    url: API_URL.GET_OMRON_LOGIN_URL,
    fetch: API_FETCH.GET_OMRON_LOGIN_URL,
    swr: API_SWR.GET_OMRON_LOGIN_URL,
  },

  GET_INSTITUTIONS: {
    url: API_URL.GET_INSTITUTIONS,
    fetch: API_FETCH.GET_INSTITUTIONS,
    swr: API_SWR.GET_INSTITUTIONS,
  },

  GET_PATIENT: {
    url: API_URL.GET_PATIENT,
    fetch: API_FETCH.GET_PATIENT,
    swr: API_SWR.GET_PATIENT,
  },

  GET_PATIENTS: {
    url: API_URL.GET_PATIENTS,
    fetch: API_FETCH.GET_PATIENTS,
    swr: API_SWR.GET_PATIENTS,
  },

  GET_DOCTOR: {
    url: API_URL.GET_DOCTOR,
    fetch: API_FETCH.GET_DOCTOR,
    swr: API_SWR.GET_DOCTOR,
  },

  GET_DOCTORS: {
    url: API_URL.GET_DOCTORS,
    fetch: API_FETCH.GET_DOCTORS,
    swr: API_SWR.GET_DOCTORS,
  },

  GET_SESSION: {
    url: API_URL.GET_SESSION,
    fetch: API_FETCH.GET_SESSION,
    swr: API_SWR.GET_SESSION,
  },

  GET_SESSIONS: {
    url: API_URL.GET_SESSIONS,
    fetch: API_FETCH.GET_SESSIONS,
    swr: API_SWR.GET_SESSIONS,
  },

  GET_DEVICES: {
    url: API_URL.GET_DEVICES,
    fetch: API_FETCH.GET_DEVICES,
    swr: API_SWR.GET_DEVICES,
  },

  GET_DEVICE: {
    url: API_URL.GET_DEVICE,
    fetch: API_FETCH.GET_DEVICE,
    swr: API_SWR.GET_DEVICE,
  },

  GET_DEVICE_PACKS: {
    url: API_URL.GET_DEVICE_PACKS,
    fetch: API_FETCH.GET_DEVICE_PACKS,
    swr: API_SWR.GET_DEVICE_PACKS,
  },

  GET_DEVICE_PACK: {
    url: API_URL.GET_DEVICE_PACK,
    fetch: API_FETCH.GET_DEVICE_PACK,
    swr: API_SWR.GET_DEVICE_PACK,
  },

  POST_2FA: {
    url: API_URL.POST_2FA,
    fetch: API_FETCH.POST_2FA,
  },

  POST_EMAIL_CHANGE: {
    url: API_URL.POST_EMAIL_CHANGE,
    fetch: API_FETCH.POST_EMAIL_CHANGE,
  },

  POST_SESSION: {
    url: API_URL.POST_SESSION,
    fetch: API_FETCH.POST_SESSION,
  },

  POST_MESSAGE: {
    url: API_URL.POST_MESSAGE,
    fetch: API_FETCH.POST_MESSAGE,
  },

  POST_DOCTOR: {
    url: API_URL.POST_DOCTOR,
    fetch: API_FETCH.POST_DOCTOR,
  },

  POST_DEVICE: {
    url: API_URL.POST_DEVICE,
    fetch: API_FETCH.POST_DEVICE,
  },

  POST_DEVICE_PACK: {
    url: API_URL.POST_DEVICE_PACK,
    fetch: API_FETCH.POST_DEVICE_PACK,
  },

  POST_INSTITUTION: {
    url: API_URL.POST_INSTITUTION,
    fetch: API_FETCH.POST_INSTITUTION,
  },

  POST_PASSWORD_CHANGE: {
    url: API_URL.POST_PASSWORD_CHANGE,
    fetchInit: API_FETCH.POST_PASSWORD_CHANGE_INIT,
    fetchCommit: API_FETCH.POST_PASSWORD_CHANGE_COMMIT,
  },

  POST_PATIENT: {
    url: API_URL.POST_PATIENT,
    fetch: API_FETCH.POST_PATIENT,
  },

  POST_PATIENT_USER: {
    url: API_URL.POST_USER,
    fetch: API_FETCH.POST_PATIENT_USER,
  },

  POST_DOCTOR_USER: {
    url: API_URL.POST_USER,
    fetch: API_FETCH.POST_DOCTOR_USER,
  },

  PUT_SESSION: {
    url: API_URL.PUT_SESSION,
    fetch: API_FETCH.PUT_SESSION,
  },

  PUT_DOCTOR: {
    url: API_URL.PUT_DOCTOR,
    fetch: API_FETCH.PUT_DOCTOR,
  },

  PUT_DEVICE: {
    url: API_URL.PUT_DEVICE,
    fetch: API_FETCH.PUT_DEVICE,
  },

  PUT_DEVICE_PACK: {
    url: API_URL.PUT_DEVICE_PACK,
    fetch: API_FETCH.PUT_DEVICE_PACK,
  },

  PUT_INSTITUTION: {
    url: API_URL.PUT_INSTITUTION,
    fetch: API_FETCH.PUT_INSTITUTION,
  },

  PUT_MESSAGE_SEEN: {
    url: API_URL.PUT_MESSAGE_SEEN,
    fetch: API_FETCH.PUT_MESSAGE_SEEN,
  },

  PUT_PATIENT: {
    url: API_URL.PUT_PATIENT,
    fetch: API_FETCH.PUT_PATIENT,
  },
};

const SEX = {
  MALE: 'male',
  FEMALE: 'female',
};

const DEVICE_API = {
  OMRON: 'omron',
  FITBIT: 'fitbit',
  POLAR: 'polar',
};

const MEASUREMENT_TYPE = {
  MEASUREMENT: 'measurement',
  MEDICATION: 'medication',
};

export {
  API_ENDPOINT,
  SEX,
  DEVICE_API,
  MEASUREMENT_TYPE,
  mapRelationToIdObj,
  mapRelationsToIdObjs,
};
