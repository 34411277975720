import {isDefined} from "crocks/predicates";
import {useCallback, useEffect} from "react";

const IconToggle = ({onChange = () => {}, value, children: [first, second]}) => {
  useEffect(() => {
    if (!isDefined(value)) {
      onChange(0);
    }
  }, [onChange]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <button className="focus:outline-none relative rounded-md border-gray-600 w-24 h-12 bg-gray-200 flex justify-between items-center px-1" onClick={useCallback(() => onChange(value ? 0 : 1), [onChange, value])}>
        <span className={`transition-all ${value ? 'left-[3.30rem]' : 'left-1'} duration-200 absolute shadow-sm rounded-md h-10 w-10 bg-white p-1`}></span>
        <span className={`ml-1 transition duration-200 z-10 flex items-center justify-items-center rounded-md h-8 w-8 px-1 ${ value ? 'text-base-content text-opacity-30' : 'text-primary'}`}>
          {first}
        </span>
        <span className={`mr-1 transition duration-200 z-10 flex items-center justify-items-center rounded-md h-8 w-8 p-1 ${ value ? 'text-primary' : 'text-base-content text-opacity-30'}`}>
          {second}
        </span>
      </button>
    </>
  );
};

export default IconToggle;
