import TableLayout from './TableLayout';
import {API_ENDPOINT} from '../api';
import {useNestedTranslation} from '../hooks';
import {LOCAL_STORAGE_KEYS, routes} from '../App';
import {getProp, composeK, safe, isString} from 'crocks';
import {Just} from 'crocks/Maybe';
import {isFuture, parseISO} from 'date-fns';
import {TableColumnRenderer} from '../components/Table';
import {LINK_PROPS_FN} from './TableLayout';
import {createNewItemPath} from '../util/helper';
import {useHistory} from 'react-router-dom';

const getStrDate = composeK(
  safe(isString),
  getProp,
);

const SessionsLayout = () => {
  const [ts, tt] = useNestedTranslation(['sessionsLayout', 'sessionsLayout.table']);
  const swr = API_ENDPOINT.GET_SESSIONS.swr();
  const patientSwr = API_ENDPOINT.GET_PATIENTS.swr();
  const history = useHistory();

  return (
    <TableLayout
      title={ts('title')}
      localStorageSettingsKey={LOCAL_STORAGE_KEYS.SESSION_TABLE_SETTINGS}
      onCreateCallback={createNewItemPath(history, routes.session.path)}
      swr={swr}
      pathToItem={routes.session.path}
      linkPropFn={LINK_PROPS_FN.idProp(routes.session.path)}
      columnSettings={{
        id: {
          order: 0,
          on: true,
          renderer: x => getProp('id', x).map(id => id).option(tt('unknown')),
          translated: tt('id'),
        },
        dateFromTo: {
          translated: tt('dateFromTo'),
          on: true,
          order: 0,
          renderer: (item) => (
            getStrDate('dateFrom', item)
              .concat(Just(' - '))
              .concat(getStrDate('dateTo', item))
              .map(value => <div className={`badge ${isFuture(parseISO(item.dateTo)) ? 'badge-success' : 'badge-error'}`}>{value}</div>)
              .option(<div className="badge badge-error">{ts('sessionRangeUnknown')}</div>)
          )
        },
        patient: {
          translated: tt('patient'),
          order: 0,
          on: true,
          renderer: TableColumnRenderer.mapIdPropToListItemProp(
            'patient',
            patientSwr.data || [],
            'fullName',
            tt('unknownPatient'),
            TableColumnRenderer.span
          )
        }
      }}
    />
  );
};

export default SessionsLayout;
