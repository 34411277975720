import {useCallback, useEffect, useReducer} from 'react';
import {useMergeReducer, useNestedTranslation} from '../hooks';
import {caseMap} from '../util/helper';
import {mapCheckBoxEvent, mapInputEvent} from '../util/mapper';
import {ERRORS, useLogin} from '../api/login';
import {useHistory} from 'react-router-dom';
import {routes} from '../App';
import {CheckBox, Input, LabeledFormControl, LabelText} from '../components/Form';
import {EyeIcon, EyeOffIcon} from '@heroicons/react/solid';
import {ifElse, isFunction} from 'crocks';

const LoginForm = ({email, setEmail, onTwoFactor, onForgotPassword}) => {
  const [t, tl] = useNestedTranslation(['loginLayout', 'label']);
  const {login, setRememberMe} = useLogin();
  const history = useHistory();
  const [state, setState] = useMergeReducer({
    email,
    password: '',
    remember: false,
    error: null,
  });

  useEffect(() => setRememberMe(state.remember), [state.remember, setRememberMe]);
  useEffect(() => setEmail(state.email), [state.email, setEmail]);

  const [passState, togglePassword] = useReducer((passState, _) => {
    const hiddenState = {isHidden: true, type: 'password'};
    const showState = {isHidden: false, type: 'text'};
    return passState.isHidden ? showState : hiddenState
  }, {isHidden: true, type: 'password'});

  const showError = useCallback((error) => setState({error}), [setState]);
  const showGenericError = useCallback(() => showError(t('unknownLoginError')), [showError, t]);
  const showWrongCredsError = useCallback(() => showError(t('wrongCredentials')), [showError, t]);
  const showTwoFactor = useCallback(() => showError(t('twoFactorCodeRequired')), [showError, t]);
  const isWrongCredentialsError = useCallback(error => error === ERRORS.UNEXPECTED_LOGIN_RESPONSE, []);
  const is2FaRequestedError = useCallback(error => error?.['2fa_complete'] === false && error?.error === null, []);
  const redirectToDashboard = useCallback(() => history.push(routes.dashboard.path), [history]);
  const decideAnError = caseMap(showGenericError, [
    [is2FaRequestedError, () => ifElse(isFunction, onTwoFactor, showTwoFactor, onTwoFactor)],
    [isWrongCredentialsError, showWrongCredsError],
  ]);

  const _login = useCallback(() => {
    login({
      email: state.email,
      password: state.password,
    }).fork(decideAnError, redirectToDashboard);
  }, [
    decideAnError,
    login,
    redirectToDashboard,
    state.email,
    state.password,
  ]);

  return (
    <>
      <LabeledFormControl label={tl('email')}>
        <Input id="input-email" onChange={mapInputEvent(email => setState({email}))} value={state.email} />
      </LabeledFormControl>
      <div className="form-control">
        <LabelText>{tl('password')}</LabelText>
        <div className="relative">
          <Input className="w-full" type={passState.type} id="input-password" onChange={mapInputEvent(password => setState({password}))} value={state.password} />
          <button className="absolute top-0 right-0 rounded-l-none btn btn-ghost" onClick={togglePassword}>
            {passState.isHidden  ? <EyeIcon className="w-4 h-4" /> : <EyeOffIcon className="w-4 h-4" />}
          </button>
        </div>
      </div>
      <div className="form-control flex flex-row items-center">
        <CheckBox id="input-remember-me" onChange={mapCheckBoxEvent(state.remember, remember => setState({remember}))} checked={state.remember} />
        <label className="label-text ml-2" htmlFor="input-remember-me">
          {tl('rememberMe')}
        </label>
      </div>
      <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between lg:items-center">
        <button className="btn" onClick={_login}>{tl('login')}</button>
        <button className="link" onClick={onForgotPassword}>{t('forgotPassword')}</button>
      </div>
      {state.error ? (
      <div className="rounded-lg p-2 text-center badge-error w-full overflow-hidden">
        {state.error}
      </div>) : null}
    </>
  );
};

export default LoginForm;
