import { caseMap, concatBySpace } from "../util/helper";
import {isDefined, isString} from 'crocks';

export const Label = ({ id, className, children, ...props }) => (
  <label
    {...(id ? { htmlFor: id } : null)}
    className={concatBySpace('label', className)}
  >
    {children}
  </label>
);

export const LabelText = ({
  className,
  children,
  labelTextClassName,
  ...props
}) => (
  <Label className={className} {...props}>
    <span className={concatBySpace('label-text', labelTextClassName)}>
      {children}
    </span>
  </Label>
);

export const LabeledFormControl = ({
  className,
  children,
  label,
  labelClassName,
  labelTextClassName,
  ...props
}) => (
  <div className={concatBySpace("form-control", className)} {...props}>
    <LabelText
      className={labelClassName}
      labelTextClassName={labelTextClassName}
    >
      {label}
    </LabelText>
    {children}
  </div>
);

const defaultInputClassName = "input input-bordered";

export const Input = ({
  classNameOverride,
  className,
  type = "text",
  ...props
}) => (
  <input
    type={type}
    className={concatBySpace(
      classNameOverride || defaultInputClassName,
      className
    )}
    {...props}
  />
);


export const CheckBox = (props) => (
  <Input type="checkbox" classNameOverride="checkbox" {...props} />
);

export const InputPostAddon = ({wrapperClass, className, addon, ...props}) => {
  const matchSize = match => str => isString(str) && str.match(new RegExp(match, 'gi'));
  const _wrapperClass = caseMap(() => 'h-12', [
    [isDefined, () => wrapperClass],
    [matchSize('xs'), () => 'h-6'],
    [matchSize('sm'), () => 'h-8'],
    [matchSize('lg'), () => 'h-16'],
  ], className);
  return (
    <div className={`${_wrapperClass} relative flex items-center focus-within:shadow-border-focus rounded-lg duration-200 transition-shadow`}>
      <input className={concatBySpace('input input-ghost border border-base-content border-opacity-20 w-full rounded-r-none border-r-0', className)} {...props} />
      <div className="bg-base-200 h-full border border-base-content flex items-center justify-center border-opacity-20 px-3 rounded-lg rounded-l-none whitespace-nowrap">
        {addon}
      </div>
    </div>
  )
};

